import React, { useContext, useState, useEffect } from 'react'
import { FormIdContext } from './FormIdContext';
import {
  OffersByNumberCatalog,
  GvpCreateUser,
  GvpCreateTicket,
  GvpUpdateTicket,
  GvpGetUser
} from '../../service/GlobalService';

// import {
//   REMOVE_MASK_CEP
// } from '../../utils/ValidationForm';

import ClearGlobalMessage from '../../utils/ClearGlobalMessage';
import DescricaoForm from './descricaoform/DescricaoForm';
import IdentificacaoForm from './identificacao/IdentificacaoForm';
import {
  fieldAssuntoValidations,
  fieldDetalhesValidations,
  identificationTabValidations,
  dynamicFieldValidations,
} from '../../utils/ValidationRules';

import { TYPE_ALERT } from '../../components/serproalert/SerproAlert';
import { SerproAlert, SerproFormContainer } from '../../components';
import { ButtonContainer, AlertUser } from './FormStyle';

import { Backdrop, Button } from '@material-ui/core';
import SendIcon from '@material-ui/icons/Send';
import ClearIcon from '@material-ui/icons/Clear';
import NavigateNext from '@material-ui/icons/NavigateNext';
import { CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
// import { MOUNT_CEP } from '../../service/external/cep';

import CertificateModal from '../../components/CertificateModal/CertificateModal';
import RedirectModal from '../../components/RedirectModal/RedirectModal';

function Form(props) {

  const classes = useStyles();
  const [backdrop, setBackdrop] = useState(false);
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [globalMessage, setGlobalMessage] = useState(ClearGlobalMessage);
  const [dataFieldsIdentification, setDataFieldsIdentification] = useState({});
  const [dataFieldsDescription, setDataFieldsDescription] = useState({});
  const [formFullValidate, setFormFullValidate] = useState(false);
  const [offerOfcatalog, setOfferOfcatalog] = useState([]);
  const formIdContext = useContext(FormIdContext);
  const [idTicket, setIdTicket] = useState('');
  const [numberTicket, setNumberTicket] = useState('');


  useEffect(() => {

    localStorage.clear();

    if (formIdContext.useDigitalCertificate)
      localStorage.setItem("cpfDisable", true);
  }, []);

  useEffect(() => {
    const validationIdentificationsTabResult = identificationTabValidations(dataFieldsIdentification,
      dataFieldsIdentification.newUser,
      globalMessage.message);

  }, [identificationTabValidations])

  const finalUpdate = async (e) => {
    setBackdrop(true);
    setLoading(true);
    e.preventDefault();
    setGlobalMessage(ClearGlobalMessage);

    /** validação do campo ASSUNTO da aba DESCRIÇÃO */
    const validationAssuntoResult = fieldAssuntoValidations(dataFieldsDescription);
    if (!validationAssuntoResult.passed) {
      setBackdrop(false);
      setLoading(false);
      return setGlobalMessage({
        message: validationAssuntoResult.message,
        show: true,
      });
    }
    /** -------------------- */

    /** validação dos campos do fomulário dinâmico da aba descrição */
    const validationsDynamicsFieldsResult = dynamicFieldValidations(dataFieldsDescription.data);
    if (!validationsDynamicsFieldsResult.passed) {
      setBackdrop(false);
      setLoading(false);
      return setGlobalMessage({
        message: validationsDynamicsFieldsResult.message,
        show: true,
      });
    }
    /** -------------------- */

    /** validação do campo DETALHES da aba DESCRIÇÃO */
    const validationDetalhesResult = fieldDetalhesValidations(dataFieldsDescription.details);
    if (!validationDetalhesResult.passed) {
      setBackdrop(false);
      setLoading(false);
      return setGlobalMessage({
        message: validationDetalhesResult.message,
        show: true,
      });
    }
    /** -------------------- */

    /** Validação dos campos estáticos */
    const validationIdentificationsTabResult = identificationTabValidations(dataFieldsIdentification);
    if (!validationIdentificationsTabResult.passed) {
      setBackdrop(false);
      setLoading(false);
      return setGlobalMessage({
        message: validationIdentificationsTabResult.message,
        show: true,
      });
    }
    /** -------------------- */

    /** Validação do campo de CNPJ */

    if (formIdContext.cnpjRequiredFormWeb && !dataFieldsIdentification.cnpj) {
      setBackdrop(false);
      setLoading(false);
      return setGlobalMessage({
        message: 'O campo CNPJ deve ser preenchido',
        show: true,
      });
    }
    /** -------------------- */

    await updateTicket();

    return;
  }

  const updateTicket = () => {
    try {
      const object = generateRequisitionObjectForTicket();

      GvpUpdateTicket(object)
        .then(response => {
          setBackdrop(false);
          /*  integrateTicket(); */
          // atualizarRelatorio(dataFieldsIdentification.cpf, formIdContext.numberCatalog, true, null, response.status, response.ticketId);

          SerproAlert.showDialogBlock(
            'Processo realizado com sucesso',
            `Número do ticket ${numberTicket}`,
            TYPE_ALERT.SUCCESS,
            () => {
              setBackdrop(true);
              cleanData();
            },
            false,
            false
          );
        })
        .catch(error => {
          console.error(error);

          //atualizarRelatorio(dataFieldsIdentification.cpf, formIdContext.numberCatalog, false, error, error.response.status);

          SerproAlert.showDialogBlock(
            'Ops! Ocorreu um erro...',
            'Tente novamente por favor',
            TYPE_ALERT.ERROR,
            () => {
              setBackdrop(true);
              cleanData();
            },
            false,
            false
          );
        });
    } catch (error) {

      setBackdrop(false);
      SerproAlert.showDialogBlock(
        'Atenção',
        'Falha no cadastro da oferta, por favor entre em contato com o administrador do catalogo.',
        TYPE_ALERT.ERROR,
        () => {
          cleanData();
        },
        false,
        false
      );
    }

  }


  const generateRequisitionObjectForNewUser = () => {


    return {
      document: dataFieldsIdentification.cpf,
      fullName: dataFieldsIdentification.name,
      email: dataFieldsIdentification.email,
      address: {
        number: filterAdressNumber(dataFieldsIdentification.endNumero),
        neighborhood: dataFieldsIdentification.bairro,
        state: dataFieldsIdentification.uf,
        city: dataFieldsIdentification.cidade,
        zipCode: dataFieldsIdentification.cep,
        addressComplete: `${dataFieldsIdentification.logradouro}`,
        complemento: dataFieldsIdentification.complemento
      },
      phone: {
        ddd: dataFieldsIdentification.ddd,
        number: dataFieldsIdentification.telefone
      }
    }
  }

  const generateTicket = async () => {

    const object = generateFirstObject();

    await GvpCreateTicket(object)
      .then(response => {
        setIdTicket(response.data);
        setNumberTicket(response.data);
        /* setIdTicket(response.data.id);
        setNumberTicket(response.data.number); */
      })
      .catch(error => {

        SerproAlert.showDialogBlock(
          'Ops! Ocorreu um erro...',
          'Tente novamente por favor',
          TYPE_ALERT.ERROR,
          () => {
            cleanData();
          },
          false,
          false
        );
      });
  }


  /*   const integrateTicket = () => {
      let obj = {
        ticket: {
          id: idTicket
        }
      }
  
      IntegratedTicket(obj);
    } */

  const generateRequisitionObjectForTicket = () => {
    try {
      let logradouro = '';
      logradouro += dataFieldsIdentification.logradouro ? dataFieldsIdentification.logradouro : '';
      logradouro += dataFieldsIdentification.endNumero ? '::' + dataFieldsIdentification.endNumero : '';
      logradouro += dataFieldsIdentification.complemento ? '::' + dataFieldsIdentification.complemento : '';

      let objectTicket = {
        title: process.env.REACT_APP_ENVIRONMENT == 'prd' ? dataFieldsDescription.topic : "homologacao 0205HOMOLOG",
        group: 'NIVEL 1::SERVICE DESK',
        customer: dataFieldsIdentification.cpf,
        cep: dataFieldsIdentification.cep,
        cnpj: dataFieldsIdentification.cnpj,
        //state_id: 20,
        logradouro,
        category: (`${formIdContext.description}::${dataFieldsDescription.topic}::${formIdContext.numberCatalog}::${dataFieldsDescription.numberOffer}`).toUpperCase(),
        categoria: (`${formIdContext.description}::${dataFieldsDescription.topic}::${formIdContext.numberCatalog}::${dataFieldsDescription.numberOffer}`).toUpperCase(),
        canal: 'FORMULARIO',
        article: {
          subject: dataFieldsDescription.topic,
          body: 'Chamado criado via formulário para orientação/registro: ' + formIdContext.description + '; Detalhes da demanda: ' + dataFieldsDescription.details,
          internal: true,
          attachments: images,
        },
        owner: 'mxintstef',
        state: '767',
        finalizador: 'FORMULARIO',
        aplicarplano: dataFieldsDescription.groupLevelOne ? false : true,
        idserpro: `${idTicket}`,
        campoS_EXTRAS: generateArticleBodyForObjectRequisition(dataFieldsDescription.data),
        in27: formIdContext.in27
      };

      if (dataFieldsDescription.groupLevelOne) {
        objectTicket = {
          ...objectTicket,
          gruposolucionador: dataFieldsDescription.groupLevelOne,
          new_group: dataFieldsDescription.groupLevelOne
        }
      }

      return objectTicket

    } catch (error) {
      console.log('generateRequisitionObjectForTicket', error);
      throw Error(error);
    }
  }

  const generateFirstObject = () => {

    let logradouro = '';
    logradouro += dataFieldsIdentification.logradouro ? dataFieldsIdentification.logradouro : '';
    logradouro += dataFieldsIdentification.endNumero ? '::' + dataFieldsIdentification.endNumero : '';
    logradouro += dataFieldsIdentification.complemento ? '::' + dataFieldsIdentification.complemento : '';

    return {
      title: process.env.REACT_APP_ENVIRONMENT == 'prd' ? "DÚVIDA/INFORMAÇÃO" : "homologacao 0205HOMOLOG",
      group: 'NIVEL 1::SERVICE DESK',
      customer: dataFieldsIdentification.cpf,
      cep: dataFieldsIdentification.cep,
      cnpj: dataFieldsIdentification.cnpj,
      logradouro,
      category: 'GESTÃO DO ATENDIMENTO CSS::DÚVIDA/INFORMAÇÃO::3105::13351',
      categoria: 'GESTÃO DO ATENDIMENTO CSS::DÚVIDA/INFORMAÇÃO::3105::13351',
      canal: 'FORMULARIO',
      article: {
        subject: "DÚVIDA/INFORMAÇÃO",
        body: 'Chamado criado via formulário para orientação/registro: ' + formIdContext.description,
        internal: true,
        attachments: images,
      },
      owner: 'mxintstef',
      state: '772',
      finalizador: 'FORMULARIO',
      aplicarplano: false,
      new_group: 'ATGSBRTERC',
      gruposolucionador: 'ATGSBRTERC',
      affected_by: dataFieldsIdentification.affectedBy

    };
  }

  const generateArticleBodyForObjectRequisition = (details) => {

    try {
      let body = '';

      if (details) {
        details.forEach(detail => {
          body += `${detail.attrId}!(*|_|*)! ${detail.serproDisplay === null ? ' ' : detail.serproDisplay?.toUpperCase()}!(*|_|*)! ${detail.value}!(*|_|*)! ${detail.type} !(***|_|***)!`;
        });
      }

      return body;

    } catch (error) {
      console.log('generateArticleBodyForObjectRequisition', error)
      throw Error('Erro no cadastro da oferta')
    }
  }

  const callbackImage = (image) => {
    setImages(image);
  }

  const cleanData = (e) => {
    setBackdrop(true);
    setLoading(true);
    setBackdrop(false);

    if (formIdContext.saUserCnpj) {
      window.location = process.env.REACT_APP_FORM_PSERPRO_INTER
    } else {
      window.location = process.env.REACT_APP_FORM_ZDYNAMIC_HOME
    }
  }

  const setFieldsDescriptionValue = (value) => {
    setDataFieldsDescription(value);
  }

  const setFieldsIdentificationValue = (value) => {
    setDataFieldsIdentification(value);
  }

  const setGlobalMessageValue = (value) => {
    setGlobalMessage(value);
  }

  const handleButtonNext = async (event) => {
    setBackdrop(true);
    setGlobalMessage(ClearGlobalMessage);

    /** Validação dos campos estáticos */
    const validationIdentificationsTabResult = identificationTabValidations(dataFieldsIdentification,
      dataFieldsIdentification.newUser);

    if (dataFieldsIdentification.newUser) {
      let userExists;

      await GvpGetUser(dataFieldsIdentification.email, formIdContext.numberCatalog).then((res) => {
        if (res.data?.id && res.data?.id !== 0) {
          userExists = true;
        } else {
          userExists = false
        }
      }).catch(() => { userExists = false })

      if (userExists) {
        setBackdrop(false);
        return setGlobalMessage({
          message: 'O e-mail informado já está sendo utilizado em outro cadastro em nossa base. Por gentileza, informe um e-mail diferente',
          show: true,
        });
      }
    }
    if (!validationIdentificationsTabResult.passed) {
      setBackdrop(false);
      return setGlobalMessage({
        message: validationIdentificationsTabResult.message,
        show: true,
      });
    }

    if (formIdContext.cnpjRequiredFormWeb) {
      if (dataFieldsIdentification.cnpj === undefined) {
        setBackdrop(false);
        return setGlobalMessage({
          message: 'O campo CNPJ deve ser preenchido',
          show: true,
        });
      }

      if (dataFieldsIdentification.cnpj?.length < 14) {
        setBackdrop(false);
        return setGlobalMessage({
          message: 'O campo CNPJ deve ter no mínimo 14 caracteres',
          show: true,
        });
      }
    }

    if (dataFieldsIdentification.newUser) {
      setBackdrop(true);

      const req = generateRequisitionObjectForNewUser();

      filterName(dataFieldsIdentification.name);

      await GvpCreateUser(req)
        .then(response => {
          generateTicket()
            .then(() => {
              SerproAlert.showSimpleDialog(
                'Novo registro',
                'Cadastro de usuário realizado com sucesso',
                TYPE_ALERT.SUCCESS,
                () => { }
              );
              setBackdrop(false);
              setFormFullValidate(true);
            }).catch(() => {
              setGlobalMessage({
                message: 'Falha ao abrir o chamado, por gentileza tente mais tarde.',
                show: true,
              });
            })
        })
        .catch(error => {
          setBackdrop(false);
          SerproAlert.showSimpleDialog(
            'Atenção',
            'Falha ao cadastrar o usuário, por gentileza verifique seus dados e tente mais tarde.',
            TYPE_ALERT.SUCCESS,
            () => { window.location.reload() }
          );
        });
      setFormFullValidate(false);
    }
    else {
      event.preventDefault();

      localStorage.setItem("autoTicket", true);
      localStorage.setItem("cpfDisable", true);
      localStorage.setItem("cnpjDisabled", true);
      await generateTicket()
    }

    await OffersByNumberCatalog(formIdContext.numberCatalog)
      .then(response => {
        const offers = response.data;
        setOfferOfcatalog(offers);
      })
      .catch(error => {
        console.error('error :>> ', error);
        if (error.response.status === 404) {
          setGlobalMessage({
            message: 'Não existem ofertas para este catálogo',
            show: true,
          });
        }
        else {
          setGlobalMessage({
            message: 'Não foi possível recuperar as ofertas',
            show: true,
          });
        }
      });

    if (dataFieldsIdentification.newUser != true) {
      setBackdrop(false);
      setFormFullValidate(validationIdentificationsTabResult.passed)
    }


  }

  function filterAdressNumber(input) {
    if (!input) return 0
    let array = input.split('')
    let filtered = array.filter(el => el > -1);
    return parseInt(filtered.join(''))
  }

  function filterName(name, typeName) {
    let arrayName = name.split('');
    let result = null;
    let i = 0
    arrayName.map((c) => {
      if (c[i] !== " " && result === null) {
        let tempArray = arrayName.join('');
        result = tempArray.slice(arrayName.indexOf(c), tempArray.length);
      }
      i++
    })

    if (typeName === 'fistName') {
      return result.split(" ")[0]
    }

    if (typeName === 'lastName') {
      return result.slice(result.indexOf(" ") + 1, result.length)
    }

    return null
  }

  // const generateRelatory = (validationIdentificationsTabResult, success, error, statusCode) => {
  //   return {
  //     name: "Relatorio de Informações",
  //     error: error,
  //     statusCode: statusCode,
  //     success: success,
  //     metadata: {
  //       passo: 1,
  //       SucessUserExists: validationIdentificationsTabResult,
  //       DataHorario: new Date(),
  //       cpf: dataFieldsIdentification.cpf,
  //       catalog: formIdContext.numberCatalog
  //     },
  //   }
  // }

  // const updateRelatory = (cpf, catalog, success, error, statusCode, ticketId) => {
  //   return {
  //     name: "Relatorio de Informações",
  //     error: error,
  //     sucess: success,
  //     statusCode: statusCode,
  //     metadata: {
  //       passo: 2,
  //       cpf: cpf,
  //       catalog: catalog,
  //       ticketId: ticketId
  //     }
  //   }
  // }

  // const criarRelatorio = (validationIdentificationsTabResult, succsess, error, status) => {
  //   const objectRel = generateRelatory(validationIdentificationsTabResult, succsess, error, status);

  //   CriarRelatorioAcompanhamento(objectRel).then(response => {
  //     setIdRelatorio(response.data.id);
  //   }).catch(error => {
  //     setGlobalMessage({
  //       message: 'Erro ao gerar relatório de informações',
  //       show: true,
  //     });
  //   });
  // }

  // const atualizarRelatorio = (cpf, catalog, succsess, error, status, ticketId) => {
  //   const objectRel = updateRelatory(cpf, catalog, succsess, error, status, ticketId);

  //   AtualizarRelatorioAcompanhamento(idRelatorio, objectRel).then(response => {

  //   }).catch(error => {
  //     setGlobalMessage({
  //       message: 'Erro ao atualizar relatório de informações',
  //       show: true,
  //     });
  //   });
  // }

  return (
    <>
      <SerproFormContainer
        showInfo={true}
        title="Registro de solicitação de serviço"
        description="Utilize o formulário abaixo para solicitar um atendimento"
      >
        <nav>
          <div className="nav nav-tabs" id="nav-tab" role="tablist">
            <a
              className={(!formFullValidate && "nav-link active") || "nav-link"}
              id="nav-home-tab"
              data-bs-toggle="tab"
              href="#nav-home"
              role="tab"
              aria-controls="nav-home"
              aria-selected="true"
            >
              Identificação
            </a>
            <a
              className={(formFullValidate && "nav-link active") || "nav-link"}
              id="nav-profile-tab"
              data-bs-toggle="tab"
              href="#nav-profile"
              role="tab"
              aria-controls="nav-profile"
              aria-selected="false"
              hidden={!formFullValidate}
            >
              Descrição
            </a>
          </div>
        </nav>

        {globalMessage.show && (
          <AlertUser>
            {globalMessage.message}
          </AlertUser>
        )}

        <div className="tab-content" id="nav-tabContent">
          <div
            className={(!formFullValidate && "tab-pane fade show active") || "tab-pane fade show"}
            id="nav-home"
            role="tabpanel"
            aria-labelledby="nav-home-tab"
          >
            <IdentificacaoForm
              setFieldsIdentification={setFieldsIdentificationValue}
              setGlobalMessage={setGlobalMessageValue}
              setCpfDisable={localStorage.getItem("cpfDisable")}
              setCnpjDisabled={localStorage.getItem("cnpjDisabled")}
            />
          </div>

          <div
            className={(formFullValidate && "tab-pane fade show active") || "tab-pane fade"}
            id="nav-profile"
            role="tabpanel"
            aria-labelledby="nav-profile-tab"
          >
            <DescricaoForm
              callbackImage={callbackImage}
              setFieldsDescription={setFieldsDescriptionValue}
              setGlobalMessage={setGlobalMessageValue}
              offers={offerOfcatalog}
              userGroup={dataFieldsIdentification.groups || ''}
              dataFieldsIdentification={dataFieldsIdentification}
              idTicket={idTicket}
            />
          </div>
        </div>

        <ButtonContainer>
          {!loading && (
            <>
              {!formFullValidate && (
                <Button
                  variant="contained"
                  color="secondary"
                  size="large"
                  startIcon={<NavigateNext />}
                  disabled={loading}
                  onClick={(e) => handleButtonNext(e)}
                >
                  PRÓXIMO
                </Button>
              )}

              {formFullValidate && (
                <Button
                  variant="contained"
                  color="primary"
                  size="large"
                  startIcon={<SendIcon />}
                  disabled={loading}
                  onClick={(e) => finalUpdate(e)}
                >
                  ENVIAR
                </Button>
              )}

              <Button
                variant="contained"
                color="inherit"
                size="large"
                startIcon={<ClearIcon />}
                disabled={loading}
                onClick={(e) => cleanData(e)}
              >
                LIMPAR
              </Button>
            </>
          )}

          {loading && (
            <>
              <Button
                variant="contained"
                color="primary"
                size="large"
                startIcon={<CircularProgress size={15} color="inherit" />}
                disabled={loading}
              >
                PROCESSANDO...
              </Button>
            </>
          )}
        </ButtonContainer>

        <Backdrop className={classes.backdrop} open={backdrop}>
          <CircularProgress color="inherit" />
          <span className={classes.textBackdrop}>Consultando ofertas do catálogo, por favor aguarde.</span>
        </Backdrop>
      </SerproFormContainer>
    </>
  )
}

export default Form

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
    display: 'flex',
    flexDirection: 'column'
  },
  textBackdrop: {
    marginTop: '20px'
  }
}));
