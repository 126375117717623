import { useState, useEffect } from 'react';
import { useFormId } from '../../screens/form/FormIdContext';
import { Timer } from '../timer/timer';
import { useTranslation } from 'react-i18next';
import LogoBR from '../../assets/img/brasil-circle.png'
import LogoUS from '../../assets/img/estados-unidos-circle.png'
import LogoES from '../../assets/img/espanha-circle.png'

const publicIp = require('public-ip');


function NewSerproHeader(props) {

    const [ip, setIp] = useState('');
    const { formIdContext, setFormIdContextAdd } = useFormId();
    const { t, i18n: { changeLanguage, language } } = useTranslation();
    const [lang, setLang] = useState('');

    const { setLangForm } = props;

    useEffect(() => {
        (async () => {
            setIp(await publicIp.v4());
        })();
    }, []);


    useEffect(() => {
        if (lang === '') {
            const langNavigator = navigator.language.slice(0, 2);
            if (!['pt', 'en', 'es'].includes(langNavigator)) {
                changeLanguage('en');
                setLang('en');
            } else {
                changeLanguage(langNavigator);
                setLang(langNavigator);
            }
        }

        const langObject = lang.toUpperCase();
        setLangForm(langObject);

    })

    const changeLang = (e) => {
        changeLanguage(e.target.value);
        setLang(e.target.value);
        const langObject = lang.toUpperCase();
        setLangForm(langObject);
    }

    return (
        <section>
            <div style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                width: '100%',
                height: '6vh',
                backgroundColor: '#FFF;',
                margin: '0px',
                outline: '0px',
                opacity: 1
            }}>
                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    marginLeft: '50px'
                }}>
                    <img
                        src={'https://www.serpro.gov.br/++resource++serpro.portalserprotema/img/logo-serpro.png'}
                        style={{ width: '100px', height: '30px' }}
                        alt=""
                    />
                    <h3
                        style={{
                            font: 'open-sans',
                            fontSize: '24px',
                            color: '#5F5F7D',
                            fontWeight: '550',
                            marginBottom: '0px',
                            marginLeft: '25px'
                        }}>
                        {t('tituloForm')}
                    </h3>
                </div>
                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    marginRight: '50px'
                }}>
                    <p style={{ font: 'open-sans', fontSize: '14px', color: '#00348B', margin: '0px' }}>
                        {t('ip')}
                        {ip}</p>
                    <p style={{ font: 'open-sans', fontSize: '14px', color: '#00348B', margin: '15px', fontWeight: '200' }}> | </p>
                    <p style={{
                        font: 'open-sans',
                        display: 'flex',
                        flexDirection: 'row',
                        fontSize: '14px',
                        color: '#00348B',
                        margin: '0px'
                    }}>
                        {t('tempoSessao')}
                        <Timer />
                    </p>
                    <div>
                        <select
                            id='remove-arrow'
                            style={{ width: '60px', marginLeft: '5px', padding: '5px', border: 'none', borderRadius: '60px', backgroundColor: '#fff', color: '#003DA5', fontWeight: '600', cursor: 'pointer' }}
                            onChange={e => changeLang(e)}
                            value={lang}
                        >
                            <option value="pt">PT_BR</option>
                            <option value="en">EN_US</option>
                            <option value="es">ES_ES</option>
                        </select>
                    </div>
                    <div>
                        {lang === 'pt' && <img src={LogoBR} alt="" style={{ width: '20px', height: '20px' }} />}
                        {lang === 'en' && <img src={LogoUS} alt="" style={{ width: '20px', height: '20px' }} />}
                        {lang === 'es' && <img src={LogoES} alt="" style={{ width: '20px', height: '20px' }} />}
                    </div>
                </div>
            </div>
        </section >
    );

}

export default NewSerproHeader;