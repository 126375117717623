import React, { useEffect, useState, useRef } from "react"
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useFormId } from "../../screens/form/FormIdContext";
import { Lang } from "../../utils/Languages";
import { useTranslation } from "react-i18next";


export const Timer = () => {

    const [backdrop, setBackdrop] = useState(false);
    const [seconds, setSeconds] = useState(59);
    const [minutes, setMinutes] = useState(11);
    const [inactiveSectionDialog, setInactiveSectionDialog] = useState(false);
    const [expiredSectionDialog, setExpiredSectionDialog] = useState(false);
    const myBtn = useRef(null);
    const { formIdContext } = useFormId();
    const { t, i18n: { changeLanguage, language } } = useTranslation();

    const openInactiveSectionDialog = () => {
        setInactiveSectionDialog(true);
    };

    const closeInactiveSectionDialog = () => {
        setInactiveSectionDialog(false);
    };

    const openExpiredSectionDialog = () => {
        setExpiredSectionDialog(true);
    };

    const closeExpiredSectionDialog = () => {
        setExpiredSectionDialog(false);
        window.location.reload();
    };

    function reset() {
        setSeconds(59);
        setMinutes(11);
        localStorage.removeItem("restartTimer");
    }

    useEffect(() => {
        if (localStorage.getItem("restartTimer")) {
            myBtn.current?.click();
        }

        let interval = setInterval(() => { setSeconds(seconds => seconds - 1) }, 1000)
        if (seconds === 0) {
            setSeconds(59);
        }
        return () => clearInterval(interval);
    }, [seconds])

    useEffect(() => {


        let interval = setInterval(() => { setMinutes(minutes => minutes - 1) }, 60000)

        if (minutes === 1)
            openInactiveSectionDialog();

        if (minutes === 0)
            openExpiredSectionDialog();

        return () => clearInterval(interval);
    }, [minutes])

    return (
        <>
            <button hidden ref={myBtn} onClick={reset}></button>
            <p style={{ font: 'open-sans', fontSize: '14px', color: '#00348B', margin: '0px 5px' }}>{minutes}:{seconds < 10 ? "0" + seconds : seconds}</p>

            <Dialog
                open={inactiveSectionDialog}
                onClose={closeInactiveSectionDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth='true'
            >
                <DialogTitle style={{ color: '#5F5F7D' }}>
                    {t('sessaoInativaTitulo')}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText >
                        {t('sessaoInativaTexto')}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button style={{ margin: '16px 24px', padding: '5px 20px', borderRadius: '60px', backgroundColor: '#003DA5', color: '#FFF', textTransform: 'none', }} onClick={closeInactiveSectionDialog} disableRipple>
                        {t('sessaoInativaBotao')}
                    </Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={expiredSectionDialog}
                onClose={closeExpiredSectionDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth='true'
            >
                <DialogTitle style={{ color: '#5F5F7D' }}>
                    {t('sessaoExpiradaTitulo')}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText >
                        {t('sessaoExpiradaTexto')}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button style={{ margin: '16px 24px', padding: '5px 20px', borderRadius: '60px', backgroundColor: '#003DA5', color: '#FFF', textTransform: 'none', }} onClick={closeExpiredSectionDialog} disableRipple>
                        {t('sessaoExpiradaBotao')}
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )

} 