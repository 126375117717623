import { useEffect, useState } from "react";
import SerproFormInputV2 from "../../../components/serproforminput/SerproFormInputV2";
import { useFormId } from "../../form/FormIdContext";
import { REMOVE_MASK_TELEFONE } from "../../../utils/ValidationForm";
import { emailValidation } from "../../../utils/ValidationRules";
import { GET_USER, GvpGetUser } from "../../../service/GlobalService";
import { Lang } from "../../../utils/Languages";
import { useTranslation } from "react-i18next";



function IdentificacaoInternatialV2(props) {

    const { setAlertMessage, setControllerBackdrop, setGenerateGenericDialog, setFormValidButton } = props;
    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [logradouro, setLogradouro] = useState('');
    const [telefone, setTelefone] = useState('');
    const [empresa, setEmpresa] = useState('');
    const [showFields, setShowFields] = useState(false);

    const [fieldDisabled, setFieldDisabled] = useState(true);
    const { setFormIdContext, formIdContext, setFormIdContextAdd } = useFormId();
    const { t, i18n: { changeLanguage, language } } = useTranslation();
    const [obj,] = useState({});

    useEffect(() => {
        setEmail(formIdContext.email);
        setName(formIdContext.name);
    })

    const getUser = async (email) => {
        openBackdrop();
        closeErrorAlert();

        const validation = emailValidation(email);
        if (!validation.passed) {
            closeBackdrop();
            openErrorAlert(validation.message);
            return
        }

        const result = await GvpGetUser(email, formIdContext.numberCatalog)
            .then(res => {

                const data = res.data;
                if (data?.id) {
                    setName(`${data.nome} ${data.sobrenome}`);
                    setLogradouro(data.address.addressComplete);
                    setTelefone(data.phone.number);
                    setEmpresa(data.company);
                    setFormIdContextAdd({ setEmailDisable: true, setFieldsInternationalDisable: false, name: `${data.firstName} ${data.lastName}` });
                    setFieldDisabled(true);
                    formIdContext.in21 ? setShowFields(true) : setShowFields(false);
                } else {
                    if (data === 'semcadastro') {
                        obj.newUser = true;
                        setShowFields(true);

                        setName('');
                        setLogradouro('');
                        setTelefone('');
                        setEmpresa('');

                        setFormIdContextAdd({ setEmailDisable: false, setFieldsInternationalDisable: true });
                        setFieldDisabled(false);
                        setFormIdContextAdd(obj);
                        openErrorAlert(t('usuarioNaoCadastrado'));
                        return closeBackdrop();
                    } 
                }

 
            }).catch(err => {

                if (err.message == 'Request failed with status code 404') {
                    obj.newUser = true;
                    setShowFields(true);

                    setName('');
                    setLogradouro('');
                    setTelefone('');
                    setEmpresa('');

                    setFormIdContextAdd({ setEmailDisable: false, setFieldsInternationalDisable: true });
                    setFieldDisabled(false);
                    setFormIdContextAdd(obj);
                    openErrorAlert(t('usuarioNaoCadastrado'));
                    return closeBackdrop();
                }
            });

        closeBackdrop();
    }

    const setEmailValue = (email) => {
        setEmail(email)
        obj.email = email;
        setFormIdContextAdd(obj);
    }

    const setNameValue = (name) => {
        setName(name);
        obj.name = name;
        setFormIdContextAdd(obj);
    }

    const setLogradouroValue = (e) => {
        setLogradouro(e)
        obj.logradouro = e;
        setFormIdContextAdd(obj);
    }

    const setTelefoneValue = (e) => {
        if (e != null) {
            setTelefone(e);
            obj.telefone = REMOVE_MASK_TELEFONE(e);
            setFormIdContextAdd(obj);
        }
    }

    const setEmpresaValue = (e) => {
        setEmpresa(e);
        obj.empresa = e;
        setFormIdContextAdd(obj);
    }

    const openErrorAlert = (message) => {
        setAlertMessage({ message: message, show: true })
    }

    const closeErrorAlert = () => {
        setAlertMessage({ message: '', show: false })
    }

    const openBackdrop = (message) => {
        setControllerBackdrop({ message: message, show: true })
    }

    const closeBackdrop = () => {
        setControllerBackdrop({ message: '', show: false })
    }

    return (
        <div style={{ width: '100%' }}>
            <div style={{ width: '100%', display: 'flex' }}>
                <SerproFormInputV2
                    divClass=""
                    styleDiv={{ width: '50%' }}
                    name={t('email')}
                    value={email}
                    disabled={formIdContext.setEmailDisable}
                    onChange={(e) => setEmailValue(e.target.value)}
                    onBlur={(e) => getUser(e.target.value)}
                    maxlength={100}
                />

                <SerproFormInputV2
                    divClass="ml-3"
                    styleDiv={{ width: '50%' }}
                    name={t('nomeDoSolicitante')}
                    value={name}
                    disabled={!formIdContext.setFieldsInternationalDisable}
                    onChange={(e) => setNameValue(e.target.value)}
                    error
                    maxlength={100}
                />
            </div>
            {showFields && (
                <>
                    <div style={{ width: '100%', display: 'flex' }}>
                        <SerproFormInputV2
                            divClass=""
                            styleDiv={{ width: '50%' }}
                            name={t('enderecoLogradouro')}
                            disabled={!formIdContext.setFieldsInternationalDisable}
                            value={logradouro}
                            maxLength={150}
                            onChange={(e) => setLogradouroValue(e.target.value)}
                        />
                        <SerproFormInputV2
                            divClass="ml-3"
                            styleDiv={{ width: '50%' }}
                            name={t('telefone')}
                            mask="99999-9999"
                            value={telefone}
                            disabled={!formIdContext.setFieldsInternationalDisable}
                            onChange={(e) => setTelefoneValue(e.target.value)}
                        />
                    </div>
                    <div style={{ width: '100%', display: 'flex' }}>
                        <SerproFormInputV2
                            divClass=""
                            styleDiv={{ width: '50%' }}
                            name={t('empresa')}
                            value={empresa}
                            disabled={!formIdContext.setFieldsInternationalDisable}
                            onChange={(e) => setEmpresaValue(e.target.value)}
                        />
                    </div>
                </>
            )}
        </div>
    )
}

export default IdentificacaoInternatialV2;