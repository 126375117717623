/* eslint-disable react-hooks/exhaustive-deps */

import React, { useContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { FormIdContext } from '../FormIdContext';
import { DetailsOfferByNumberCatalog, GvpUpdateUserSCCD, GvpUpdateTicket, GvpGetUser } from '../../../service/GlobalService';
import { REMOVE_MASK_CPF, REMOVE_MASK_CNPJ, REMOVE_MASK_TELEFONE } from '../../../utils/ValidationForm';
import { TAM_CAMPO_NUMERICO, STATUS_TICKET_GVP_ENUM } from './../../../utils/ConstDatas';
import ClearGlobalMessage from '../../../utils/ClearGlobalMessage';
import { SerproFormInput, SerproFormTextArea, SerproAlert } from '../../../components';
import SerproFormSelect from './../../../components/serproformselect/SerproFormSelect';
import {
  AlertQtdeArquivosAnexados,
  AlertTamanoArquivoAnexado,
  AlertQtdeCaracteresRestantes,
  AlertTipoArquivo,
  FileItem,
} from "../FormStyle";
import { TYPE_ALERT } from './../../../components/serproalert/SerproAlert';
import { makeStyles } from '@material-ui/core/styles';
import { Backdrop, Button, CircularProgress, Dialog, Tooltip } from '@material-ui/core'
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import DeleteIcon from '@material-ui/icons/Delete';

function DescricaoForm(props) {
  const classes = useStyles();
  const formIdContext = useContext(FormIdContext);
  const { callbackImage,
    offers,
    userGroup,
    setFieldsDescription,
    setGlobalMessage,
    dataFieldsIdentification,
    idTicket
  } = props
  const [alertMaxFilesSize, setAlertMaxFilesSize] = useState(false)
  const [alertMaxQtdFiles, setAlertMaxQtdFiles] = useState(false)
  const [alertFileType, setAlertFileType] = useState(false)
  const [assunto, setAssunto] = useState('');
  const [detail, setDetail] = useState('');
  const [attachments, setAttachments] = useState([]);
  const [backdrop, setBackdrop] = useState(false);
  const [imagem] = useState([]);
  const [qtdCaracteresRestantes, setQtdCaracteresRestantes] = useState(1500);
  const [offerDetail, setOfferDetail] = useState({});
  const [arrayCatalog, setArrayCatalog] = useState([]);

  const fileTypes = ["pdf", "txt", "xml", "csv", "gif", "jpg", "jpeg", "png", "bmp", "odt", "ods",
    "odp", "odb", "odd", "doc", "xls", "ppt", "docx", "xlsx", "pptx", "tar", "gz",
    "tar", "xz", "zip"];

  const [fields, setFields] = useState({
    details: undefined,
    topic: undefined,
    catalog: formIdContext.numberCatalog,
    numberOffer: undefined,
    data: [],
    groupLevelOne: undefined
  });

  useEffect(() => {
    setGlobalMessage(ClearGlobalMessage);
  }, []);

  useEffect(async () => {

    setGlobalMessage(ClearGlobalMessage);

    /** validação dos grupos entre ofertas e usuário */
    let offersDisplay = await filterOffers(offers, userGroup);

    setArrayCatalog(offersDisplay);

    setFieldsDescription(fields);

    if (offers.length !== 0 && offersDisplay.length === 0) {

      let idContato = {
        "idcontato": dataFieldsIdentification.id
      }

      await GvpUpdateUserSCCD(idContato);

      SerproAlert.showDialogBlock(
        'Atenção',
        `Você não tem permissão para acessar as ofertas desse catálogo!`,
        TYPE_ALERT.WORN,
        () => {
          window.location.reload()
        },
        false,
        false
      );
      setInterval(() => window.location.reload(), 15000);
    }

    if (attachments && attachments.length === 0) {
      setAlertMaxQtdFiles(false)
      setAlertMaxFilesSize(false)
    }
  }, [offers]);

  useEffect(() => {
    setFieldsDescription(fields);
  }, [assunto, detail]);



  const filterOffers = async (offersCatalog, usergroup) => {

    let userGroup = usergroup;
    let updateUser = false;

    setBackdrop(true);

    await offersCatalog.map(offer => {
      if (offer.offerGroup.length > 0) return updateUser = true;
    })

    if (updateUser) {
      let idContato = {
        "idcontato": dataFieldsIdentification.id
      }
      await GvpUpdateUserSCCD(idContato);

      await GvpGetUser(dataFieldsIdentification.cpf, formIdContext.numberCatalog).then(res => {
        const user = res.data;
        userGroup = user.catalog.offerGroups
      });
    }

    let newArray = [];

    offersCatalog.forEach(async offer => {

      if (filterDuplicateOffers(offer, newArray)) return

      /** oferta sem grupo pode ser liberada para qualquer usuário */
      if (offer.offerGroup.length <= 0) {
        return newArray.push(offer);
      }

      /** oferta com grupo só pode ser liberada para usuários que tenham o mesmo grupo */

      await offer.offerGroup.forEach(async off => {
        if (userGroup.indexOf(off.personGroup) >= 0 && !(newArray.indexOf(offer) >= 0)) {
          newArray.push(offer);
        }
      });
    });

    /** adiciona os campoS 'id' e 'name' em cada oferta para poder ser usado na apresentação do select */

    newArray = newArray
      .map((offer, index) => {
        return {
          ...offer,
          id: index,
          name: offer.description,
        };
      });


    setBackdrop(false);

    return newArray;
  }

  const filterDuplicateOffers = (offer, arrayOffers) => {
    let offerVerify = arrayOffers.find(off => off.offeringNum == offer.offeringNum);
    if (offerVerify) return true
    return false
  }

  const setAssuntoValue = (e) => {

    localStorage.setItem("restartTimer", true);

    e.preventDefault();

    setBackdrop(true);

    setGlobalMessage(ClearGlobalMessage);

    setFields({
      details: undefined,
      topic: undefined,
      catalog: formIdContext.numberCatalog,
      numberOffer: undefined,
      data: [],
      groupLevelOne: undefined
    });

    const value = e.target.value;

    const offerSelected = arrayCatalog[value];

    if (!offerSelected || offerSelected === undefined || offerSelected === '') {
      setFields({ ...fields, topic: '' });
      setOfferDetail({});
      return setBackdrop(false);
    }

    DetailsOfferByNumberCatalog(offerSelected.numberCatalog, offerSelected.offeringNum)
      .then(response => {
        const offerDetail = response.data;
        Array.from(document.querySelectorAll("input")).forEach(
          input => (input.value = "")
        );

        Array.from(document.querySelectorAll("textarea")).forEach(
          textarea => (textarea.value = "")
        );

        // detail.pmsCoffDialog.sort(function (a, b) {        
        //   if (a.sequence < b.sequence) {
        //     return -1;
        //   } else {
        //     return true;
        //   }
        // });

        if (!response.data || !offerDetail) {
          setOfferDetail({});
          setGlobalMessage({
            message: 'Não foi possível recuperar detalhe da oferta',
            show: true,
          });
          return setBackdrop(false);
        }

        setOfferDetail(offerDetail);
        setFields({
          ...fields, numberOffer: offerDetail.offeringNum,
          topic: arrayCatalog[value].description,
          data: offerDetail.pmsCoffDialog,
          groupLevelOne: arrayCatalog[value].groupLevelOne
        });

        setBackdrop(false);
        setAssunto(value);

        updateTicket(offerDetail, arrayCatalog[value].description);
      })
      .catch(error => {
        console.error('error :>> ', error);

        setGlobalMessage({
          message: 'Não foi possível recuperar detalhe da oferta',
          show: true,
        });

        setBackdrop(false);
      })
  }

  const updateObject = (detail, topic) => {

    let date = new Date();
    date.setMinutes(date.getMinutes() + 12);
    date = new Date(date);

    let estado = true;
    let title = 'Assunto selecionado: ' + topic;
    let description = detail.moreInfoLongDescription;

    if (description != null || localStorage.getItem("estado") != null) {
      localStorage.setItem("estado", false);
      estado = false;
    }

    let objectTicket = {
      title: process.env.REACT_APP_ENVIRONMENT == 'prd' ? topic : "homologacao 0205HOMOLOG",
      group: 'NIVEL 1::SERVICE DESK',
      customer: dataFieldsIdentification.cpf,
      cep: dataFieldsIdentification.cep,
      cnpj: dataFieldsIdentification.cnpj,
      logradouro: dataFieldsIdentification.logradouro,
      category: (`${formIdContext.description}::${topic}::${formIdContext.numberCatalog}::${detail.offeringNum}`).toUpperCase(),
      categoria: (`${formIdContext.description}::${topic}::${formIdContext.numberCatalog}::${detail.offeringNum}`).toUpperCase(),
      canal: 'FORMULARIO',
      article: {
        subject: topic,
        body: description != null ? title + '\n Orientação recebida: ' + description?.replace(/<[^>]*>|&quot;/g, '') : title,
        internal: true,
        attachments: [],
      },
      owner: 'mxintstef',
      state: statusVerify(detail),
      finalizador: 'FORMULARIO',
      aplicarplano: detail.groupLevelOne ? false : true,
      /*       new_group: detail.groupLevelOne ? detail.groupLevelOne : null,
            gruposolucionador: detail.groupLevelOne ? detail.groupLevelOne : null, */
      session_time: date,
      idserpro: `${idTicket}`
    }

    if (detail.groupLevelOne) {
      objectTicket = {
        ...objectTicket,
        gruposolucionador: detail.groupLevelOne,
        new_group: detail.groupLevelOne
      }
    }

    return objectTicket
  }

  const updateTicket = (detail, topic) => {

    const object = updateObject(detail, topic);
    setBackdrop(false);

    GvpUpdateTicket(object)
      .then(response => {
        setBackdrop(false);
      })
      .catch(error => {
        console.error(error);

        SerproAlert.showDialogBlock(
          'Ops! Ocorreu um erro...',
          'Tente novamente por favor',
          TYPE_ALERT.ERROR,
          () => {
            setBackdrop(true);
          },
          false,
          false
        );
      });
  }

  const setDetalhesDemandaValue = (e) => {

    e.preventDefault();

    const value = e.target.value;

    setQtdCaracteresRestantes(1500 - value.length)
    setDetail(value);
    setFields({ ...fields, details: value });
    setFieldsDescription(fields);

  }

  const setInputFieldValue = (event, element, rmvMask) => {
    event.preventDefault();

    setGlobalMessage(ClearGlobalMessage);

    let { value } = event.target;

    if (value.length > 252) {
      setGlobalMessage({
        message: 'Somente é possível inserir informações de, no máximo, 254 caracteres. Por gentileza, inclua o restante no campo “Detalhes da demanda” ou como arquivo anexo.',
        show: true,
      });
      value = value.substring(0, 253);
    }

    value = value.trim();

    if (rmvMask) {
      value = REMOVE_MASK_CPF(value);
      value = REMOVE_MASK_CNPJ(value);
      value = REMOVE_MASK_TELEFONE(value);
    }

    element.value = value;

    setFieldsDescription(fields);

  };

  const setSelectFieldValue = (event, element) => {

    event.preventDefault();

    const { value } = event.target;

    value ? element.value = element.options[value].value : element.value = '';

    setFieldsDescription(fields);

  };

  const setTextareaFieldValue = (event, element) => {
    event.preventDefault();
    const { value } = event.target;

    if (!value) return;

    element.value = value;

    setFields({ ...fields, data: arrayCatalog });
    setFieldsDescription(fields);
  };

  const checkSetFilesSize = (_files) => {
    let tSize = 0
    for (let i = 0; i <= _files.length - 1; i++) {
      tSize += _files[i].size
    }
    return tSize
  }

  const handleFilesRead = async (event) => {
    const field = event.target.id;
    const file = event.target.files && event.target.files[0];

    if (field && file) {
      const base64 = await convertBase64(file);
      const type = base64.split(',')[0].split(';')[0].split(':')[1]

      if (file.size + checkSetFilesSize(attachments) > 41943040) {
        setAlertMaxFilesSize(true)
        return setBackdrop(false)
      }
      else {
        let isvalid = false;

        for (var i = 0; i < fileTypes.length; i++) {
          if (file.name.toLowerCase().endsWith(fileTypes[i]))
            isvalid = true;
        }

        if (isvalid) {
          setAttachments((old) => [...old, file]);

          imagem.push({
            filename: file.name,
            data: base64.split(',')[1],
            'mimetype': type,
            'contenttype': type
          })

          event.target.value = null;
          setBackdrop(false);

          setAlertFileType(false);
          callbackImage(imagem);
        }
        else {
          setAlertFileType(true);
          setAttachments([]);
          return setBackdrop(false);
        }
      }
    }
  }

  const handleFiles = async (event) => {
    setBackdrop(true)
    setAlertMaxFilesSize(false);

    if (attachments.length === 15) {
      setAlertMaxQtdFiles(true);
      return setBackdrop(false);
    }
    else {
      setAlertMaxQtdFiles(false);
      await handleFilesRead(event);
    }
  }

  const onRemoveFile = (index) => (e) => {
    e.preventDefault()
    setAttachments((old) => old.filter((f, i) => i !== index))
    imagem.splice(index, 1);

    if (checkSetFilesSize(attachments) < 41943040)
      setAlertMaxFilesSize(false);

    if (attachments.length <= 15)
      setAlertMaxQtdFiles(false);

    callbackImage(imagem)
  };

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader()
      fileReader.readAsDataURL(file)
      fileReader.onload = () => {
        resolve(fileReader.result)
      }
      fileReader.onerror = (error) => {
        reject(error)
      }
    })
  }

  const generateDynamicDetails = (element, index) => {

    if (!element) return (<></>);

    if (element.type === 'multValue') return renderSelect(element);

    if (element.checkbox) return renderRadio(element);

    return renderInput(element);
  }

  const statusVerify = (offer) => {
    if (offer.status === "ACTIVE") {
      localStorage.setItem("estado", false);
      return STATUS_TICKET_GVP_ENUM.RASCUNHO;
    }
    return STATUS_TICKET_GVP_ENUM.EM_ATENDIMENTO;
  }

  const renderRadio = (element) => {
    return (
      <>
        <div className="form-group col-md-12" >
          <label >
            {element.required ? element.serproDisplay + ' *' : element.serproDisplay}
          </label>
          {
            element.options.map(el => (
              <div className="form-group" >
                <div className="form-check " key={el.pmscooffDialogId}>
                  <input className="form-check-input" type="radio" name="exampleRadios" id="exampleRadios1" value="option1" checked />
                  <label className="form-check-label" for="exampleRadios1">
                    Default radio
                  </label>
                </div>
              </div>
            ))
          }
        </div>
      </>
    );
  }

  const renderInput = (element) => {

    let count = 0

    if (element.serproDisplay?.includes('CNPJ')) count++
    if (element.serproDisplay?.includes('CPF')) count++
    if (element.serproDisplay?.includes('CEP')) count++
    if (element.serproDisplay?.includes('Telefone')) count++
    if (count == 1) {
      return (
        <>
          <SerproFormInput
            divClass="col-md-12"
            name={element.required ? element.serproDisplay + ' *' : element.serproDisplay}
            title={element.tooltip ? element.tooltip : ''}
            hidden={element.hidden}
            readOnly={element.readonly}
            mask={element.serproDisplay?.includes('CNPJ') ? '99.999.999/9999-99' :
              element.serproDisplay?.includes('CPF') ? '999.999.999-99' :
                element.serproDisplay?.includes('CEP') ? '99.999-999' :
                  element.serproDisplay?.includes('Telefone') ? '(99)99999-9999' : ''}
            type='text'
            onChange={event => setInputFieldValue(event, element, true)}
            maxlength={253}
          />
        </>
      );
    } else if (element.numerico) {
      return (
        <>
          <SerproFormInput
            divClass="col-md-12"
            className="form-control"
            name={element.required ? element.serproDisplay + ' *' : element.serproDisplay}
            title={element.tooltip ? element.tooltip : ''}
            hidden={element.hidden}
            readOnly={element.readonly}
            mask={TAM_CAMPO_NUMERICO}
            maskChar=""
            onChange={event => setInputFieldValue(event, element)}
          />
        </>
      );
    } else if (element.multLineText) {
      return (
        /*Renderização dos campos extras*/
        <>
          <SerproFormTextArea
            divClass="col-md-12"
            name={element.required ? element.serproDisplay + ' *' : element.serproDisplay}
            title={element.tooltip ? element.tooltip : ''}
            hidden={element.hidden}
            readOnly={element.readonly}
            type="text"
            onChange={event => setTextareaFieldValue(event, element)}
            maxlength={253}
          />
        </>
      );
    } else {
      return (
        /*Renderização dos campos extras*/
        <>
          <SerproFormInput
            divClass="col-md-12"
            name={element.required ? element.serproDisplay + ' *' : element.serproDisplay}
            title={element.tooltip ? element.tooltip : ''}
            hidden={element.hidden}
            readOnly={element.readonly}
            mask={''}
            type='text'
            onChange={event => setInputFieldValue(event, element)}
            maxlength={253}
          />
        </>
      );
    }

  }

  const renderSelect = (element) => {

    const itemArray = element.options.map((item, index) => {
      return { ...item, id: index, name: item.description }
    });

    return (
      <SerproFormSelect
        divClass="col-md-12"
        name={element.required ? element.serproDisplay + ' *' : element.serproDisplay}
        title={element.tooltip ? element.tooltip : ''}
        onHidden={element.hidden}
        readOnly={element.readonly}
        value={element?.value}
        itemArray={itemArray}
        onChange={event => setSelectFieldValue(event, element)}
      />
    );
  }

  return (
    <>
      <p></p>

      <div className="form-row">
        <SerproFormSelect
          divClass="col-md-12"
          name="Assunto*"
          onChange={(e) => setAssuntoValue(e)}
          itemArray={arrayCatalog}
        />
      </div>
      {
        offerDetail &&
        offerDetail.moreInfoLongDescription &&
        (
          <div style={{ backgroundColor: '#fff999', marginBottom: '15px', padding: '20px', borderRadius: '3px' }}>
            <span dangerouslySetInnerHTML={{ __html: offerDetail.moreInfoLongDescription }} />
          </div>
        )
      }

      {(offerDetail?.pmsCoffDialog?.length == 1 && offerDetail?.pmsCoffDialog[0]?.type !== 'multValue' && !offerDetail?.pmsCoffDialog[0]?.checkbox) &&
        <div style={{ color: '#ff5630', marginBottom: '10px', fontStyle: 'italic' }}>
          <span>ATENÇÃO! O campo a seguir aceita, no máximo, 254 caracteres. Caso sua informação exceda este limite, por gentileza, inclua o restante no campo “Detalhes da demanda” ou como arquivo anexo.</span>
        </div>
      }

      {(offerDetail?.pmsCoffDialog?.length > 1 && offerDetail?.pmsCoffDialog[0]?.type !== 'multValue' && !offerDetail?.pmsCoffDialog[0]?.checkbox) &&
        <div style={{ color: '#ff5630', marginBottom: '10px', fontStyle: 'italic' }}>
          <span>ATENÇÃO! Os campos a seguir aceitam, no máximo, 254 caracteres. Caso sua informação exceda este limite, por gentileza, inclua o restante no campo “Detalhes da demanda” ou como arquivo anexo.</span>
        </div>
      }

      {offerDetail?.pmsCoffDialog?.length > 0 &&

        (
          offerDetail.pmsCoffDialog.map((element, index) =>
          (
            <>
              <div className="form-row" key={index}>
                {generateDynamicDetails(element, index)}
              </div>
            </>
          ))
        )
      }

      {
        !offerDetail && (<></>)
      }

      {/* { assunto === "0" && (
        <div className="form-row">
          { arrayCatalog.map((element, index) => (
            <>
              { element.element === 'input' && (
                <SerproFormInput
                  divClass="col-md-12"
                  key={index}
                  name={element.required ? element.label + ' *' : element.label}
                  type={element.type}
                  title={element.title}
                  minLength={element.minLength}
                  maxLength={element.maxlength}
                  placeholder={element.placeholder}
                  mask={element.mask}
                  required={element.required}
                  onChange={event => setInputFieldValue(event, element)}
                />
              )}

              { element.element === 'select' && (
                <SerproFormSelect
                  key={index}
                  divClass="col-md-12"
                  name={element.required ? element.label + ' *' : element.label}
                  type={element.type}
                  itemArray={element.data}
                  onChange={event => setSelectFieldValue(event, element)}
                />
              )}

              { element.element === 'textarea' && (
                <SerproFormTextArea
                  key={index}
                  divClass="col-md-12"
                  name={element.required ? element.label + ' *' : element.label}
                  type={element.type}
                  minLength={element.minLength}
                  maxLength={element.maxlength}
                  placeholder={element.placeholder}
                  onChange={event => setTextareaFieldValue(event, element)}
                />
              )}
            </>
          ))}
        </div>
      )} */}

      {/* {
        assunto === "1" &&
        (
          <>
            <div className="form-row">
              {
                arrayCatalog.map((element, index) => (
                  <>
                    {
                      element.element === 'input' &&
                      (
                        <SerproFormInput
                          divClass="col-md-12"
                          key={index}
                          name={element.label}
                          type={element.type}
                          title={element.title}
                          minLength={element.minLength}
                          maxLength={element.maxlength}
                          placeholder={element.placeholder}
                          mask={element.mask}
                          onChange={event => setInputFieldValue(event, element)}
                        />
                      )
                    }

                    {
                      element.element === 'select' &&
                      (
                        <SerproFormSelect
                          key={index}
                          divClass="col-md-12"
                          name={element.label}
                          type={element.type}
                          itemArray={element.data}
                          onChange={event => setSelectFieldValue(event, element)}
                        />
                      )
                    }

                    {
                      element.element === 'textarea' &&
                      (
                        <SerproFormTextArea
                          key={index}
                          divClass="col-md-12"
                          name={element.label}
                          type={element.type}
                          minLength={element.minLength}
                          maxLength={element.maxlength}
                          placeholder={element.placeholder}
                          onChange={event => setTextareaFieldValue(event, element)}
                        />
                      )
                    }
                  </>
                ))
              }
            </div>
          </>
        )
      } */}

      {/* {
        assunto === "2" &&
        (
          <>
            <div className="form-row">
              <MensagemInformativa>
                <span>Caro Cliente,</span>
                <p></p>
                <span>Não deixe de informar na descrição da demanda</span>
                <br></br>
                <span>&emsp;- CNPJ da Empresa</span>
                <p></p>
                <span>
                  Ao se tratar de algum valor, informe também o mês de referência, nr da Nota Fiscal, valores ou outras informações que possam
                  contribuir para agilizar na análise de seu caso.
                  </span>
              </MensagemInformativa>

              {
                arrayCatalog.map(element => (
                  <>
                    {
                      element.element === 'checkbox' &&
                      (
                        <>
                          <label className="col-md-12">{element.label}</label>
                          <Checkbox />
                          <label>Opção 1</label>
                          <Checkbox />
                          <label>Opção 2</label>
                          <Checkbox />
                          <label>Opção 3</label>
                          <Checkbox />
                          <label>Opção 4</label>
                          <Checkbox />
                          <label>Opção 5</label>
                        </>
                      )
                    }

                    {
                      element.element === 'radio' &&
                      (
                        <>
                          <div className="form-row">
                            <p>Problema</p>
                            <input type="radio" name="chooseone" value="Travamento do S.O." />
                            <label>Travamento do S.O.</label>
                            <input type="radio" name="chooseone" value="Aquecimento" />
                            <label>Aquecimento</label>
                            <input type="radio" name="chooseone" value="Falta de memória" />
                            <label>Falta de memória</label>
                          </div>
                        </>
                      )
                    }
                  </>
                ))
              }
            </div>
          </>
        )
      } */}

      <div className="form-row">
        <SerproFormTextArea
          divClass="col-md-12"
          name="Detalhes da demanda*"
          type="text"
          //value={detail}
          onChange={(e) => setDetalhesDemandaValue(e)}
          maxLength={1500}
        />
        <AlertQtdeCaracteresRestantes className="mt-2">Restam {qtdCaracteresRestantes} caracteres.</AlertQtdeCaracteresRestantes>
      </div>

      <br /><br />

      <form>
        <div className="form-group">
          <input
            className={classes.displayNone}
            id="contained-button-file"
            type="file"
            onChange={(e) => handleFiles(e)}
            accept=".pdf,.txt,.xml,.csv,.gif,.jpg,.jpeg,.png,.bmp,.odt,.ods,.odp,.odb,.odd,
                    .doc,.xls,.ppt,.docx,.xlsx,.pptx,.tar,.gz,.tar,.xz,.zip"
          />
          <label htmlFor="contained-button-file">
            <Button
              variant="outlined"
              color="default"
              component="span"
              size="small"
              disableFocusRipple
              startIcon={<CloudUploadIcon />}
            >
              Anexar Arquivo {attachments && attachments.length > 0 && (
                <>
                  <b>&emsp;({attachments.length})</b>
                </>
              )}
            </Button>
          </label>

          <br />

          <span>
            <i>
              Anexos (até 15 arquivos totalizando 40 MB. Extensões permitidas: pdf, txt, xml, csv, gif, jpg, jpeg, png, bmp, odt, ods, odp, odb, odd, doc, xls, ppt, docx, xlsx, pptx, tar.gz, tar.xz ou zip)
            </i>
          </span>

          <p></p>

          {alertMaxQtdFiles && (
            <AlertQtdeArquivosAnexados>
              Somente podem ser enviados até
              &nbsp;<strong>15</strong>&nbsp; arquivos.
            </AlertQtdeArquivosAnexados>
          )}
          {alertMaxFilesSize && (
            <AlertTamanoArquivoAnexado>
              A soma total dos arquivos não pode ser maior que
              &nbsp;<strong>40MB</strong>&nbsp;.
            </AlertTamanoArquivoAnexado>
          )}
          {alertFileType && (
            <AlertTipoArquivo>
              Extensão não permitida
            </AlertTipoArquivo>
          )}
        </div>

        <div className="col-md-12">
          {attachments &&
            attachments.map((f, i) => (
              <FileItem className="col-md-12" key={i}>
                <p>{f.name}</p>
                <p>{(f.size / 1048576).toFixed(2)}MB</p>
                <Tooltip title="Excluir" placement="left">
                  <Button color="secondary" variant="text" size="small" disableRipple onClick={onRemoveFile(i)}>
                    <DeleteIcon color="error" fontSize="small" />
                  </Button>
                </Tooltip>
              </FileItem>
            ))}
        </div>

        <Dialog
          className={classes.modalBackdrop}
          open={backdrop}
          disableBackdropClick
          disableEscapeKeyDown
          fullScreen
        >
          <Backdrop className={classes.backdrop} open={backdrop}>
            <CircularProgress color="inherit" />
            <span className={classes.textBackdrop}>Verificando suas permissões de acesso as ofertas, por favor aguarde.</span>
          </Backdrop>
        </Dialog>
      </form>
    </>
  )
}

export default DescricaoForm

DescricaoForm.prototype = {
  setFieldsDescription: PropTypes.func,
  setGlobalMessage: PropTypes.func,
  offers: PropTypes.array,
  userGroup: PropTypes.array,
}

const useStyles = makeStyles((theme) => ({
  displayNone: {
    display: 'none !important;',
  },
  modalBackdrop: {
    opacity: '0.5',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
    display: 'flex',
    flexDirection: 'column'
  },
  textBackdrop: {
    marginTop: '20px'
  }
}));
