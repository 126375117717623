import React, { useEffect } from 'react'
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import Error from './components/error/Error'
import { useFormId } from './screens/form/FormIdContext'

import ResearchSatisfaction from './screens/researchSatisfaction/index'
import UpperScreenForm from './screens/upperscreenform/UpperScreenForm.screen'
import UpperScreenHeader from './screens/upperscreenheader/UpperScreenHeader.screen'
import Redirect from './screens/redirectSingleAcess/RedirectSingleAcess.screen'
import ScreenSection from './screens/screenSection/screenSection.screen'

function Routes() {
  return (

    <BrowserRouter>
      <Switch>

        <Route
          exact path="/"
          component={UpperScreenHeader}
        />
        <Route
          path="/upperScreenForm/:id"
          component={UpperScreenForm}
        />
        <Route
          exact path="/form-v2/:id"
          component={ScreenSection}
        />
        <Route
          path="/SingleAccess"
          component={Redirect}
        />
        <Route
          path="/researchSatisfaction/:ticket_number"
          component={ResearchSatisfaction}
        />
        <Route path="*" exact={true} component={Error} />
      </Switch>
    </BrowserRouter>
  )
}

export default Routes
