import { useEffect, useState } from 'react';
import PinDrop from '@material-ui/icons/PinDrop'

import img from '../../assets/img/LogoICN.png'

import Grid from '@material-ui/core/Grid'
import { Container } from './SerproHeader.style'
import SerproTimer from '../serprotimer/SerproTimer'
import { useFormId } from '../../screens/form/FormIdContext'

const publicIp = require('public-ip');

function SerproHeader() {
  const [ip, setIp] = useState('');

  const { formIdContext } = useFormId();
  
  useEffect(() => {
    (async () => {
      setIp(await publicIp.v4());
    })();
  }, [])

  return (
    <Container>
      <div className="col-md-9">
        <a
          rel="noreferrer"
          href="/"
        >
          {
            formIdContext.numberCatalog === '3467' ? (
              <img
                src={img}
                width="80px"
                style={{ marginTop: '16px', marginLeft: '20px', borderRadius: '3px' }}
                alt=""
              />
            ) : 
            <img
            
            src={'https://www.serpro.gov.br/++resource++serpro.portalserprotema/img/logo-serpro.png'}
            width="150px"
            style={{ marginTop: '16px', marginLeft: '20px' }}
            alt=""
          />
          }
        </a>
      </div>

      <div className="col-md-3">
        <img
          src={process.env.PUBLIC_URL + '/ellipse.png'}
          width="40"
          height="40"
          style={{
            marginLeft: '0px',
            position: 'absolute',
            marginTop: '20px',
          }}
          alt=""
        />
        <Grid
          item
          xs={8}
          style={{
            marginLeft: '9px',
            position: 'absolute',
            marginTop: '27px',
          }}
        >
          <PinDrop style={{ color: 'white' }} />
        </Grid>
        <label
          style={{
            marginLeft: '50px',
            position: 'absolute',
            marginTop: '25px',
            color: 'white',
            fontWeight: 'bold',
            fontSize: '20px',
          }}
        >
          {ip}
        </label>

        <SerproTimer />
      </div>
    </Container>
  )
}

export default SerproHeader;
