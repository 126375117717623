/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { ButtonContainer } from '../../screens/form/FormStyle';
import { makeStyles } from '@material-ui/core/styles';
import LacunaWebPKI from 'web-pki';

import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@material-ui/core';

export default function CertificateModal(props) {

  const { openDialog, onCloseDialog, formIdContext } = props;
  const classes = useStyles();
  const [backdrop, setBabackdrop] = useState(false);
  const [certificates, setCertificates] = useState([]);
  const [certVisible, setCertVisible] = useState(true);

  useEffect(() => {

    if (document.getElementById('certificateSelect') == null && formIdContext.useDigitalCertificate) {
      start();
    }

  });

  var pki = new LacunaWebPKI('ASYAanNmaWRkbGUubmV0LHdlYnBraS5sYWN1bmFzb2Z0d2FyZS5jb20AAAABClKvO1J22vAD+YmfANiKQLbcLE1lNraPKCel6tRM+ZxR+h6M/crtJYRRVGGz7hrdbM0Y0mfTu15RMYGqQMi1QNZS6GrT4vNzIayv552Fl0EFWQA7jWlctUwfYoHRHVEnCNx9YGXDiA9+yDoGlVwgTR7fjzNeS3Fen1MVIyKBF464gN0JvdiCRJMI47JGVDkPmKjcrYIvJs6y5Lg25RW4ZnBKVruS+HR2s3k8ZrV4y4RCQE4UYMKbukF9vsF+JqAEifRlPq2xLcrNdxBveVDSXS/LRHAcrZrMM+Iw4A79jl0ngWPcy+CwinAhT+3dxVo5ZWMRQFpmTkylEMDvTjV9wQ==');

  function start() {
    pki.init({ ready: onWebPkiReady });
  }

  function onWebPkiReady() {
    refreshCertificates();
  }

  function refreshCertificates() {
    pki.listCertificates({
      // filter: function (cert) {
      //   return (cert.pkiBrazil.cpf != null);
      // },
      selectId: 'certificateSelect'
    }).success(function (certs) {
      setCertificates(certs);
      onCertificateSelectChange();
    });
  }

  function onCertificateSelectChange() {
    var element = document.getElementById('certificateSelect');
    if (document.getElementById('certificateSelect') != null)
      showCertDetails(element.value);
  }

  function showCertDetails(thumbprint) {
    var cert = certificates.find(function (c) { return c.thumbprint === thumbprint; });
    if (!cert) return;

    if (cert.pkiBrazil.responsavel != null &&
      cert.pkiBrazil.cpf != null &&
      cert.email != null) {

      setCertVisible(true);

      localStorage.setItem("nameCertificate", cert.pkiBrazil.responsavel);
      localStorage.setItem("cpfCertificate", cert.pkiBrazil.cpf);
      localStorage.setItem("emailCertificate", cert.email);

      setBabackdrop(false);
      return onCloseDialog({});
    }
    else
      setCertVisible(false);
  }

  return (
    <>
      <Dialog
        open={openDialog}
        onClose={onCloseDialog}
        disableEscapeKeyDown={false}
        disableBackdropClick={false}
        maxWidth="md"
        fullWidth={false}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          <Box className={classes.modalTitle}>{"Selecione seu Certificado Digital"}</Box>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <select id="certificateSelect"></select>
            <div hidden={certVisible} className={classes.erroCert}>Certificado inválido!</div>
          </DialogContentText>
        </DialogContent>
        <DialogActions className={classes.boxInfoRequired}>
          <ButtonContainer>
            <Button
              onClick={onCertificateSelectChange}
              color="primary"
              variant="contained"
              className={classes.buttonWidthDefault}
            >
              Carregar
            </Button>
          </ButtonContainer>
        </DialogActions>
        <Backdrop className={classes.backdrop} open={backdrop}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Dialog>
    </>
  )

}

CertificateModal.prototype = {
  openDialog: PropTypes.bool,
  onCloseDialog: PropTypes.func,
}

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
    display:'flex',
    flexDirection:'column'
  },
  buttonWidthDefault: {
    width: '630px',
  },
  boxInfoRequired: {
    display: 'grid',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'end',
    padding: '30px',
    marginBottom: '0',
    marginTop: '-30px',
    fontSize: '10pt',
    fontWeight: 'bold',
    marginLeft: '15px'
  },
  modalTitle: {
    fontSize: '25px',
    fontWeight: 'bold',
  },
  erroCert: {
    color: '#FF0000'
  }
}));
