import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 80px;
  background-color: #6666DD;
  margin: 0px ;
  outline:0px;
  box-sizing: border-box;

  h2{
    margin: 2% 0% 0% 5%;
    color: #FFF;
    /* font-size: 20px; */
  }
`;

