/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles';
import { VALIDAR_CATALOGO_MULTICLIENT } from '../../utils/ValidationForm';

import CallCenterIcon from '../../assets/img/callcenter.png'
import ChatIcon from '../../assets/img/chat.png'
import EmailIcon from '../../assets/img/email.png'
import FormIcon from '../../assets/img/form.png'
import WhatsappIcon from '../../assets/img/whatsapp.png';

import { FormIdContext } from '../../screens/form/FormIdContext';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
} from '@material-ui/core';

export default function RedirectModal(props) {

  useEffect(() => {
    let validation = VALIDAR_CATALOGO_MULTICLIENT(formIdContext.numberCatalog);
    setCatalogMultiClient(validation);
  })

  const formIdContext = useContext(FormIdContext);
  const [catalogMultiClient, setCatalogMultiClient] = useState(false);

  const { openDialog, onCloseDialog } = props;

  const classes = useStyles();

  return (

    <>
      {formIdContext.numberCatalog === '3192' &&
        <Dialog
          open={openDialog}
          onClose={onCloseDialog}
          maxWidth="md"
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <DialogContentText
              id="alert-dialog-description"
              className={classes.text}
            >
              O Serpro dispõe de outros meios de comunicação! Todos <b>disponíveis 24 horas</b> por dia,
              <b> 07 dias por semana</b> e <b>totalmente gratuito!</b> Confira os canais de atendimento abaixo:

              <ul className={classes.listItens}>
                <li><img src={FormIcon} width='20px' /> Formulários Entrônicos: <a href="https://www.serpro.gov.br/menu/suporte/css" target="_blank">https://www.serpro.gov.br/menu/suporte/css</a></li>
                <li><img src={ChatIcon} width='20px' /> Chatbot/Chat Humano: <a href="https://www.serpro.gov.br/menu/suporte/css" target="_blank">https://www.serpro.gov.br/menu/suporte/css</a></li>
                <li><img src={WhatsappIcon} width='20px' /> WhatsApp: <a href="https://wa.me/556135356810" target="_blank">https://wa.me</a></li>
              </ul>

              Ao entrar em contato não esqueça de <b>informar o número do seu chamado</b> para continuidade do atendimento!
            </DialogContentText>

          </DialogContent>
          <DialogActions className={classes.boxInfoRequired}>
            <Button
              onClick={onCloseDialog}
              color="primary"
              variant="contained"
              className={classes.button}
            >
              Fechar
            </Button>
          </DialogActions>
        </Dialog>
      }

      {formIdContext.numberCatalog !== '3192' && catalogMultiClient &&
        <Dialog
          open={openDialog}
          onClose={onCloseDialog}
          maxWidth="md"
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <DialogContentText
              id="alert-dialog-description"
              className={classes.text}
            >
              O meio de comunicação via formulário está <b>disponível 24 horas</b> por dia,
              <b> 07 dias por semana</b> e <b>totalmente gratuito!</b>
              <ul className={classes.listItens}>
                <li><img src={FormIcon} width='20px' /> Formulários Entrônicos: <a href="https://www.serpro.gov.br/menu/suporte/css" target="_blank">https://www.serpro.gov.br/menu/suporte/css</a></li>
              </ul>
              Ao entrar em contato não esqueça de <b>informar o número do seu chamado</b> para continuidade do atendimento!
            </DialogContentText>
          </DialogContent>
          <DialogActions className={classes.boxInfoRequired}>
            <Button
              onClick={onCloseDialog}
              color="primary"
              variant="contained"
              className={classes.button}
            >
              Fechar
            </Button>
          </DialogActions>
        </Dialog>
      }
      {formIdContext.numberCatalog !== '3192' && !catalogMultiClient &&
        <Dialog
          open={openDialog}
          onClose={onCloseDialog}
          maxWidth="md"
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogContent>
            <DialogContentText
              id="alert-dialog-description"
              className={classes.text}
            >
              O Serpro dispõe de outros meios de comunicação! Todos <b>disponíveis 24 horas</b> por dia,
              <b> 07 dias por semana</b> e <b>totalmente gratuito!</b> Confira os canais de atendimento abaixo:
              <ul className={classes.listItens}>
                <li><img src={FormIcon} width='20px' /> Formulários Entrônicos: <a href="https://www.serpro.gov.br/menu/suporte/css" target="_blank">https://www.serpro.gov.br/menu/suporte/css</a></li>
                <li><img src={ChatIcon} width='20px' /> Chatbot/Chat Humano: <a href="https://www.serpro.gov.br/menu/suporte/css" target="_blank">https://www.serpro.gov.br/menu/suporte/css</a></li>
              </ul>
              Ao entrar em contato não esqueça de <b>informar o número do seu chamado</b> para continuidade do atendimento!
            </DialogContentText>

          </DialogContent>
          <DialogActions className={classes.boxInfoRequired}>
            <Button
              onClick={onCloseDialog}
              color="primary"
              variant="contained"
              className={classes.button}
            >
              Fechar
            </Button>
          </DialogActions>
        </Dialog>
      }

    </>
  )

}

RedirectModal.prototype = {
  openDialog: PropTypes.bool,
  onCloseDialog: PropTypes.func,
}

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  button: {
    width: '80px',
  },
  boxInfoRequired: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '30px',
    fontSize: '10pt',
    fontWeight: 'bold',
  },
  modalTitle: {
    fontSize: '25px',
    fontWeight: 'bold',
  },
  listItens: {
    marginTop: '30px',
  },
  text: {
    color: '#000'
  }
}));
