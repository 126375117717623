import styled from 'styled-components'

const Container = styled.form`
  width: 100%;
  box-shadow: 2px 2px 2px 2px rgba(0.2, 0.2, 0.2, 0.2);
  background-color: #e6e6ff;
  padding: 1%;
`

const Title = styled.h2`
  color: #595858;
  font-weight: bold;
  font-size: 20px;
  line-height: 126.9%;
  margin-left: 30px;
  padding-top: 25px;
`

const Description = styled.p`
  margin-bottom: 2%;
  font-size: 14;
  margin-left: 5px;
  color: #6666DD;
  font-weight: bold;
`
const Label = styled.p`
  margin-bottom: 2%;
  font-size: 12;
  color: grey;
  font-weight: bold;
`
const ButtonContainer = styled.div`
  width: 100%;
  margin: 2%;
  display: flex;
  justify-content: space-evenly;
  padding-left: 5%;
  padding-right: 5%;
`
const CleanButton = styled.button`
  outline: none;
  border: 2px solid #6666DD;
  border-radius: 5px;
  width: 70%;
  height: 45px;
  font-weight: bold;
  background-color: #fff;
  color: #6666DD;
  transition: background-color 0.2s;
  cursor: pointer;
  :hover {
    background-color: #979a9a;
  }
`
export { Container, Title, Description, Label, ButtonContainer, CleanButton }
