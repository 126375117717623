import { VALIDAR_EMAIL, VALIDAR_FORMATO_CNPJ, VALIDAR_CNPJ } from "./ValidationForm";


export const identificationTabValidations = (data, newUser) => {
  let result = {
    message: '',
    passed: true,
  };


  if (!data.cpf || data.cpf === '') {
    return result = {
      message: 'O campo CPF deve ser preenchido',
      passed: false,
    };
  }

  if (data.cpf && data.cpf.length !== 11) {
    return result = {
      message: 'O campo CPF deve ter 11 dígitos',
      passed: false
    };
  }

  if (!data.name || data.name === '') {
    return result = {
      message: 'O campo NOME DO SOLICITANTE deve ser preenchido',
      passed: false
    };
  }

  let fullName = data.name.trimStart();
  let arrayName = fullName.split(' ');


  if (arrayName.length < 2 || arrayName[1] === '') {
    return result = {
      message: 'Deve ser inserido o nome completo do solicitante (composto de pelo menos um nome e um sobrenome)',
      passed: false
    };
  }

  if (newUser) {

    /*if(message === 'CEP inválido') {
      return result = {
        message: message,
        passed: false
      };
    }

    if (!data.cep || data.cep === '') {
      return result = {
        message: 'O campo CEP deve ser preenchido',
        passed: false
      };
    }

    if (data.cep && data.cep.length !== 8) {
      return result = {
        message: 'O campo CEP deve ter 8 dígitos',
        passed: false
      };
    }

    if (!data.logradouro || data.logradouro === '') {
      return result = {
        message: 'O campo LOGRADOURO deve ser preenchido',
        passed: false
      };
    }

    if (!data.bairro || data.bairro === '') {
      return result = {
        message: 'O campo BAIRRO deve ser preenchido',
        passed: false
      };
    }

    if (!data.uf || data.uf === '') {
      return result = {
        message: 'O campo UF deve ser preenchido',
        passed: false
      };
    }

    if (!data.cidade || data.cidade === '') {
      return result = {
        message: 'O campo MUNICÍPIO deve ser preenchido',
        passed: false
      };
    }*/

    if (!data.email || data.email === '') {
      return result = {
        message: 'O campo EMAIL deve ser preenchido',
        passed: false
      };
    }

    if (data.email && !VALIDAR_EMAIL(data.email)) {
      return result = {
        message: 'O EMAIL informado é inválido',
        passed: false
      };
    }

    if (!data.ddd || data.ddd === '') {
      return result = {
        message: 'O campo DDD deve ser preenchido',
        passed: false
      };
    }

    if (data.ddd.length !== 2) {
      return result = {
        message: 'O campo DDD deve ter 2 dígitos',
        passed: false
      };
    }

    if (!data.telefone || data.telefone === '') {
      return result = {
        message: 'O campo TELEFONE deve ser preenchido',
        passed: false
      };
    }

    if (data.telefone.length < 8) {
      return result = {
        message: 'O campo TELEFONE deve ter 8 ou 9 dígitos',
        passed: false
      };
    }
  }

  return result;
};

export const fieldAssuntoValidations = (data) => {

  let result = {
    message: '',
    passed: true,
  };

  if (!data.topic || data.topic === '') {
    return result = {
      message: 'O campo ASSUNTO deve ser selecionado',
      passed: false,
    };
  }

  return result;
};

export const fieldDetalhesValidations = (data) => {

  let result = {
    message: '',
    passed: true,
    idMenssage: ''
  };

  if (!data || data === '') {
    return result = {
      message: 'O campo DETALHES DA DEMANDA deve ser preenchido',
      passed: false,
      idMenssage: 'detalhesDemandaVazio'
    };
  }

  if (data && data.length > 1500) {
    return result = {
      message: 'O campo DETALHES DA DEMANDA deve ter no máximo 1500 caracteres',
      passed: false,
      idMenssage: 'detalhesDemandaInvalidoCaracteres'
    };
  }

  return result;
};

export const dynamicFieldValidations = arrayData => {

  let resultValidations = {
    passed: true,
    message: undefined,
    element: undefined,
    idMenssage: undefined,
    fieldInvalid: undefined
  };

  if (arrayData.cnpjRequiredFormWeb && !arrayData.cnpj) {
    return resultValidations = {
      passed: false,
      message: arrayData.messageMinLengthField ||
        `O campo ${arrayData.cnpj.toUpperCase()} ser preenchido`,
      element: arrayData,
      idMenssage: 'campoVazio',
      fieldInvalid: arrayData.cnpj.toUpperCase()
    };
  }

  if (arrayData.cnpjRequiredFormWeb && arrayData.cnpj && arrayData.cnpj.length < 14) {
    return resultValidations = {
      passed: false,
      message: arrayData.messageMinLengthField ||
        `O campo ${arrayData.cnpj.toUpperCase()} deve ter no mínimo 14 caracteres`,
      element: arrayData,
      idMenssage: 'campoIncompleto',
      fieldInvalid: arrayData.cnpj.toUpperCase()
    };
  }

  if (arrayData && arrayData.length > 0) {
    for (let i = 0; i < arrayData.length; i++) {
      const element = arrayData[i];

      /** RULE: campo obrigatório */

      if (element.required && !element.value) {

        resultValidations = {
          passed: false,
          message: element.messageRequiredField || `O campo ${element.serproDisplay.toUpperCase()} deve ser preenchido`,
          element: element,
          idMenssage: 'campoVazio',
          fieldInvalid: element.serproDisplay.toUpperCase()
        };
        break;
      }

      // /** RULE: quantidade mínima de caracteres */
      // if (element.minLength > 0 && element.value.length < element.minLength) {
      //   resultValidations = {
      //     passed: false,
      //     message: element.messageMinLengthField ||
      //       `O campo ${element.label} deve ter no mínimo ${element.minLength} caracteres`,
      //     element: element,
      //   };
      //   break;
      // }

      // /** RULE: quantidade máxima de caracteres */
      // if (element.maxLength > 0 && element.value.length > element.maxLength) {
      //   resultValidations = {
      //     passed: false,
      //     message: element.messageMaxLengthField ||
      //       `O campo ${element.label} deve ter no máximo ${element.minLength} caracteres`,
      //     element: element,
      //   };
      //   break;
      // }
    }
  }

  return resultValidations;
};


export const cnpjValidation = cnpj => {

  if (!cnpj) {
    return {
      message: 'O campo cnpj é obrigatório',
      passed: false
    }
  }

  if (!VALIDAR_FORMATO_CNPJ(cnpj)) {
    return {
      message: 'O formato do cnpj é inválido',
      passed: false
    }
  }

  if (!VALIDAR_CNPJ(cnpj)) {
    return {
      message: 'O cnpj é inválido',
      passed: false
    }
  }

  return {
    message: '',
    passed: true
  }


}

export const emailValidation = email => {
  if (!email || email === '') {
    return {
      message: 'O campo EMAIL deve ser preenchido',
      passed: false,
    };
  }

  if (email && !VALIDAR_EMAIL(email)) {
    return {
      message: 'O EMAIL informado é inválido',
      passed: false,
    };
  }

  return {
    message: '',
    passed: true
  }
}

export const internacionalValidation = data => {

  if (!data.email || data.email === '') {
    return {
      message: 'emailVazio',
      passed: false
    };
  }

  if (data.email && !VALIDAR_EMAIL(data.email)) {
    return {
      message: 'emailInvalido',
      passed: false
    };
  }

  if (!data.fullName || data.fullName === '') {
    return {
      message: 'nomeVazio',
      passed: false
    }
  }

  let fullName = data.fullName.trimStart();
  let arrayName = fullName.split(' ');

  if (arrayName.length < 2 || arrayName[1] === '') {
    return {
      message: 'nomeIncompleto',
      passed: false
    };
  }

  if (data.address.addressComplete === undefined) {
    return {
      message: 'enderecoVazio',
      passed: false
    };
  }

  if (!data.phone.number || data.phone.number === '') {
    return {
      message: 'telefoneVazio',
      passed: false
    };
  }

  if (!data.empresa || data.empresa === '') {
    return {
      message: 'empresaVazio',
      passed: false
    };
  }

  return {
    message: '',
    passed: true
  }
}
