import React, { useState, useEffect } from 'react'
import { useParams, useLocation } from 'react-router-dom'
import { Backdrop, CircularProgress, makeStyles } from '@material-ui/core';
import { ListCatalog, requestAuthSCCD, getCatalogsForCpf, getUserSccd } from '../../service/GlobalService'
import UpperScreenHeaderContainer from '../../containers/upperscreenheadercontainer/UpperScreenHeaderContainer'
import { parseJwt } from '../../utils/jwtDecoder'
import { BackdropText } from '../upperscreenform/UpperScreenForm.style';
import { SerproAlert, SerproHeader } from '../../components';
import { TYPE_ALERT } from '../../components/serproalert/SerproAlert';
import { FormIdContext, useFormId } from './../form/FormIdContext';


function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

function UpperScreenHeader() {

  const classes = useStyles();
  const query = useQuery();
  const [catalogs, setCatalogs] = useState([]);
  const [state, setState] = useState('');
  const [code, setCode] = useState('');
  const [sessionState, setSessionState] = useState('');
  const [backdrop, setBackdrop] = useState(false);
  const { setFormIdContext, formIdContext, setFormIdContextAdd } = useFormId();
  const [textBackdrop, setTextBackdrop] = useState('');


  useEffect(async () => {
    getParams();
  });

  useEffect(async () => {



    if (code.length > 0 && code !== 'no params') {

      if (process.env.REACT_APP_FORM_SCCD_FORM_SECRET !== state) {
        SerproAlert.showSimpleDialog(
          'Atenção',
          "Esta requisição não foi gerada a partir do formulário stefanini. Por favor acesso o formulário e tente novamente.",
          TYPE_ALERT.WORN,
          () => {
            console.log('close dialog')
          },
          false,
          false
        );
        return
      }

      setTextBackdrop('Estamos consultado suas credenciais, por favor aguarde.');
      setBackdrop(true);


      const result = parseJwt(await getAuth());
      const cnpj = (await getUserSccd(result.CPF)).data.toString();

      setFormIdContext({ authorized: true, saUser: result.CPF, saUserCnpj: cnpj, auth_time: new Date(result.auth_time * 1000) });

      if (result) {

        if (catalogs.length === 0) {
          await getCatalogsForCpf(result.CPF).then(res => {
            setCatalogs(res.data);
            if (res.data.length === 0) {
              setBackdrop(false);
              SerproAlert.showSimpleDialog(
                'Atenção',
                "Nenhum catálogo foi encontrado para o seu perfil. Entre em contato com o seu gestor.",
                TYPE_ALERT.WORN,
                () => { },
                false,
                false
              );
              return
            }
            setBackdrop(false);
          });
        }
      }

    }

    if (code === 'no params') {
      if (catalogs.length === 0) {
        setTextBackdrop('Estamos consultado seus catalogos, por favor aguarde.');
        setBackdrop(true);
        await ListCatalog().then((res) => {
          setCatalogs(res.data);
          setBackdrop(false);
        });
      }
    }

  }, [code])

  const getParams = () => {

    let sessionState = query.get('session_state');
    let code = query.get('code');
    let state = query.get('state');

    if (code) {
      setSessionState(sessionState);
      setCode(code);
      setState(state);
      return
    }

    setSessionState('no params');
    setCode('no params');
    setState('no params');
  }

  const getAuth = () => {
    try {
      return requestAuthSCCD(code).then(async (res) => {
        return res.data.id_token;
      }).catch((error) => {
        console.log(error);
        setBackdrop(false);
        SerproAlert.showSimpleDialog(
          'Atenção',
          "Sua sessão expirou, faça acesso novamente!",
          TYPE_ALERT.WORN,
          () => {
            redirectToLoginUnico();
          },
          false,
          false
        );
      });
    } catch (error) {
      console.log(error);
    }
  }

  const redirectToLoginUnico = () => {
    window.location = process.env.REACT_APP_FORM_SCCD_KEY_CLOACK
  }

  return (
    <>
      <SerproHeader />
      <div style={{ width: "100%" }}>

        {(code.length > 0 && code !== 'no params') &&

          <UpperScreenHeaderContainer
            title="Atendimento aos usuários do Login Único(SERPRO)"
            description="Bem-vindo! Seu acesso foi autenticado pelo Login Único(SERPRO). Abaixo estão os catalogos que seu perfil possui acesso."
            catalog={catalogs}
            code={code}
          >

          </UpperScreenHeaderContainer>
        }

        {code === 'no params' &&
          <UpperScreenHeaderContainer
            title="Atendimento aos usuários"
            catalog={catalogs}
          >
          </UpperScreenHeaderContainer>
        }

        <Backdrop className={classes.backdrop} open={backdrop}>
          <CircularProgress color="inherit" />
          <span className={classes.textBackdrop}>{textBackdrop}</span>
        </Backdrop>

      </div>
    </>
  )
}

export default UpperScreenHeader


const useStyles = makeStyles((theme) => ({
  modalBackdrop: {
    opacity: '0.5',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
    display: 'flex',
    flexDirection: 'column'
  },
  textBackdrop: {
    marginTop: '20px'
  }
}));
