import { confirmAlert } from 'react-confirm-alert'
import 'react-confirm-alert/src/react-confirm-alert.css'

export const TYPE_ALERT = {
  SUCCESS: 'success',
  WORN: 'worn',
  ERROR: 'error',
}

export default class SerproAlert { }

//TODO: Customizar a vies layout
const alert = (
  title: String,
  menssage: String,
  type: String,
  buttons: Array,
) => {
  confirmAlert({
    title: title,
    message: menssage,
    buttons: buttons,
    customUI: ({ onClose }) => {
      return <div className="custom-ui"></div>
    },
  })
}

SerproAlert.showDialog = function (
  title: String,
  menssage: String,
  type: String,
  buttons: Array,
) {
  confirmAlert({
    title: title,
    message: menssage,
    buttons: buttons,
  })
}

SerproAlert.showDialogBlock = function (
  title: String,
  menssage: String,
  type: String,
  onClick: Function,
  backdropClick: Boolean,
  closeOnEscape: Boolean,
) {
  var buttons = [
    {
      label: 'OK',
      onClick: onClick,
    },
  ]
  confirmAlert({
    title: title,
    message: menssage,
    buttons: buttons,
    backdropClick,
    closeOnEscape
  })

  return this
}

SerproAlert.showSimpleDialog = function (
  title: String,
  menssage: String,
  type: String,
) {
  var buttons = [
    {
      label: 'OK',
      onClick: () => { },
    },
  ]
  alert(title, menssage, null)
  confirmAlert({
    title: title,
    message: menssage,
    buttons: buttons,
  })
  return this
}

SerproAlert.showSimpleDialog = function (
  title: String,
  menssage: String,
  type: String,
  onClick: Function,
) {
  var buttons = [
    {
      label: 'OK',
      onClick: onClick,
    },
  ]
  confirmAlert({
    title: title,
    message: menssage,
    buttons: buttons,
  })

  return this
}

SerproAlert.showDialogExpired = function (
  title: String,
  menssage: String,
  type: String,
  onClick: Function,
  backdropClick: Boolean,
  closeOnEscape: Boolean,
) {
  var buttons = [
    {
      label: 'Recarregar',
      onClick: onClick,
    },
  ]
  confirmAlert({
    title: title,
    message: menssage,
    buttons: buttons,
    backdropClick,
    closeOnEscape
  })

  return this
}

SerproAlert.showDialogInative = function (
  title: String,
  menssage: String,
  type: String,
  onClick: Function,
  backdropClick: Boolean,
  closeOnEscape: Boolean,
) {
  var buttons = [
    {
      label: 'Ciente',
      onClick: onClick,
    },
  ]
  confirmAlert({
    title: title,
    message: menssage,
    buttons: buttons,
    backdropClick,
    closeOnEscape
  })

  return this
}
