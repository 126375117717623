import React from 'react'
// import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types'

import { Container, Title, Description } from './SelectedFormContainer.style'

function SelectedFormContainer(props) {
  const { title, description } = props
  // const history = useHistory();

  // const cleanData = (e) => {
  //   history.push('/')
  // }

  return (
    <Container>
      <div style={{ height: "100%", backgroundColor: "#e6e6ff" }}>
        <div className="row">
          <div className="col-md-10">
            <Title>{title}</Title>
            <div className="row" style={{ marginLeft: "30px", marginTop: "-5px" }}>
              <Description>{description}</Description>
            </div>
          </div>
          {/* <div className="col-md-2" style={{ marginTop: "20px" }}>
            <ButtonContainer>
              <CleanButton onClick={(e) => cleanData(e)}>VOLTAR</CleanButton>
            </ButtonContainer>
          </div> */}
        </div>
      </div>

      <>{props.children}</>
    </Container>
  )
}

export default SelectedFormContainer

SelectedFormContainer.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
}
