import React, { useState } from 'react'
import { useParams } from 'react-router-dom'

import { Container } from './style'
import { apiSerpro  } from '../../service/index'
import { Sucess, Loading } from '../../styles/global'
import Error from '../../components/error/Error'
import { REACT_CONTINGENCY } from '../../utils/ConstDatas'

function Index() {
  const [sucess, setSucces] = useState(false)
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(false)
  let { ticket_number } = useParams()
  const note = {
    nota: 0,
    state_id: 10,
  }

  const hasChange = async (value) => {
    setLoading(true)
    note.nota = value
    try {
      const res = await apiSerpro.put(
        `/zammad/tickets/${ticket_number}`,
        note,
        {
          headers: {
            Authorization: '4d072840-4d79-40ed-8344-0cec31548333',
          },
        },
      )

      setSucces(res.status === 200 || 201 ? true : false)
    } catch (error) {
      setError(true)
    } finally {
      setLoading(false)
    }
  }
  return (
    <Container>
      <h2>Como foi sua experiência conosco ?</h2>
      <select onChange={(e) => hasChange(e.target.value)}>
        <option value="0">Nota:</option>
        <option value={1}>1</option>
        <option value={2}>2</option>
        <option value={3}>3</option>
        <option value={4}>4</option>
        <option value={5}>5</option>
      </select>
      {loading && (
        <Loading style={{ marginLeft: '0px', marginTop: '20px' }}>
          Carregando...
        </Loading>
      )}
      {error && (
        <Error content={<p> O sistema de Gestão de Atendimento da Central de Serviços SERPRO (CSS) está em manutenção temporariamente. 
          <br />Por gentileza, <a href={REACT_CONTINGENCY} target="_blank">clique aqui</a> para acessar o formulário de contingência.</p>}/>

      )}
      {sucess && (
        <Sucess style={{ marginLeft: '0px', marginTop: '20px' }}>
          Obrigado sua particação é muito importante!
        </Sucess>
      )}
    </Container>
  )
}

export default Index
