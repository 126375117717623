import styled from 'styled-components';

export const PageNotFound = styled.p`
  margin: 2%;
  font: 500 30px 'Roboto';
`;

export const BackdropText = styled.div`
  display: flex;
  text-align: center;
  align-items: center;
  font-size: large;
  font-weight: normal;
  font-family: 'Roboto';
  font-style: italic;
  padding: 15px;
`;

