import styled from 'styled-components'

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: auto;
  margin-top: 10%;

  select{
    margin-top: 2%;
    border-radius: 5px;
    border: 2px solid silver;
    width: 20%;
    height: 30px;
    background-color:#fff;
    :hover{
      border: 2px solid #3A6590
    }

    option * {
      background-color: green;
      text-align: center;
    }
  }
`