import { apiCep } from "./apiCep";

/*
Exemplo de retorno de objeto:
{
    "cep": "01001-000",
    "logradouro": "Praça da Sé",
    "complemento": "lado ímpar",
    "bairro": "Sé",
    "localidade": "São Paulo",
    "uf": "SP",
    "ibge": "3550308",
    "gia": "1004",
    "ddd": "11",
    "siafi": "7107"
}
*/
export const MOUNT_CEP = (cep) => {
  return apiCep.get(`${cep}/json/`);
}