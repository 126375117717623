import axios from 'axios'

export const apiUser = axios.create({
  baseURL: process.env.REACT_APP_ZCLIENT,
  headers: {
    Authorization: `Bearer ${process.env.REACT_APP_ZCLIENT_AUTH}`,
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': '*',
    'Access-Control-Allow-Headers': '*'
  }
});

export const apiSerpro = axios.create({
  baseURL: process.env.REACT_APP_PSERPRO,
  headers: {
    'Authorization': `Bearer ${process.env.REACT_APP_PSERPRO_AUTH}`,
    'Accept': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': '*',
    'Access-Control-Allow-Headers': '*'
  },
});

export const apiZerpro = axios.create({
  baseURL: process.env.REACT_APP_ZSERPRO,
  headers: {
    'Authorization': `${process.env.REACT_APP_ZSERPRO_AUTH}`,
    'Accept': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': '*',
    'Access-Control-Allow-Headers': '*'
  },
});

export const zintegratorApi = axios.create({
  baseURL: process.env.REACT_APP_ZINTEGRATOR,
  headers: {
    Authorization: `bearer ${process.env.REACT_APP_ZINTEGRATOR_AUTH}`,
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': '*',
    'Access-Control-Allow-Headers': '*'
  },
});

export const apiDigitalCertificate = axios.create({
  baseURL: `https://cssinter.serpro.gov.br`,
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': '*',
    'Access-Control-Allow-Headers': '*'
  },
});

export const GetAuthTokenGvp = axios.create({
  baseURL: `${process.env.REACT_APP_GVP}/api/token/GetToken?usuario=${process.env.REACT_APP_GVP_USER}&senha=@$3rpro@!integra`,
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': '*',
    'Access-Control-Allow-Headers': '*'
  }
});

export const gvp = axios.create({
  baseURL: process.env.REACT_APP_GVP,
  headers: {
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': '*',
    'Access-Control-Allow-Headers': '*'
  }
});

export const keycloakSerpro = axios.create({
  baseURL: process.env.REACT_APP_FORM_SCCD_HOST,
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
  },
});



