import { useContext, useEffect, useState } from 'react'
import SerproFormSelectV2 from "../../../components/serproformselect/SerproFormSelectV2";
import SerproFormInputV2 from '../../../components/serproforminput/SerproFormInputV2';
import SerproFormTextAreaV2 from '../../../components/serproformtextarea/SerproFormTextAreaV2';
import { SerproFormTextArea } from '../../../components';
import { AlertQtdeCaracteresRestantes } from '../../form/FormStyle';
import { GvpUpdateUserSCCD, GvpGetUser, DetailsOfferByNumberCatalog, GvpUpdateTicket, updateTicketZammad } from '../../../service/GlobalService';
import { useFormId } from '../../form/FormIdContext';
import Alert from '@mui/material/Alert';
import AlertTitle from '@mui/material/AlertTitle';
import Stack from '@mui/material/Stack';
import { Button, Tooltip } from '@material-ui/core';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import DeleteIcon from '@material-ui/icons/Delete';
import { TAM_CAMPO_NUMERICO, STATUS_TICKET_GVP_ENUM } from './../../../utils/ConstDatas';
import { REMOVE_MASK_CNPJ, REMOVE_MASK_CPF, REMOVE_MASK_TELEFONE } from '../../../utils/ValidationForm';
import { Lang } from '../../../utils/Languages';
import { useTranslation } from 'react-i18next';

function DescricaoV2(props) {

    const { offers,
        setFieldsDescription,
        setControllerBackdrop,
        setAlertMessage,
        setGenerateGenericDialog,
        userGroup,
        callbackImage } = props;

    const { setFormIdContext, formIdContext, setFormIdContextAdd } = useFormId();
    const { t, i18n: { changeLanguage, language } } = useTranslation();
    const [arrayCatalog, setArrayCatalog] = useState([]);
    const [attachments, setAttachments] = useState([]);
    const [offerDetail, setOfferDetail] = useState({});
    const [assunto, setAssunto] = useState('');
    const [qtdCaracteresRestantes, setQtdCaracteresRestantes] = useState(1500);
    const [detail, setDetail] = useState('');
    const [imagem] = useState([]);

    const fileTypes = ["pdf", "txt", "xml", "csv", "gif", "jpg", "jpeg", "png", "bmp", "odt", "ods",
        "odp", "odb", "odd", "doc", "xls", "ppt", "docx", "xlsx", "pptx", "tar", "gz",
        "tar", "xz", "zip"];

    const [fields, setFields] = useState({
        details: undefined,
        topic: undefined,
        catalog: formIdContext.numberCatalog,
        numberOffer: undefined,
        data: [],
        groupLevelOne: undefined
    });

    useEffect(async () => {
        /** validação dos grupos entre ofertas e usuário */
        let offersDisplay = await filterOffers(offers, userGroup);

        setArrayCatalog(offersDisplay);

        if (offers.length !== 0 && offersDisplay.length === 0) {

            let idContato = {
                "idcontato": formIdContext.id
            }

            await GvpUpdateUserSCCD(idContato);

            setGenerateGenericDialog(true, 'Atenção', 'Você não tem permissão para acessar as ofertas desse catálogo!', 'Ok', 'reload');

            setInterval(() => window.location.reload(), 15000);
        }

        if (attachments && attachments.length === 0) {
            closeErrorAlert();
        }
    }, [offers]);

    useEffect(() => {
        setFieldsDescription(fields);
    }, [fields, detail])

    const filterOffers = async (offersCatalog, usergroup) => {

        openBackdrop('Consultando seus acessos as ofertas');

        let userGroup = usergroup;
        let updateUser = false;



        await offersCatalog.map(offer => {
            if (offer.offerGroup.length > 0) return updateUser = true;
        })

        if (updateUser) {
            let idContato = {
                "idcontato": formIdContext.id
            }
            await GvpUpdateUserSCCD(idContato);

            await GvpGetUser(formIdContext.cpf, formIdContext.numberCatalog).then(res => {
                const user = res.data;
                userGroup = user.catalog.offerGroups
            });
        }

        let newArray = [];

        offersCatalog.forEach(async offer => {

            if (filterDuplicateOffers(offer, newArray)) return

            /** oferta sem grupo pode ser liberada para qualquer usuário */
            if (offer.offerGroup.length <= 0) {
                return newArray.push(offer);
            }

            /** oferta com grupo só pode ser liberada para usuários que tenham o mesmo grupo */

            await offer.offerGroup.forEach(async off => {
                if (userGroup.indexOf(off.personGroup) >= 0 && !(newArray.indexOf(offer) >= 0)) {
                    newArray.push(offer);
                }
            });
        });

        /** adiciona os campoS 'id' e 'name' em cada oferta para poder ser usado na apresentação do select */

        newArray = newArray
            .map((offer, index) => {
                return {
                    ...offer,
                    id: index,
                    name: offer.description,
                };
            });

        closeBackdrop();

        return newArray;
    }

    const filterDuplicateOffers = (offer, arrayOffers) => {
        let offerVerify = arrayOffers.find(off => off.offeringNum == offer.offeringNum);
        if (offerVerify) return true
        return false
    }

    const setAssuntoValue = (e) => {

        e.preventDefault();

        localStorage.setItem("restartTimer", true);

        openBackdrop(t('consultandoParametros'));

        closeErrorAlert();

        setFields({
            details: undefined,
            topic: undefined,
            catalog: formIdContext.numberCatalog,
            numberOffer: undefined,
            data: [],
            groupLevelOne: undefined
        });

        fields['details'] = "";

        const value = e.target.value;

        const offerSelected = arrayCatalog[value];

        if (!offerSelected || offerSelected === undefined || offerSelected === '') {
            setFields({ ...fields, topic: '' });
            setOfferDetail({});
            return closeBackdrop(false);
        }

        DetailsOfferByNumberCatalog(offerSelected.numberCatalog, offerSelected.offeringNum)
            .then(response => {
                const offerDetail = response.data;
                Array.from(document.querySelectorAll("input")).forEach(
                    input => (input.value = "")
                );

                Array.from(document.querySelectorAll("textarea")).forEach(
                    textarea => (textarea.value = "")
                );

                // detail.pmsCoffDialog.sort(function (a, b) {        
                //   if (a.sequence < b.sequence) {
                //     return -1;
                //   } else {
                //     return true;
                //   }
                // });

                if (!response.data || !offerDetail) {
                    setOfferDetail({});
                    openErrorAlert('Não foi possível recuperar detalhe da oferta');
                    return closeBackdrop();
                }

                setOfferDetail(offerDetail);
                setFields({
                    ...fields, numberOffer: offerDetail.offeringNum,
                    topic: arrayCatalog[value].description,
                    data: offerDetail.pmsCoffDialog,
                    groupLevelOne: arrayCatalog[value].groupLevelOne
                });
                closeBackdrop();
                setAssunto(value);
                
                setFormIdContextAdd(fields);
                updateTicket(offerDetail, arrayCatalog[value].description);
            })
            .catch(error => {
                console.error('error :>> ', error);
                openErrorAlert('Não foi possível recuperar detalhe da oferta');
                closeBackdrop();
            })
    }

    const updateTicket = (detail, topic) => {
        openBackdrop();
        const object = updateObject(detail, topic);


        GvpUpdateTicket(object)
            .then(response => {
                closeBackdrop();
            })
            .catch(error => {
                console.error(error);
                setGenerateGenericDialog(true, 'Ops! Ocorreu um erro...', 'Tente novamente por favor.', 'Ok', 'reload');
            });
    }

    // const updateTicketInternational = (detail, topic) => {
    //     openBackdrop();
    //     const object = updateObject(detail, topic);


    //     updateTicketZammad(object, formIdContext.idTicket)
    //         .then(response => {
    //             closeBackdrop();
    //         })
    //         .catch(error => {
    //             console.error(error);
    //             setGenerateGenericDialog(true, 'Ops! Ocorreu um erro...', 'Tente novamente por favor.', 'Ok', 'reload');
    //         });
    // }

    const updateObject = (detail, topic) => {

        let date = new Date();
        date.setMinutes(date.getMinutes() + 12);
        date = new Date(date);

        let estado = true;
        let title = 'Assunto selecionado: ' + topic;
        let description = detail.moreInfoLongDescription;

        if (description != null || localStorage.getItem("estado") != null) {
            localStorage.setItem("estado", false);
            estado = false;
        }

        let objectTicket = {
            title: process.env.REACT_APP_ENVIRONMENT == 'prd' ? topic : "homologacao 0205HOMOLOG",
            group: 'NIVEL 1::SERVICE DESK',
            customer: formIdContext.cpf,
            cep: formIdContext.cep ? formIdContext.cep : "",
            cnpj: formIdContext.cnpj,
            logradouro: formIdContext.logradouro,
            category: (`${formIdContext.description}::${topic}::${formIdContext.numberCatalog}::${detail.offeringNum}`).toUpperCase(),
            categoria: (`${formIdContext.description}::${topic}::${formIdContext.numberCatalog}::${detail.offeringNum}`).toUpperCase(),
            canal: 'FORMULARIO',
            article: {
                subject: topic,
                body: description != null ? title + '\n Orientação recebida: ' + description?.replace(/<[^>]*>|&quot;/g, '') : title,
                internal: true,
                attachments: [],
            },
            owner: 'mxintstef',
            state: statusVerify(detail),
            finalizador: 'FORMULARIO',
            aplicarplano: detail.groupLevelOne ? false : true,
            /*       new_group: detail.groupLevelOne ? detail.groupLevelOne : null,
                  gruposolucionador: detail.groupLevelOne ? detail.groupLevelOne : null, */
            session_time: date,
            idserpro: `${formIdContext.idTicket}`
        }

        if (detail.groupLevelOne) {
            objectTicket = {
                ...objectTicket,
                gruposolucionador: detail.groupLevelOne,
                new_group: detail.groupLevelOne
            }
        }

        if (formIdContext.in19) {
            objectTicket = {
                ...objectTicket,
                empresa: formIdContext.empresa ? formIdContext.empresa : 'N/A',
                endereco: formIdContext.endereco ? formIdContext.endereco : 'N/A',
                estrangeiro: true
            }
        }

        return objectTicket
    }

    // const updateObjectInternational = (detail, topic) => {

    //     let date = new Date();
    //     date.setMinutes(date.getMinutes() + 12);
    //     date = new Date(date);

    //     let estado = true;
    //     let title = 'Assunto selecionado: ' + topic;
    //     let description = detail.moreInfoLongDescription;

    //     if (description != null || localStorage.getItem("estado") != null) {
    //         localStorage.setItem("estado", false);
    //         estado = false;
    //     }

    //     let objectTicket = {
    //         title: "homologacao 0205HOMOLOG",
    //         // prod title: topic,
    //         group: 'NIVEL 1::SERVICE DESK',
    //         category: (`${formIdContext.description}::${topic}::${formIdContext.numberCatalog}::${detail.offeringNum}`).toUpperCase(),
    //         categoria: (`${formIdContext.description}::${topic}::${formIdContext.numberCatalog}::${detail.offeringNum}`).toUpperCase(),
    //         canal: 'FORMULARIO',
    //         article: {
    //             subject: topic,
    //             body: description != null ? title + '\n Orientação recebida: ' + description?.replace(/<[^>]*>|&quot;/g, '') : title,
    //             internal: true,
    //             attachments: [],
    //         },
    //         state: statusVerify(detail),
    //         aplicarplano: detail.groupLevelOne ? false : true,
    //         /*       new_group: detail.groupLevelOne ? detail.groupLevelOne : null,
    //               gruposolucionador: detail.groupLevelOne ? detail.groupLevelOne : null, */
    //         session_time: date,
    //     }

    //     return objectTicket
    // }

    const statusVerify = (offer) => {
        if (offer.status === "ACTIVE") {
            localStorage.setItem("estado", false);
            return STATUS_TICKET_GVP_ENUM.RASCUNHO;
        }
        return STATUS_TICKET_GVP_ENUM.EM_ATENDIMENTO;
    }

    const statusVerifyZammad = (offer) => {
        if (offer.status === "ACTIVE") {
            localStorage.setItem("estado", false);
            return "RASCUNHO";
        }
        return "EM ATENDIMENTO";
    }

    const generateDynamicDetails = (element, index) => {

        if (!element) return (<></>);

        if (element.type === 'multValue') return renderSelect(element);

        if (element.checkbox) return renderRadio(element);

        return renderInput(element);
    }

    const renderSelect = (element) => {

        const itemArray = element.options.map((item, index) => {
            return { ...item, id: index, name: item.description }
        });

        return (
            <SerproFormSelectV2
                name={element.required ? element.serproDisplay + ' *' : element.serproDisplay}
                title={element.tooltip ? element.tooltip : ''}
                onHidden={element.hidden}
                readOnly={element.readonly}
                value={element?.value}
                itemArray={itemArray}
                onChange={event => setSelectFieldValue(event, element)}
            />
        );
    }

    const setSelectFieldValue = (event, element) => {

        event.preventDefault();
        const { value } = event.target;
        value ? element.value = element.options[value].value : element.value = '';
        setFormIdContextAdd(fields);

    };

    const renderRadio = (element) => {
        return (
            <>
                <div className="form-group col-md-12" >
                    <label >
                        {element.required ? element.serproDisplay + ' *' : element.serproDisplay}
                    </label>
                    {
                        element.options.map(el => (
                            <div className="form-group" >
                                <div className="form-check " key={el.pmscooffDialogId}>
                                    <input className="form-check-input" type="radio" name="exampleRadios" id="exampleRadios1" value="option1" checked />
                                    <label className="form-check-label" for="exampleRadios1">
                                        Default radio
                                    </label>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </>
        );
    }

    const renderInput = (element) => {

        let count = 0

        if (element.serproDisplay?.includes('CNPJ')) count++
        if (element.serproDisplay?.includes('CPF')) count++
        if (element.serproDisplay?.includes('CEP')) count++
        if (element.serproDisplay?.includes('Telefone')) count++
        if (count == 1) {
            return (
                <div style={{ width: '100%' }}>
                    <SerproFormInputV2
                        name={element.required ? element.serproDisplay + ' *' : element.serproDisplay}
                        title={element.tooltip ? element.tooltip : ''}
                        hidden={element.hidden}
                        readOnly={element.readonly}
                        mask={element.serproDisplay?.includes('CNPJ') ? '99.999.999/9999-99' :
                            element.serproDisplay?.includes('CPF') ? '999.999.999-99' :
                                element.serproDisplay?.includes('CEP') ? '99.999-999' :
                                    element.serproDisplay?.includes('Telefone') ? '(99)99999-9999' : ''}
                        type='text'
                        onChange={event => setInputFieldValue(event, element, true)}
                    // maxlength={253}
                    />
                </div>
            );
        } else if (element.numerico) {
            return (
                <div style={{ width: '100%' }}>
                    <SerproFormInputV2
                        name={element.required ? element.serproDisplay + ' *' : element.serproDisplay}
                        title={element.tooltip ? element.tooltip : ''}
                        hidden={element.hidden}
                        readOnly={element.readonly}
                        mask={TAM_CAMPO_NUMERICO}
                        maskChar=""
                        onChange={event => setInputFieldValue(event, element)}
                    />
                </div>
            );
        } else if (element.multLineText) {
            return (
                /*Renderização dos campos extras*/
                <div style={{ width: '100%' }}>
                    <SerproFormTextArea
                        divClass="col-md-12"
                        name={element.required ? element.serproDisplay + ' *' : element.serproDisplay}
                        title={element.tooltip ? element.tooltip : ''}
                        hidden={element.hidden}
                        readOnly={element.readonly}
                        type="text"
                        onChange={event => setTextareaFieldValue(event, element)}
                        maxlength={253}
                    />
                </div>
            );
        } else {
            return (
                /*Renderização dos campos extras*/
                <div style={{ width: '100%' }}>
                    <SerproFormInputV2
                        name={element.required ? element.serproDisplay + ' *' : element.serproDisplay}
                        title={element.tooltip ? element.tooltip : ''}
                        hidden={element.hidden}
                        readOnly={element.readonly}
                        // mask={''}
                        type='text'
                        onChange={event => setInputFieldValue(event, element)}
                        maxlength={253}
                    />
                </div>
            );
        }
    }

    const setInputFieldValue = (event, element, rmvMask) => {
        event.preventDefault();

        closeErrorAlert();

        let { value } = event.target;

        if (value.length > 252) {
            openErrorAlert('Somente é possível inserir informações de, no máximo, 254 caracteres. Por gentileza, inclua o restante no campo “Detalhes da demanda” ou como arquivo anexo.');
            value = value.substring(0, 253);
        }

        value = value.trim();

        if (rmvMask) {
            value = REMOVE_MASK_CPF(value);
            value = REMOVE_MASK_CNPJ(value);
            value = REMOVE_MASK_TELEFONE(value);
        }

        element.value = value;
        setFormIdContextAdd(fields);

    }

    const setTextareaFieldValue = (event, element) => {
        event.preventDefault();
        const { value } = event.target;

        if (!value) return;

        element.value = value;
        setFields({ ...fields, data: arrayCatalog });
        setFormIdContextAdd(fields);
    };

    const setDetalhesDemandaValue = (e) => {

        e.preventDefault();

        const value = e.target.value;

        setQtdCaracteresRestantes(1500 - value.length);
        setDetail(value);
        setFields({ ...fields, details: value });
        setFormIdContextAdd(fields);
        setFieldsDescription(fields);

    }

    const handleFiles = async (event) => {
        openBackdrop('')
        closeErrorAlert();

        if (attachments.length === 15) {
            openErrorAlert(t('limite15Arquivos'))
            return closeBackdrop();
        }
        else {
            closeErrorAlert();
            await handleFilesRead(event);
        }
    }

    const handleFilesRead = async (event) => {
        const field = event.target.id;
        const file = event.target.files && event.target.files[0];

        if (field && file) {
            const base64 = await convertBase64(file);
            const type = base64.split(',')[0].split(';')[0].split(':')[1]

            if (file.size + checkSetFilesSize(attachments) > 41943040) {
                openErrorAlert(t('limite40mb'));
                return closeBackdrop();
            }
            else {
                let isvalid = false;

                for (var i = 0; i < fileTypes.length; i++) {
                    if (file.name.toLowerCase().endsWith(fileTypes[i]))
                        isvalid = true;
                }

                if (isvalid) {
                    setAttachments((old) => [file, ...old]);

                    imagem.push({
                        filename: file.name,
                        data: base64.split(',')[1],
                        'mimetype': type,
                        'contenttype': type
                    })

                    event.target.value = null;
                    closeBackdrop();
                    callbackImage(imagem);
                }
                else {
                    openErrorAlert(t('limite40mb'));
                    setAttachments([]);
                    return closeBackdrop();
                }
            }
        }
    }

    const convertBase64 = (file) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader()
            fileReader.readAsDataURL(file)
            fileReader.onload = () => {
                resolve(fileReader.result)
            }
            fileReader.onerror = (error) => {
                reject(error)
            }
        })
    }

    const checkSetFilesSize = (_files) => {
        let tSize = 0
        for (let i = 0; i <= _files.length - 1; i++) {
            tSize += _files[i].size
        }
        return tSize
    }

    const onRemoveFile = (index) => (e) => {
        e.preventDefault()
        setAttachments((old) => old.filter((f, i) => i !== index))
        imagem.splice(index, 1);

        if (checkSetFilesSize(attachments) < 41943040)
            closeErrorAlert();

        if (attachments.length <= 15)
            closeErrorAlert();

        callbackImage(imagem)
    };

    const openErrorAlert = (message) => {
        setAlertMessage({ message: message, show: true })
    }

    const closeErrorAlert = () => {
        setAlertMessage({ message: '', show: false })
    }

    const openBackdrop = (message) => {
        setControllerBackdrop({ message: message, show: true })
    }

    const closeBackdrop = () => {
        setControllerBackdrop({ message: '', show: false })
    }

    return (

        <div style={{ width: '100%' }}>
            <div>
                <SerproFormSelectV2
                    onChange={(e) => setAssuntoValue(e)}
                    itemArray={arrayCatalog}
                />
            </div>
            {
                offerDetail &&
                offerDetail.moreInfoLongDescription &&
                (
                    <Stack sx={{ width: '100%', }} spacing={2}>
                        <Alert style={{ backgroundColor: '#c6dcff', margin: '15px 0px', }} severity="info">
                            <AlertTitle>Info</AlertTitle>
                            <span dangerouslySetInnerHTML={{ __html: offerDetail.moreInfoLongDescription }} />
                        </Alert>
                    </Stack>
                )
            }

            {(offerDetail?.pmsCoffDialog?.length == 1 && offerDetail?.pmsCoffDialog[0]?.type !== 'multValue' && !offerDetail?.pmsCoffDialog[0]?.checkbox) &&
                <div style={{ color: '#5F5F7D', marginBottom: '10px', fontStyle: 'italic', textAlign: 'justify' }}>
                    <span>
                        {t('max254CaracteresSingular')}
                    </span>
                </div>
            }

            {(offerDetail?.pmsCoffDialog?.length > 1 && offerDetail?.pmsCoffDialog[0]?.type !== 'multValue' && !offerDetail?.pmsCoffDialog[0]?.checkbox) &&
                <div style={{ color: '#5F5F7D', marginBottom: '10px', fontStyle: 'italic', textAlign: 'justify' }}>
                    <span>
                        {t('max254CaracteresPlural')}
                    </span>
                </div>
            }

            {offerDetail?.pmsCoffDialog?.length > 0 &&

                (
                    offerDetail.pmsCoffDialog.map((element, index) =>
                    (
                        <>
                            <div className="form-row" key={index}>
                                {generateDynamicDetails(element, index)}
                            </div>
                        </>
                    ))
                )
            }

            <div style={{ marginTop: '20px' }}>
                <SerproFormTextAreaV2
                    name={t('detalhesDemanda').toUpperCase() + ' *'}
                    type="text"
                    onChange={(e) => setDetalhesDemandaValue(e)}
                    maxLength={1500}
                    styleDiv={{ display: 'flex', flexDirection: 'column', width: '100%' }}
                    inputStyle={{ height: '120px', padding: '10px' }}
                />
                <AlertQtdeCaracteresRestantes>
                    {t('qtdCaracteresRestantes', { qtd: qtdCaracteresRestantes })}
                </AlertQtdeCaracteresRestantes>
            </div>

            <form style={{ marginTop: '10px' }}>
                <div>
                    <input
                        style={{ display: 'none' }}
                        id="contained-button-file"
                        type="file"
                        onChange={(e) => handleFiles(e)}
                        accept=".pdf,.txt,.xml,.csv,.gif,.jpg,.jpeg,.png,.bmp,.odt,.ods,.odp,.odb,.odd,
                    .doc,.xls,.ppt,.docx,.xlsx,.pptx,.tar,.gz,.tar,.xz,.zip"
                    />
                    <label htmlFor="contained-button-file">
                        <Button
                            style={{ borderRadius: '0', padding: '5px 20px' }}
                            variant="outlined"
                            color="default"
                            component="span"
                            size="small"
                            disableFocusRipple
                            startIcon={<CloudUploadIcon />}
                        >
                            {t('anexarAquivo')}
                            {attachments && attachments.length > 0 && (
                                <>
                                    <b>&emsp;({attachments.length})</b>
                                </>
                            )}
                        </Button>
                        <p style={{ marginTop: '10px', color: '#5F5F7D' }}>{t('arquivosPermitidos') + "pdf, txt, xml, csv, gif, jpg, jpeg, png, bmp, odt, ods, odp, odb, odd, doc, xls, ppt, docx, xlsx, pptx, tar.gz, tar.xz, zip."}</p>
                    </label>
                </div>
            </form>

            <div style={{ width: '100%', marginTop: '20px' }}>
                {attachments &&
                    attachments.map((f, i) => (
                        <div className="col-md-12" key={i} style={{ width: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: '4px 0', padding: '5px 20px', backgroundColor: '#003DA5', color: '#FFF' }}>
                            <p style={{ width: '50%', margin: '0', fontWeight: '600', whiteSpace: 'normal' }}>{f.name.length > 65 ? `${f.name.substring(0, 65)}...` : f.name}</p>
                            <p style={{ width: '30%', margin: '0', fontWeight: '600' }}>{(f.size / 1048576).toFixed(2)}MB</p>
                            <Tooltip title="Excluir" placement="left">
                                <Button style={{ color: '#FFF' }} variant="text" aria-label="delete" size="small" disableRipple onClick={onRemoveFile(i)}>
                                    <DeleteIcon fontSize="small" style={{ color: '#FFF' }} />
                                </Button>
                            </Tooltip>
                        </div>
                    ))}
            </div>


        </div >

    )
}

export default DescricaoV2;