export const Lang = {
  pt_br: {
    nomeDoSolicitante: 'Nome do Solicitante',
    cpf: 'CPF',
    cep: 'CEP',
    enderecoLogradouro: 'Endereço / Logradouro',
    numero: 'Número',
    bairro: 'Bairro',
    complemento: 'Complemento (Sala/Andar)',
    ddd: 'DDD',
    email: 'E-mail',
    municipio: 'Município de Atendimento',
    ramal: 'Ramal',
    telefone: 'Telefone',
    empresa: 'Empresa',
    uf: 'UF',
    tituloForm: 'CENTRAL DE SERVIÇOS SERPRO',
    sessaoInativaTitulo: 'Sessão Inativa',
    sessaoInativaTexto: 'Sessão inativa por mais de 11 minutos. Sua sessão expirará em 1 minuto(s) caso não execute nenhuma ação o sistema.',
    sessaoInativaBotao: 'Ciente',
    sessaoExpiradaTitulo: 'Sessão Expirada',
    sessaoExpiradaTexto: 'Sua sessão expirou.',
    sessaoExpiradaBotao: 'Recarregar',
    ip: 'Endereço de IP:',
    tempoSessao: 'Tempo de sessão restante:',
    caminhoTextoUm: 'Página inicial > Central de Serviços > ',
    caminhoTextoDois: ' > Formulário',
    centralBotao: 'Central de Ajuda',
    tituloSessao: 'Registro de Solicitação de Serviço',
    descricaoSessao: 'Utilize o formulário abaixo para solicitar um atendimento',
    identificacao: 'Identificação',
    descricao: 'Descrição',
    preenchimento: '* Campos de preenchimento obrigatório.',
    usuarioNaoCadastrado: 'Usuário não cadastrado, preencha o formulário.',
    limparFormBotao: 'LIMPAR FORMULÁRIO',
    cancelarBotao: 'CANCELAR',
    cadastrarBotao: 'CADASTRAR',
    avancarBotao: 'AVANÇAR',
    enviarBotao: 'ENVIAR',
    consultandoParametros: 'Consultando parâmetros das ofertas',
    max254CaracteresSingular: 'ATENÇÃO! O campo a seguir aceita, no máximo, 254 caracteres. Caso sua informação exceda este limite, por gentileza, inclua o restante no campo “Detalhes da demanda” ou como arquivo anexo.',
    max254CaracteresPlural: 'ATENÇÃO! Os campos a seguir aceitam, no máximo, 254 caracteres. Caso sua informação exceda este limite, por gentileza, inclua o restante no campo “Detalhes da demanda” ou como arquivo anexo.',
    detalhesDemanda: 'Detalhes da Demanda',
    qtdCaracteresRestantesInicio: 'Restam',
    qtdCaracteresRestantesFim: 'caracteres',
    anexarAquivo: 'Anexar Arquivo',
    limite15Arquivos: 'Somente podem ser enviados até 15 arquivos',
    limite40mb: 'A soma total dos arquivos não pode ser maior que 40MB',
  },
  en_us: {
    nomeDoSolicitante: 'Requester Name',
    cpf: 'CPF',
    cep: 'Zip Code',
    enderecoLogradouro: 'Address',
    numero: 'number',
    complemento: 'Complement (Room/Floor)',
    bairro: 'District',
    ddd: 'DDD',
    email: 'Email',
    municipio: 'Service City',
    ramal: 'Branch Line',
    telefone: 'Phone Number',
    empresa: 'Company',
    uf: 'State',
    tituloForm: 'SERPRO SERVICE CENTER',
    sessaoInativaTitulo: 'Session Inactive',
    sessaoInativaTexto: 'Session inactive for more than 11 minutes. Your session will expire in 1 minute(s) if you do not perform any action on the system.',
    sessaoInativaBotao: 'Aware',
    sessaoExpiradaTitulo: 'Session expired',
    sessaoExpiradaTexto: 'Your session has expired.',
    sessaoExpiradaBotao: 'Reload',
    ip: 'IP address:',
    tempoSessao: 'Remaining session time:',
    caminhoTextoUm: 'Home page > Service Center >',
    caminhoTextoDois: ' > Form',
    centralBotao: 'Help Center',
    tituloSessao: 'Service Request Registration',
    descricaoSessao: 'Use the form below to request service',
    identificacao: 'Identification',
    descricao: 'Description',
    preenchimento: '* Required fields.',
    usuarioNaoCadastrado: 'Unregistered user, fill out the form.',
    limparFormBotao: 'CLEAR FORM',
    cancelarBotao: 'CANCEL',
    cadastrarBotao: 'REGISTER',
    avancarBotao: 'ADVANCE',
    enviarBotao: 'TO SEND',
    consultandoParametros: 'Querying offer parameters',
    max254CaracteresSingular: 'ATTENTION! The following field accepts a maximum of 254 characters. If your information exceeds this limit, please include the remainder in the “Demand Details” field or as an attached file.',
    max254CaracteresPlural: 'ATTENTION! The following fields accept a maximum of 254 characters. If your information exceeds this limit, please include the remainder in the “Demand Details” field or as an attached file.',
    detalhesDemanda: 'Demand Details',
    qtdCaracteresRestantesInicio: 'Remain',
    qtdCaracteresRestantesFim: 'characters',
    anexarAquivo: 'Attach file',
    limite15Arquivos: 'Only up to 15 files can be sent',
    limite40mb: 'The total sum of files cannot be greater than 40MB',
  },
};
