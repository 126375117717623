import { useState, useEffect } from 'react';
import SerproFormInputV2 from '../../../components/serproforminput/SerproFormInputV2';
import { useFormId } from '../../form/FormIdContext';
import {
    REMOVE_MASK_TELEFONE,
    REMOVE_MASK_CPF,
    VALIDAR_CPF,
    REMOVE_MASK_CEP,
    REMOVE_MASK_CNPJ,
    ADICIONA_MASK_CPF
} from '../../../utils/ValidationForm';
import { GvpGetUser } from '../../../service/GlobalService';
import { MOUNT_CEP } from '../../../service/external/cep';
import Button from '@mui/material/Button';
import OtherUserV2 from '../../../components/OtherUserModal/OtherUserModalV2';
import { InfoRelator } from '../../form/FormStyle';

function IdentificacaoV2(props) {

    const { setAlertMessage, setControllerBackdrop, setGenerateGenericDialog, setFormValidButton } = props;
    const { setFormIdContext, formIdContext, setFormIdContextAdd } = useFormId();

    const [fieldDisabled, setFieldDisabled] = useState(true);
    const [fieldDisabledCep, setFieldDisabledCep] = useState(true);
    const [getCpfVal, setGetCpfVal] = useState(true);
    const [showFields, setShowFields] = useState(false);
    const [isAtendimentoOutroUsuario, setIsAtendimentoOutroUsuario] = useState(false);
    const [viewOtherUserOption, setViewOtherUserOption] = useState(false);
    const [openDialogOtherUser, setOpenDialogOtherUser] = useState(false);
    const [consultUserSa, setConsultUserSa] = useState(true);

    const [cnpj, setCnpj] = useState('');
    const [cpf, setCpf] = useState('');
    const [name, setName] = useState('');
    const [cep, setCep] = useState('');
    const [logradouro, setLogradouro] = useState('');
    const [endNumero, setEndNumero] = useState('');
    const [complemento, setComplemento] = useState('');
    const [bairro, setBairro] = useState('');
    const [cidade, setCidade] = useState('');
    const [uf, setUf] = useState('');
    const [email, setEmail] = useState('');
    const [ddd, setDdd] = useState('');
    const [telefone, setTelefone] = useState('');
    const [ramal, setRamal] = useState('');
    const [dadosRelator, setDadosRelator] = useState({});

    const [nameMask, setNameMask] = useState('');
    const [nameMaskSolicitante, setNameMaskSolicitante] = useState('');

    const [obj,] = useState({})

    useEffect(() => {

        if (formIdContext.name) {
            setCpf(formIdContext.cpf);
            setName(formIdContext.name);
            setFormValidButton(false);

            if (formIdContext.relatorCpf) {
                setIsAtendimentoOutroUsuario(true);
                dadosRelator.cpf = formIdContext.relatorCpf;
                dadosRelator.name = formIdContext.relatorName;
            }
        }


        if (formIdContext.saUser && consultUserSa) {

            if (formIdContext.cnpjRequiredFormWeb) {
                if (formIdContext.saUserCnpj) {
                    setCnpjValue(formIdContext.saUserCnpj);
                    setCpfValue(formIdContext.saUser);
                    getCpf(formIdContext.saUser);
                    setConsultUserSa(false);
                } else {
                    setGenerateGenericDialog(true, 'Atenção', 'Para acessar este formulário é necessário ter um CNPJ vinculado ao seu usuário no SCCD. Entre em contato com seu administrador.', 'Ok', 'redirect');
                }
            } else {
                setCpfValue(formIdContext.saUser);
                getCpf(formIdContext.saUser);
                setConsultUserSa(false);
            }
        }

    });


    const setUserIdValue = (e) => {
        obj.id = e
        setFormIdContextAdd(obj);
    }

    const getCpf = async (value) => {
        setGetCpfVal(false);
        setShowFields(false);
        setDadosRelator({});
        setIsAtendimentoOutroUsuario(false);
        closeErrorAlert();

        let cpfConst = REMOVE_MASK_CPF(value)
        setCpfValue(cpfConst);
        setHasUserToEditValue(false);

        if (cpfConst.length !== 11) {
            return
        }

        if (!VALIDAR_CPF(cpfConst)) {
            openErrorAlert('O CPF informado é inválido');
            return;
        }

        openBackdrop('Aguarde um instante, estamos verificando seu cadastro.');

        GvpGetUser(cpfConst, formIdContext.numberCatalog).then((resp) => {
            const data = resp.data;

            if (data?.id) {
                const offerGroups = data.catalog.offerGroups;
                obj.groups = offerGroups?.join('::');
                obj.newUser = false;

                setCpfValue(cpfConst);
                setUserIdValue(data?.id);
                setNameValue(`${data.nome} ${data.sobrenome}`);
                setLastNameValue(data.sobrenome);
                setEmailValue(data.email);
                setLogradouroValue(data.enderec?.addresscomplete);
                setEndNumeroValue(data.enderec?.number);
                setNeighborhoodValue(data.enderec?.neighborhood);
                setComplementoValue('')
                setUfValue(data.enderec?.state);
                setMunicipioValue(data.enderec?.city);
                setTelefoneValue(data.phone?.number);
                setDddValue(data.phone?.ddd);
                setCep(data.enderec?.zipcode);
                setHasUserToEditValue(true);
                obj.hasUserToEdit = true;

                setFieldDisabled(true);
                setFormIdContextAdd(obj);
                setViewOtherUserOption(true);

                formIdContext.in21 ? setShowFields(true) : setShowFields(false);

                closeBackdrop();
            } else {
                obj.cpf = '';

                if (data === 'semcadastro') {
                    obj.newUser = true;
                    setShowFields(true);

                    setCpfValue(cpfConst);
                    setUserIdValue('');
                    setNameValue('', '');
                    setEmailValue('');
                    setLogradouroValue('');
                    setEndNumeroValue('');
                    setNeighborhoodValue('');
                    setComplementoValue('');
                    setUfValue('');
                    setMunicipioValue('');
                    setTelefoneValue('');
                    setDddValue('');
                    setCepValue('');
                    setFieldDisabled(false);
                    setFieldDisabledCep(false);

                    setFormIdContextAdd(obj);
                    openErrorAlert('Usuário não cadastrado, preencha o formulário.');

                    return closeBackdrop();
                }
            }
        }).catch(() => {

            setCpfValue('');
            setUserIdValue('');
            setNameValue('', '');
            setEmailValue('');
            setLogradouroValue('');
            setEndNumeroValue('');
            setNeighborhoodValue('');
            setComplementoValue('');
            setUfValue('');
            setMunicipioValue('');
            setTelefoneValue('');
            setDddValue('');
            setCepValue('');
            setFieldDisabled(true);

            openErrorAlert('Ocorreu um erro ao buscar os dados para o CPF informado, por favor tente novamente mais tarde');

            setFormIdContextAdd(obj);
            closeBackdrop();
        })
    }

    const setCpfValue = (e) => {
        setCpf(e);
        obj.cpf = e;
        setFormIdContextAdd(obj);
    }

    const setNameValue = (name) => {
        setName(name);
        obj.name = name;
        setFormIdContextAdd(obj);
    }

    const setLastNameValue = (e) => {
        obj.lastName = e;
        setFormIdContextAdd(obj);
    }

    const setCepValue = async (value) => {
        if (value != null) {
            setCep(value);
            let cep = "" + value;
            obj.cep = REMOVE_MASK_CEP(value);
            cep = cep.replace(/[\s_.-]*/gim, '');

            if (cep.length !== 8) return

            if (cep.length === 8) {
                openBackdrop('Aguarde um instante, estamos consultando o endereço.');

                MOUNT_CEP(cep)
                    .then(response => response.data)
                    .then(response => {
                        if (response.erro) {
                            clearAddressFields();
                            openErrorAlert('CEP inválido');
                        }
                        closeErrorAlert();

                        setLogradouroValue(response.logradouro);
                        setUfValue(response.uf);
                        setNeighborhoodValue(response.bairro);
                        setDddValue(response.ddd);
                        setMunicipioValue(response.localidade);

                        setFieldDisabledCep(true);
                        closeBackdrop();
                    })
                    .catch(error => {
                        console.error(error);
                        openErrorAlert('Ocorreu um erro ao buscar os dados para o CEP informado, por favor tente novamente mais tarde');
                        closeBackdrop();
                    });
            } else {
                clearAddressFields();
            }
        }
        setFormIdContextAdd(obj);
    }

    const clearAddressFields = () => {
        setLogradouroValue('')
        setComplementoValue('')
        setUfValue('')
        setNeighborhoodValue('')
        setDddValue('')
        setMunicipioValue('')
        closeBackdrop()
    }

    const setLogradouroValue = (e) => {
        setLogradouro(e)
        obj.logradouro = e;
        setFormIdContextAdd(obj);
    }

    const setEndNumeroValue = (e) => {
        setEndNumero(e)
        obj.endNumero = e;
        setFormIdContextAdd(obj);
    }

    const setComplementoValue = (e) => {
        setComplemento(e)
        obj.complemento = e;
        setFormIdContextAdd(obj);
    }

    const setNeighborhoodValue = (e) => {
        setBairro(e)
        obj.bairro = e;
        setFormIdContextAdd(obj);
    }

    const setUfValue = (e) => {
        setUf(e)
        obj.uf = e;
        setFormIdContextAdd(obj);
    }

    const setMunicipioValue = (e) => {
        setCidade(e)
        obj.cidade = e;
        setFormIdContextAdd(obj);
    }

    const setEmailValue = (email) => {
        setEmail(email)
        obj.email = email;
        setFormIdContextAdd(obj);
    }

    const setDddValue = (e) => {
        setDdd(e)
        obj.ddd = e;
        setFormIdContextAdd(obj);
    }

    const setTelefoneValue = (e) => {
        if (e != null) {
            setTelefone(e);
            obj.telefone = REMOVE_MASK_TELEFONE(e);
            setFormIdContextAdd(obj);
        }
    }

    const setRamalValue = (e) => {
        setRamal(e)
        obj.ramal = e;
        setFormIdContextAdd(obj);
    }

    const setHasUserToEditValue = (e) => {
        obj.hasUserToEdit = e;
        setFormIdContextAdd(obj);
    }

    const setCnpjValue = (e) => {
        setCnpj(e);
        obj.cnpj = e;
        setFormIdContextAdd(obj);
    }

    const openErrorAlert = (message) => {
        setAlertMessage({ message: message, show: true })
    }

    const closeErrorAlert = () => {
        setAlertMessage({ message: '', show: false })
    }

    const openBackdrop = (message) => {
        setControllerBackdrop({ message: message, show: true })
    }

    const closeBackdrop = () => {
        setControllerBackdrop({ message: '', show: false })
    }

    const handleCloseDialog = (data) => {
        setOpenDialogOtherUser(false);
        setDadosRelator({});

        if (data.isOutroUsuario) {
            setDadosRelator({
                cpf: ADICIONA_MASK_CPF(cpf),
                name,
                email,
                cep,
                logradouro,
                endNumero,
                complemento,
                bairro,
                cidade,
                uf,
                ddd,
                telefone,
                ramal
            });

            obj.relatorCpf = REMOVE_MASK_CPF(cpf);
            obj.relatorName = name;

            setCpfValue(data.cpfOutroUsuario);
            setNameValue(data.nomeDoSolicitanteOutroUsuario);
            setEmail(data.emailOutroUsuario);
            setCep(data.cepOutroUsuario);
            setLogradouro(data.logradouroOutroUsuario);
            setEndNumero(data.numeroLogradouroOutroUsuario);
            setComplemento(data.complementoOutroUsuario);
            setBairro(data.bairroOutroUsuario);
            setCidade(data.cidadeOutroUsuario);
            setUf(data.ufOutroUsuario);
            setDdd(data.dddOutroUsuario);
            setTelefone(data.telefoneOutroUsuario);
            setRamal(data.ramalOutroUsuario);

            obj.affectedBy = data.cpfOutroUsuario;

            setNameMaskSolicitante(nameMask);

            setFormIdContextAdd(obj);
        }

        setIsAtendimentoOutroUsuario(data.isOutroUsuario);
    };

    return (

        <div style={{ width: '100%' }}>
            {isAtendimentoOutroUsuario && (
                <div style={{ marginBottom: '40px' }}>
                    <b>Relatado por: </b>
                    <span>{dadosRelator.cpf} - </span> {nameMaskSolicitante ? nameMaskSolicitante : dadosRelator.name}
                </div>

            )}
            <div style={{ width: '100%', display: 'flex' }}>
                <SerproFormInputV2
                    divClass=""
                    styleDiv={{ width: '20%' }}
                    name="CPF *"
                    mask="999.999.999-99"
                    error
                    value={cpf}
                    disabled={formIdContext.setCpfDisable || formIdContext.saUser}
                    onChange={(e) => getCpf(e.target.value)}
                />

                <SerproFormInputV2
                    divClass="ml-3"
                    styleDiv={{ width: '80%' }}
                    name="NOME DO SOLICITANTE *"
                    value={name}
                    disabled={fieldDisabled}
                    onChange={(e) => setNameValue(e.target.value)}
                    error
                    maxlength={100}
                />
            </div>
            {formIdContext.cnpjFormWeb && (
                <SerproFormInputV2
                    divClass=""
                    styleDiv={{ width: '20%' }}
                    name={formIdContext.cnpjRequiredFormWeb ? 'CNPJ *' : 'CNPJ'}
                    mask="99.999.999/9999-99"
                    value={cnpj}
                    onChange={e => setCnpjValue(REMOVE_MASK_CNPJ(e.target.value))}
                    disabled={formIdContext.setCnpjDisabled || formIdContext.saUserCnpj}
                />
            )}
            {showFields && (
                <>
                    <div style={{ width: '100%', display: 'flex', marginTop: '10px' }}>
                        <SerproFormInputV2
                            divClass=""
                            styleDiv={{ width: '15%' }}
                            name="CEP"
                            mask="99.999-999"
                            error
                            value={cep}
                            disabled={fieldDisabled}
                            onChange={(e) => setCepValue(e.target.value)}
                        />
                        <SerproFormInputV2
                            divClass="ml-3"
                            styleDiv={{ width: '45%' }}
                            name="ENDEREÇO"
                            disabled={fieldDisabledCep}
                            value={logradouro}
                            maxLength={55}
                            onChange={(e) => setLogradouroValue(e.target.value)}
                        />
                        <SerproFormInputV2
                            divClass="ml-3"
                            styleDiv={{ width: '10%' }}
                            name="NÚMERO"
                            mask="9999999"
                            value={endNumero}
                            disabled={fieldDisabled}
                            onChange={(e) => setEndNumeroValue(e.target.value)}
                        />
                        <SerproFormInputV2
                            divClass="ml-3"
                            styleDiv={{ width: '30%' }}
                            name="COMPLEMENTO"
                            maxLength={10}
                            value={complemento}
                            disabled={fieldDisabled}
                            onChange={(e) => setComplementoValue(e.target.value)}
                            exemple={'Ex: Sala / Andar / etc.'}
                        />
                    </div>
                    <div style={{ width: '100%', display: 'flex', marginTop: '10px' }}>
                        <SerproFormInputV2
                            divClass=""
                            styleDiv={{ width: '55%' }}
                            name="BAIRRO"
                            value={bairro}
                            disabled={fieldDisabledCep}
                            onChange={(e) => setNeighborhoodValue(e.target.value)}
                        />
                        <SerproFormInputV2
                            divClass="ml-3"
                            styleDiv={{ width: '5%' }}
                            name="UF"
                            mask="aa"
                            type="select"
                            value={uf}
                            disabled={fieldDisabledCep}
                            onChange={(e) => setUfValue(e.target.value)}
                        />
                        <SerproFormInputV2
                            divClass="ml-3"
                            styleDiv={{ width: '40%' }}
                            name="MUNICÍPIO"
                            value={cidade}
                            disabled={fieldDisabledCep}
                            onChange={(e) => setMunicipioValue(e.target.value)}
                        />
                    </div>
                    <div style={{ width: '100%', display: 'flex', marginTop: '10px' }}>
                        <SerproFormInputV2
                            divClass=""
                            styleDiv={{ width: '65%' }}
                            name="EMAIL *"
                            value={email}
                            disabled={!obj.newUser}
                            onChange={(e) => setEmailValue(e.target.value)}
                            maxlength={100}
                        />
                        <SerproFormInputV2
                            divClass="ml-3"
                            styleDiv={{ width: '5%' }}
                            name="DDD *"
                            mask="99"
                            value={ddd}
                            disabled={fieldDisabled}
                            onChange={(e) => setDddValue(e.target.value)}
                        />
                        <SerproFormInputV2
                            divClass="ml-3"
                            styleDiv={{ width: '15%' }}
                            name="TELEFONE *"
                            mask="99999-9999"
                            value={telefone}
                            disabled={fieldDisabled}
                            onChange={(e) => setTelefoneValue(e.target.value)}
                        />
                        <SerproFormInputV2
                            divClass="ml-3"
                            styleDiv={{ width: '15%' }}
                            name="RAMAL"
                            mask="99999999999"
                            value={ramal}
                            disabled={fieldDisabled}
                            onChange={(e) => setRamalValue(e.target.value)}
                        />
                    </div>
                </>
            )}
            {viewOtherUserOption &&
                <div style={{ width: '100%', marginTop: '20px' }}>
                    <p style={{ marginBottom: '10px', color: '#5F5F7D' }}>Clique no botão abaixo caso o atendimento seja para outro usuário:</p>
                    <Button style={{ margin: '0', padding: '5px 20px', borderRadius: '0px', backgroundColor: '#003DA5', color: '#FFF' }} disableRipple onClick={() => { setOpenDialogOtherUser(true) }}>ATENDIMENTO PARA OUTRO USUÁRIO</Button>
                    <OtherUserV2 openDialog={openDialogOtherUser} onCloseDialog={handleCloseDialog}></OtherUserV2>
                </div>
            }

        </div>

    )
}

export default IdentificacaoV2;