/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Select
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { AlertError, ButtonContainer } from '../../screens/form/FormStyle';
import SerproFormInput from '../serproforminput/SerproFormInput';
import { GvpGetUser, GvpCreateUser } from '../../service/GlobalService';
import { MOUNT_CEP } from './../../service/external/cep';
import { Lang } from '../../utils/Languages';
import MenuItem from '@material-ui/core/MenuItem';
import { FormIdContext } from '../../screens/form/FormIdContext';
import { identificationTabValidations } from '../../utils/ValidationRules';

import {
  REMOVE_MASK_CEP,
  REMOVE_MASK_CPF,
  VALIDAR_CPF,
  VALIDAR_EMAIL
} from '../../utils/ValidationForm';

export default function OtherUser(props) {

  // const arrayLanguageOptions = [
  //   { id: 0, sigla: 'pt_br', name: 'Português (Brasil)' },
  //   { id: 1, sigla: 'en_us', name: 'ingês (US)' },
  //   { id: 2, sigla: 'es', name: 'Espanhol' },
  // ];

  const { openDialog, onCloseDialog, validaForm } = props;
  const classes = useStyles();

  /** Controles de tela */
  const [backdrop, setBabackdrop] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [newUser, setNewUser] = useState(false);
  const [languageSelected, setLanguageSelected] = useState('br');
  const [, setValidFormFields] = useState(false);

  /** Constrole de formulário */
  const [cep, setCep] = useState('');
  const [cidade, setCidade] = useState('');
  const [complemento, setComplemento] = useState('');
  const [cpf, setCpf] = useState('');
  const [ddd, setDdd] = useState('');
  const [email, setEmail] = useState('');
  const [logradouro, setLogradouro] = useState('');
  const [bairro, setBairro] = useState('');
  const [nomeDoSolicitante, setNomeDoSolicitante] = useState('');
  const [endNumero, setNumeroLogradouro] = useState('');
  const [ramal, setRamal] = useState('');
  const [telefone, setTelefone] = useState('');
  const [uf, setUf] = useState('');
  const [, setUserId] = useState('');
  const formIdContext = useContext(FormIdContext);
  const [nameMask, setNameMask] = useState('');
  const [valForm, setValidaForm] = useState(validaForm);
  const [dataFieldsIdentification, setFieldsIdentification] = useState({});

  useEffect(() => {
    setLanguageSelected('br');
    formIdContext.in21 ? setValidaForm(true) : setValidaForm(false);
  }, []);

  const [obj,] = useState({
    id: '',
    cpf: '',
    name: '',
    sobrenome: '',
    email: '',
    cep: '',
    logradouro: '',
    endNumero: '',
    complemento: '',
    bairro: '',
    cidade: '',
    uf: '',
    ddd: '',
    telefone: '',
    ramal: '',
    alertUser: true,
    hasUserToEdit: false,
    newUser: false,
    groups: '',
    affectedBy: ''
  })

  const initialFieldsDataUser = () => {
    setCpf('')
    setDdd('')
    setEmail('')
    setNomeDoSolicitante('')
    setNameMask('')
    setRamal('')
    setTelefone('')
    setUserId('')
  }

  const initialFieldsDataUserLessCPF = () => {
    setDdd('')
    setEmail('')
    setNomeDoSolicitante('')
    setNameMask('')
    setRamal('')
    setTelefone('')
    setUserId('')
  }

  const initialFieldsLocaleUser = () => {
    setCep('')
    setCidade('')
    setComplemento('')
    setLogradouro('')
    setBairro('')
    setNumeroLogradouro('')
    setUf('')
  }

  const initialViewControls = () => {
    setErrorMessage('')
    setBabackdrop(false)
    setNewUser(false)
  }

  // function validateFormByField() {
  //   if(newUser) {
  //     if (obj.length === 0 || obj[0] === {}) return true
  //     const keys = Object.keys(obj)
  //     const isValidForm = keys.every(value => obj[value])
  //     return isValidForm
  //   }

  //   return true
  // }

  const handleClose = (e, isValid = false) => {
    initialFieldsDataUser();
    initialFieldsLocaleUser();
    initialViewControls();
    setBabackdrop(false);
    setValidaForm(false);
    setNewUser(false);
    return onCloseDialog({
      bairroOutroUsuario: bairro,
      cepOutroUsuario: cep,
      cidadeOutroUsuario: cidade,
      complementoOutroUsuario: complemento,
      cpfOutroUsuario: cpf,
      dddOutroUsuario: ddd,
      emailOutroUsuario: email,
      logradouroOutroUsuario: logradouro,
      nomeDoSolicitanteOutroUsuario: nameMask ? nameMask : nomeDoSolicitante,
      numeroLogradouroOutroUsuario: endNumero,
      ramalOutroUsuario: ramal,
      telefoneOutroUsuario: telefone,
      ufOutroUsuario: uf,
      isOutroUsuario: isValid,
    });
  };

  function filterAdressNumber(input) {
    if (!input) return 0  
    let array = input.split('')
    let filtered = array.filter(el => el > -1);
    return parseInt(filtered.join(''))
  }

  function filterName(name, typeName) {
    let arrayName = name.split('');
    let result = null;
    let i = 0
    arrayName.map((c) => {
      if (c[i] !== " " && result === null) {
        let tempArray = arrayName.join('');
        result = tempArray.slice(arrayName.indexOf(c), tempArray.length);
      }
      i++
    })

    if (typeName === 'fistName') {
      return result.split(" ")[0]
    }

    if (typeName === 'lastName') {
      return result.slice(result.indexOf(" ") + 1, result.length)
    }

    return null
  }

  const generateRequisitionObjectForNewUser = () => {
    return {
      document: dataFieldsIdentification.cpf,
      fullName: dataFieldsIdentification.name,
      email: dataFieldsIdentification.email,
      address: {
        number: filterAdressNumber(dataFieldsIdentification.endNumero),
        neighborhood: dataFieldsIdentification.bairro,
        state: dataFieldsIdentification.uf,
        city: dataFieldsIdentification.cidade,
        zipCode: dataFieldsIdentification.cep,
        addressComplete: `${dataFieldsIdentification.logradouro}`,
        complemento: dataFieldsIdentification.complemento
      },
      phone: {
        ddd: dataFieldsIdentification.ddd,
        number: dataFieldsIdentification.telefone
      }
    };
  }


  const handleSend = async () => {
    setBabackdrop(true);
    let isValidEmail = true;
    const formIsValidate = identificationTabValidations(dataFieldsIdentification, true, '');

    if (newUser) {

      if (!formIsValidate.passed) {
        setBabackdrop(false);
        return setErrorMessage(formIsValidate.message);
      }

      isValidEmail = VALIDAR_EMAIL(email);
      if (!isValidEmail) {
        setErrorMessage('O E-mail informado é inválido');
        return setBabackdrop(false);
      }

      let userExists;

      await GvpGetUser(email, formIdContext.numberCatalog).then((response) => {

        if (response.data === 'semcadastro'){
          userExists = false
        }else{
          userExists = true;
        }
      }).catch(() => {
        userExists = false
      })

      if (userExists) {
        setErrorMessage('O e-mail informado já está sendo utilizado em outro cadastro em nossa base. Por gentileza, informe um e-mail diferente');
        return setBabackdrop(false);
      } else {
        const req = generateRequisitionObjectForNewUser();

        GvpCreateUser(req)
          .then(response => {
            setValidFormFields(formIsValidate);
            handleClose(null, formIsValidate && isValidEmail);
            setBabackdrop(false);
          })
          .catch(error => {
            setErrorMessage(error);
            return setBabackdrop(false);
          });
      }
    }
    else {
      setValidFormFields(formIsValidate);
      handleClose(null, formIsValidate && isValidEmail);
    }
  }

  const getCpf = async (value) => {
    setErrorMessage('');
    const cpfClean = REMOVE_MASK_CPF(value)
    setCpf(cpfClean)

    if (cpfClean.length === 11) {
      if (!VALIDAR_CPF(cpfClean)) {
        setErrorMessage('O CPF informado é inválido');
        return;
      }

      setBabackdrop(true);
      if (cpfClean !== '') {

        GvpGetUser(cpfClean, formIdContext.numberCatalog)
          .then((response) => {
            return response.data
          })
          .then((data) => {
            if (data === 'semcadastro') {
              initialFieldsDataUser();
              setValidaForm(true);
              setCpf(cpfClean);
              setNewUser(true);
              setErrorMessage('Usuário não cadastrado, preencha o formulário.');

              obj.cpf = cpfClean;
              setFieldsIdentification(obj);
              return setBabackdrop(false)
            }

            if (data?.id) {

              setCpf(cpfClean);
              setCepValue(data.enderec?.zipcode);
              setNumeroLogradouro(data.enderec?.number);
              setBairro(data.enderec?.neighborhood);
              setCidade(data.enderec?.city);
              setDdd(data.phone?.ddd);
              setEmail(data.email);
              setLogradouro(data.enderec?.addressComplete);
              setNomeDoSolicitante(`${data.nome} ${data.sobrenome}`);
              setTelefone(data.phone?.number);
              setUserId(data.document);
              

              setNewUser(false);
              setErrorMessage('');
              return setBabackdrop(false)
            }
          })
          .catch((error) => {
            setNewUser(true);
            setValidaForm(true);
            initialFieldsDataUserLessCPF();
            initialFieldsLocaleUser();

            obj.cpf = cpfClean;
            setFieldsIdentification(obj);

            setErrorMessage('Usuário não cadastrado, preencha o formulário.')
          })
          .finally(() => {
            setBabackdrop(false);
          })
      }
      else {
        setBabackdrop(false);
      }
    }
  }

  const clearAddressFields = () => {
    setBairro('')
    setCidade('')
    setComplemento('')
    setDdd('')
    setLogradouro('')
    setUf('')
  }

  const setCepValue = async (e) => {
    if (e != null) {
      setCep(e);

      let cep = "" + e;
      obj.cep = REMOVE_MASK_CEP(e);

      cep = cep.replace(/[\s_.-]*/gim, '');

      if (cep.length !== 8) {
        return;
      }

      if (cep.length === 8) {
        setBabackdrop(true);

        MOUNT_CEP(cep)
          .then(response => response.data)
          .then(response => {
            if (response.erro) {
              clearAddressFields();
              setBabackdrop(false);
              return setErrorMessage('CEP inválido');
            }
            setErrorMessage('');

            changeBairro(response.bairro)
            changeCidade(response.localidade)
            changeComplemento(response.bairro)
            changeDdd(response.ddd)
            changeLogradouro(response.logradouro)
            changeUf(response.uf)

            setBabackdrop(false);
          })
          .catch(error => {
            console.error(error);
            setErrorMessage('Ocorreu um erro ao buscar os dados para o CEP informado, por favor tente novamente mais tarde');
            setBabackdrop(false);
          });
      }
      else {
        clearAddressFields();
      }
    }
  }


  // const getCep = (value) => {
  //   let cepClean = value.replace(/[\s_.-]*/gim, '')

  //   setCep(cepClean)
  //   obj.cep = cepClean

  //   if (cepClean.length === 8) {
  //     setBabackdrop(true);

  //     MOUNT_CEP(cepClean)
  //       .then(response => response.data)
  //       .then(response => {
  //         setBairro(response.bairro)
  //         setCidade(response.localidade)
  //         setComplemento(response.bairro)
  //         setDdd(response.ddd)
  //         setLogradouro(response.logradouro)
  //         setUf(response.uf)
  //       })
  //       .catch(error => {
  //         console.error(error)
  //         setErrorMessage('Um erro aconteceu ou o CEP informado está inválido, por favor tente novamente')
  //         initialFieldsDataUser()
  //         initialFieldsLocaleUser()
  //       })
  //       .finally(() => {
  //         setBabackdrop(false)
  //       })
  //   }
  // }

  const changeNomeDoSolicitante = (value) => {
    //value = value.trim();
    setNomeDoSolicitante(value)
    obj.name = value;
    setFieldsIdentification(obj);
  }

  const changeEmail = (value) => {
    //value = value.trim();
    setEmail(value)
    obj.email = value
    setFieldsIdentification(obj);
  }

  const changeDdd = (value) => {
    //value = value.trim();
    setDdd(value)
    obj.ddd = value;
    setFieldsIdentification(obj);
  }

  const changeTelefone = (value) => {
    //value = value.trim();
    setTelefone(value)
    obj.telefone = value;
    setFieldsIdentification(obj);
  }

  const changeRamal = (value) => {
    //value = value.trim();
    setRamal(value)
    obj.ramal = value;
    setFieldsIdentification(obj);
  }

  const changeLogradouro = (value) => {
    //value = value.trim();
    setLogradouro(value)
    obj.logradouro = value
    setFieldsIdentification(obj);
  }

  const changeNumeroLogradouro = (value) => {
    //value = value.trim();
    setNumeroLogradouro(value)
    obj.endNumero = value;
    setFieldsIdentification(obj);
  }

  const changeComplemento = (value) => {
    //value = value.trim();
    setComplemento(value)
    obj.complemento = value;
    setFieldsIdentification(obj);
  }

  const changeBairro = (value) => {
    //value = value.trim();
    setBairro(value)
    obj.bairro = value;
    setFieldsIdentification(obj);
  }

  const changeCidade = (value) => {
    //value = value.trim();
    setCidade(value)
    obj.cidade = value;
    setFieldsIdentification(obj);
  }

  const changeUf = (value) => {
    //value = value.trim();
    setUf(value)
    obj.uf = value;
    setFieldsIdentification(obj);
  }

  const setLanguageOptionsValue = (e) => {
    e.preventDefault();
    const { value } = e.target;
    setLanguageSelected(value);
  }

  return (
    <>
      <Dialog
        open={openDialog}
        onClose={onCloseDialog}
        disableEscapeKeyDown={true}
        disableBackdropClick={true}
        maxWidth="md"
        fullWidth={true}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{ overflow: "hidden" }}
      >
        <div className="form-row">
          <DialogTitle id="alert-dialog-title" divClass="col-md-8" >
            <Box className={classes.modalTitle}>{"Solicitar atendimento para outro usuário"}</Box>
          </DialogTitle>

          <>
            <Select
              divClass="col-md-4"
              labelId="demo-simple-select-filled-label"
              id="demo-simple-select-filled"
              value={languageSelected}
              onChange={setLanguageOptionsValue}
            >
              <MenuItem value={'br'}>Português (Brasil)</MenuItem>
              <MenuItem value={'en'}>Inglês</MenuItem>
              <MenuItem value={'es'} disabled>Espanhol</MenuItem>
            </Select>
          </>
        </div>

        <DialogContent dividers={true}>

          {errorMessage && <AlertError>{errorMessage}</AlertError>}

          <Box component="span" className={classes.boxInfoRequired}>
            <h5><b>*</b></h5>{'Campos de preenchimento obrigatório'}
          </Box>

          <DialogTitle id="alert-dialog-description">
            {'Dados do usuário que será atendido:'}
          </DialogTitle>

          <DialogContentText id="alert-dialog-description">
            <div className="form-row">
              <SerproFormInput
                divClass="col-md-3"
                name={
                  (languageSelected === 'br' && Lang.pt_br.cpf) ||
                  (languageSelected === 'en' && Lang.en_us.cpf)
                }
                mask="999.999.999-99"
                value={cpf}
                onChange={(e) => getCpf(e.target.value)}
                error
              />
              <SerproFormInput
                divClass="col-md-9"
                name={
                  (languageSelected === 'br' && Lang.pt_br.nomeDoSolicitante + ' *') ||
                  (languageSelected === 'en' && Lang.en_us.nomeDoSolicitante + ' *')
                }
                value={nameMask !== '' ? nameMask : nomeDoSolicitante}
                disabled={!newUser}
                onChange={(e) => changeNomeDoSolicitante(e.target.value)}
                error
                maxlength={100}
              />
            </div>
            {valForm && (
              <>
                <div className="form-row">
                  <SerproFormInput
                    divClass="col-md-6"
                    name={
                      (languageSelected === 'br' && Lang.pt_br.email + ' *') ||
                      (languageSelected === 'en' && Lang.en_us.email + ' *')
                    }
                    value={email}
                    disabled={!newUser}
                    onChange={(e) => changeEmail(e.target.value)}
                    error
                    maxlength={100}
                  />
                  <SerproFormInput
                    divClass="col-md-1"
                    name={
                      (languageSelected === 'br' && Lang.pt_br.ddd + ' *') ||
                      (languageSelected === 'en' && Lang.en_us.ddd + ' *')
                    }
                    mask="99"
                    value={ddd}
                    disabled={!newUser}
                    onChange={(e) => changeDdd(e.target.value)}
                    error
                  />
                  <SerproFormInput
                    divClass="col-md-3"
                    name={
                      (languageSelected === 'br' && Lang.pt_br.telefone + ' *') ||
                      (languageSelected === 'en' && Lang.en_us.telefone + ' *')
                    }
                    mask="99999999999"
                    value={telefone}
                    disabled={!newUser}
                    onChange={(e) => changeTelefone(e.target.value)}
                    error
                  />
                  <SerproFormInput
                    divClass="col-md-2"
                    name={
                      (languageSelected === 'br' && Lang.pt_br.ramal) ||
                      (languageSelected === 'en' && Lang.en_us.ramal)
                    }
                    value={ramal}
                    disabled={!newUser}
                    onChange={(e) => changeRamal(e.target.value)}
                    error
                  />
                </div>
              </>
            )}
          </DialogContentText>

          {valForm && (
            <>
              <DialogTitle id="alert-dialog-description">
                {'Localidade:'}
              </DialogTitle>
              <DialogContentText id="alert-dialog-description">
                <div className="form-row">
                  <SerproFormInput
                    divClass="col-md-3"
                    name={
                      (languageSelected === 'br' && Lang.pt_br.cep) ||
                      (languageSelected === 'en' && Lang.en_us.cep)
                    }
                    mask="99.999-999"
                    value={cep}
                    disabled={!newUser}
                    onChange={(e) => setCepValue(e.target.value)}
                    error
                  />
                  <SerproFormInput
                    divClass="col-md-7"
                    name={
                      (languageSelected === 'br' && Lang.pt_br.enderecoLogradouro) ||
                      (languageSelected === 'en' && Lang.en_us.enderecoLogradouro)
                    }
                    value={logradouro}
                    disabled={!newUser}
                    onChange={(e) => changeLogradouro(e.target.value)}
                    error
                  />
                  <SerproFormInput
                    divClass="col-md-2"
                    name={
                      (languageSelected === 'br' && Lang.pt_br.numero) ||
                      (languageSelected === 'en' && Lang.en_us.numero)
                    }
                    mask="99999"
                    value={endNumero}
                    disabled={!newUser}
                    onChange={(e) => changeNumeroLogradouro(e.target.value)}
                    error
                  />
                </div>

                <div className="form-row">
                  <SerproFormInput
                    divClass="col-md-6"
                    name={
                      (languageSelected === 'br' && Lang.pt_br.complemento) ||
                      (languageSelected === 'en' && Lang.en_us.complemento)
                    }
                    value={complemento}
                    disabled={!newUser}
                    onChange={(e) => changeComplemento(e.target.value)}
                    error
                  />
                  <SerproFormInput
                    divClass="col-md-6"
                    name={
                      (languageSelected === 'br' && Lang.pt_br.bairro) ||
                      (languageSelected === 'en' && Lang.en_us.bairro)
                    }
                    value={bairro}
                    disabled={!newUser}
                    onChange={(e) => changeBairro(e.target.value)}
                    error
                  />
                </div>

                <div className="form-row">
                  <SerproFormInput
                    divClass="col-md-10"
                    name={
                      (languageSelected === 'br' && Lang.pt_br.municipio) ||
                      (languageSelected === 'en' && Lang.en_us.municipio)
                    }
                    value={cidade}
                    disabled={!newUser}
                    onChange={(e) => changeCidade(e.target.value)}
                    error
                  />
                  <SerproFormInput
                    divClass="col-md-2"
                    name={
                      (languageSelected === 'br' && Lang.pt_br.uf) ||
                      (languageSelected === 'en' && Lang.en_us.uf)
                    }
                    value={uf}
                    disabled={!newUser}
                    onChange={(e) => changeUf(e.target.value)}
                    error
                  />
                </div>
              </DialogContentText>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <ButtonContainer>
            <Button
              onClick={handleClose}
              color="default"
              variant="contained"
              className={classes.buttonWidthDefault}
            >
              Fechar
            </Button>
            <Button
              onClick={handleSend}
              color="primary"
              variant="contained"
              autoFocus
              className={classes.buttonWidthDefault}
            >
              OK
            </Button>
          </ButtonContainer>
        </DialogActions>
        <Backdrop className={classes.backdrop} open={backdrop}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Dialog>
    </>
  )

}

OtherUser.prototype = {
  openDialog: PropTypes.bool,
  onCloseDialog: PropTypes.func,
}

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  buttonWidthDefault: {
    width: '150px'
  },
  boxInfoRequired: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'end',
    marginBottom: '-3%',
    marginTop: '1%',
    fontSize: '10pt',
    fontWeight: 'bold'
  },
  modalTitle: {
    fontSize: '25px',
    fontWeight: 'bold'
  }
}));