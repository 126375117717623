import React, { useRef, useEffect, useState } from "react";
import Timelapse from '@material-ui/icons/Timelapse';
import Grid from '@material-ui/core/Grid';
import { SerproAlert } from '../../components';
import { TYPE_ALERT } from '../../components/serproalert/SerproAlert';

const SerproTimer = () => {

    const [backdrop, setBackdrop] = useState(false);
    const [seconds, setSeconds] = useState(59);
    const [minutes, setMinutes] = useState(11); 
    const myBtn = useRef(null);

    function reset() {
      setSeconds(59);
      setMinutes(11);
      localStorage.removeItem("restartTimer");
    }
  
    useEffect(() => {
        let interval = setInterval(() => {setSeconds(seconds => seconds - 1)}, 1000);
        if(seconds === 0) {
          setSeconds(59);
        }
      if(localStorage.getItem("restartTimer")) {
        myBtn.current?.click();
      }
      return () => clearInterval(interval);
    }, [seconds]);

    useEffect(() => {
      let interval = setInterval(() => {setMinutes(minutes => minutes - 1)}, 60000);
      
      if(minutes === 1) {
        SerproAlert.showDialogInative(
          'Sessão Inativa',
          "Sessão inativa por mais de 11 minutos. Sua sessão expirará em 1 minuto(s) caso não execute nenhuma ação o sistema.",
          TYPE_ALERT.WORN,
          () => { 
            setBackdrop(true);
          },
          false,
          false
        );
      }

      if(minutes === 0) {
        setMinutes(11);
        SerproAlert.showDialogExpired(
          'Sessão Expirada',
          'Sessão Expirada!',
          TYPE_ALERT.WORN,
          () => { 
            setBackdrop(true);
            cleanData();
          },
          false,
          false
        );
      }

      return () => clearInterval(interval);
    }, [minutes]);

    const cleanData = () => {
      window.location.reload();
    }
  
    return (
    <>
      <div>
          <div>
           {' '}
           <img
            src={process.env.PUBLIC_URL + '/ellipse.png'}
            width="40"
            height="40"
            style={{
              marginLeft: '205px',
              position: 'absolute',
              marginTop: '20px',
            }}
            alt=""
          />
          <Grid
            item
            xs={8}
            style={{
              marginLeft: '213px',
              position: 'absolute',
              marginTop: '28px',
            }}
          >
            <Timelapse style={{ color: 'white' }} />
          </Grid>
          <label
            style={{
              marginLeft: '250px',
              position: 'absolute',
              marginTop: '25px',
              color: 'white',
              fontWeight: 'bold',
              fontSize: '20px',
            }}
          >
            <button hidden ref={myBtn} onClick={reset}></button>
            {minutes}:{seconds < 10 ? "0" + seconds : seconds}
          </label>
        </div>
      </div>
    </>
    );
  };

export default SerproTimer;