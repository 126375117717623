import React, { useState, useEffect, useContext } from 'react'
import { Link } from 'react-router-dom'
import {
  Container,
  FlexRow,
  FlexRowSaibaMais,
  FlexColumn,
  HighlightedWord,
  InfoText,
  Title,
  Description,
  OmniMensege,
  OmniDescription,
  OmniMensegeLink
} from './SerproFormContainer.style'
import PropTypes from 'prop-types'

import { VALIDAR_CATALOGO_MULTICLIENT } from '../../utils/ValidationForm'

import RedirectModal from '../../components/RedirectModal/RedirectModal'

import { FormIdContext } from '../../screens/form/FormIdContext';

function SerproFormContainer(props) {

  useEffect(() => {
    let validation = VALIDAR_CATALOGO_MULTICLIENT(formIdContext.numberCatalog);
    setCatalogMultiClient(validation);
  })

  const formIdContext = useContext(FormIdContext);

  const [openDialogRedirect, setOpenDialogRedirect] = useState(false);
  const [catalogMultiClient, setCatalogMultiClient] = useState(false);

  const handleCloseDialogRedirect = () => {
    setOpenDialogRedirect(false);
  };

  const handleOpenDialogRedirect = () => {
    setOpenDialogRedirect(true);
  };

  const {
    title = '',
    description = '',
    showInfo = false,
  } = props

  return (
    <Container>
      <FlexRow>
        <Title>{title}</Title>
        { formIdContext.numberCatalog !== '3522' && 
        
        <FlexColumn>

          {formIdContext.numberCatalog === '3192' && !catalogMultiClient &&
            <OmniDescription>
              Além deste canal, você pode ser atendido por chatbot, chat humano e whatsapp.
            </OmniDescription>
          }

          {formIdContext.numberCatalog !== '3192' && catalogMultiClient &&
            <OmniDescription>
              Você pode ser atendido por outro formulário.
            </OmniDescription>
          }
          
          {formIdContext.numberCatalog !== '3192' && !catalogMultiClient &&
            <OmniDescription>
              Além deste canal, você pode ser atendido por chatbot e chat humano.
            </OmniDescription>
          }
          <FlexRowSaibaMais>
            <OmniMensege>
              Saiba mais,
            </OmniMensege>
            <OmniMensegeLink onClick={handleOpenDialogRedirect}>
              clicando aqui!
            </OmniMensegeLink>
          </FlexRowSaibaMais>

        </FlexColumn>}
      </FlexRow>
      <Description>{description}</Description>
      {showInfo &&
        <InfoText>
          <HighlightedWord>*</HighlightedWord>
          Campos de preenchimento obrigatório.
        </InfoText>
      }
      <>{props.children}</>

      <RedirectModal openDialog={openDialogRedirect} onCloseDialog={handleCloseDialogRedirect}  ></RedirectModal>

    </Container>
  )
}

export default SerproFormContainer

SerproFormContainer.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  showInfo: PropTypes.bool,
}
