import styled from 'styled-components'

/** DESCRIÇÃO FORM */
export const MensagemInformativa = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 20px 20px;
    position: relative;
    width: 100%;
    height: auto;
    margin-bottom: 1%;
    background: #D5EEF8;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 4px;

    font-style: normal;
    font-weight: bold;
    line-height: 126.9%;
    color: rgba(0, 0, 0, 0.53);
`

export const AlertQtdeCaracteresRestantes = styled.span`
  color: #5b5e09;
  font-weight: normal;
  font-style: italic;
  margin: 0;
  margin-left: 0.5%;
  margin-top: -10px;
`

export const AlertQtdeArquivosAnexados = styled.label`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 20px 20px;
  width: 100%;
  height: 10px;
  background: #FFAEAE;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
`

export const AlertTamanoArquivoAnexado = styled.label`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 20px 20px;
  width: 100%;
  height: 10px;
  background: #FFAEAE;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
`

export const AlertTipoArquivo = styled.label`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 20px 20px;
  width: 100%;
  height: 10px;
  background: #FFAEAE;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
`

export const FileItem = styled.div`
  background: rgba(0, 0, 0, 0.05);
  padding: 7px 15px;
  border-radius: 6px;
  margin: 4px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  p {
    margin: 0;
    width: 30%;
  }
`;

/** FORM SCREEN */
export const ButtonContainer = styled.div`
  width: 90%;
  margin: 2%;
  display: flex;
  justify-content: space-evenly;
  padding-left: 5%;
  padding-right: 5%;
`

export const AlertUser = styled.span`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 0;
  padding: 15px 20px;
  margin-top: 0.75%;
  width: 100%;
  height: 35px;
  font-weight: 500;
  background: #FFAEAE;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  background-color: rgba(255,174,174, 0.9);
  border-radius: 4px;
`

/** IDDENTIFICAÇÃO FORM */
export const ErrorMessage = styled.span`
  color: #f91e1e;
  margin-left: 0.5%;
  text-align: center;
  font-weight: bold;
  width: auto;
  height: 30px;
  margin-top: -13px;
  margin-bottom: 0.5%;
`

export const AlertUserLabel = styled.span`
  margin-left: 0.5%;
  text-align: center;
  font-weight: bold;
  width: auto;
  height: 30px;
  margin-top: -13px;
  margin-bottom: 0.5%;
`

export const Loading = styled.span`
  color: #523be4;
  margin-left: 0.5%;
  text-align: center;
  font-weight: bold;
  width: auto;
  height: 30px;
  margin-top: -13px;
  margin-bottom: 0.5%;
`

export const OtherUserContainer = styled.span`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 0.5%;
  margin-bottom: 0.5%;
`

export const AlertError = styled.span`
display: flex;
flex-direction: row;
align-items: center;
text-align: center;
margin: 0;
padding: 15px;
margin-top: 0%;
margin-bottom: 0%;
font-weight: bold;
width: 100% auto;
color: #c23c3c;
background: #FFAEAE;
box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
border-radius: 4px;
`
export const InfoRelator = styled.div`
  width: 100% auto;
  padding: 15px;
  color: #000;
  background: #FFD27C;
  box-shadow: 0px 1.5px 1.5px rgba(0, 0, 0, 0.5);
  border-radius: 4px;
  opacity: 0.8;

  span {
    margin-right: 25px;
    margin-left: 5px;
  }
`;
