import { cpf, cnpj } from 'cpf-cnpj-validator';
// import * as EmailValidator from 'email-validator';
import { CATALOGS_MULTICLIENT } from './ConstDatas';
var validator = require("email-validator");



export const IS_CPF = (cpf) => {
  if (typeof cpf !== 'string') return false
  cpf = cpf.replace(/[\s.-]*/gim, '')
  cpf = cpf.replace(/[\s_]*/gim, '')
  if (
    !cpf ||
    cpf.length !== 11 ||
    cpf === '00000000000' ||
    cpf === '11111111111' ||
    cpf === '22222222222' ||
    cpf === '33333333333' ||
    cpf === '44444444444' ||
    cpf === '55555555555' ||
    cpf === '66666666666' ||
    cpf === '77777777777' ||
    cpf === '88888888888' ||
    cpf === '99999999999'
  ) {
    return false
  }
  var soma = 0
  var resto
  for (let i = 1; i <= 9; i++)
    soma = soma + parseInt(cpf.substring(i - 1, i)) * (11 - i)
  resto = (soma * 10) % 11
  if (resto === 10 || resto === 11) resto = 0
  if (resto !== parseInt(cpf.substring(9, 10))) return false
  soma = 0
  for (let i = 1; i <= 10; i++)
    soma = soma + parseInt(cpf.substring(i - 1, i)) * (12 - i)
  resto = (soma * 10) % 11
  if (resto === 10 || resto === 11) resto = 0
  if (resto !== parseInt(cpf.substring(10, 11))) return false
  return true
}

export const REMOVE_MASK_CPF = (value) => {
  const strValue = value
    .replace('', '')
    .replace('.', '')
    .replace('.', '')
    .replace('-', '')
    .replace(/_/g, '')

  return strValue
}

export const ADICIONA_MASK_CPF = (value) => {
  return cpf.format(value);
}

export const REMOVE_MASK_CNPJ = (value) => {
  const strValue = value
    .replace('', '')
    .replace('.', '')
    .replace('.', '')
    .replace('/', '')
    .replace('-', '')
    .replace(/_/g, '')

  return strValue
}

export const REMOVE_MASK_CEP = (value) => {
  const strValue = value
    .replaceAll('.', '')
    .replaceAll('-', '')
    .replace(/_/g, '');

  return strValue;
}

export const REMOVE_MASK_TELEFONE = (value) => {
  const strValue = value
    .replaceAll('-', '')
    .replace(/_/g, '');

  return strValue;
}

export const DECOMPOR_ENDERECO = (endereco) => {
  let logradouro = ''
  let numero = 0
  let complemento = ''
  let bairro = ''
  let cidade = ''
  let estado = ''

  let posInicioLogradouro = 0;
  let posFinalLogradouro = endereco.indexOf(', No: ');
  let posInicioNumero = posFinalLogradouro + 6
  let posFinalNumero = endereco.indexOf(' - ')
  let posInicioComplemento = posFinalNumero + 3
  let posFinalComplemento = endereco.indexOf('. ')
  let posInicioBairro = posFinalComplemento + 2
  let posFinalBairro = endereco.indexOf(' - ', posInicioBairro)
  let posInicioCidade = posFinalBairro + 3
  let posFinalCidade = endereco.indexOf(' / ')
  let posInicioEstado = posFinalCidade + 3

  let sucesso = true;

  try {
    if (posFinalLogradouro < 0) {
      console.warn('Não foi possível decompor o logradouro do endereço', endereco)
      sucesso = false
    }
    logradouro = endereco.substring(posInicioLogradouro, posFinalLogradouro)

    if (posFinalNumero < 0) {
      console.warn('Não foi possível decompor o número do endereço', endereco)
      sucesso = false
    }
    numero = endereco.substring(posInicioNumero, posFinalNumero)

    if (posFinalComplemento < 0) {
      console.warn('Não foi possível decompor o complemento do endereço', endereco)
      sucesso = false
    }
    complemento = endereco.substring(posInicioComplemento, posFinalComplemento)

    if (posFinalBairro < 0) {
      console.warn('Não foi possível decompor o bairro do endereço', endereco)
      sucesso = false
    }
    bairro = endereco.substring(posInicioBairro, posFinalBairro)

    if (posFinalCidade < 0) {
      console.warn('Não foi possível decompor a cidade do endereço', endereco)
      sucesso = false
    }
    cidade = endereco.substring(posInicioCidade, posFinalCidade)

    estado = endereco.substring(posInicioEstado)

  } catch (e) {
    console.warn('Não foi possível decompor o estado do endereco', endereco)
    sucesso = false
  }

  return {
    'sucesso': sucesso,
    'logradouro': logradouro,
    'numero': numero,
    'complemento': complemento,
    'bairro': bairro,
    'cidade': cidade,
    'estado': estado
  }
}

export const VALIDAR_CPF = value => {
  /** deve resceber um "value" do tipo "number" de tamanho "11" para validar */
  return cpf.isValid(value);
}

export const VALIDAR_CNPJ = value => {
  return cnpj.isValid(value);
}

export const VALIDAR_FORMATO_CNPJ = value => {
  return cnpj.format(value);
}

export const VALIDAR_EMAIL = value => {
  return validator.validate(value);
}

export const VALIDAR_CATALOGO_MULTICLIENT = catalog => {
  let validation = false

  CATALOGS_MULTICLIENT.map(obj => {
    if (obj === catalog) validation = true
  });

  return validation
}