import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'
import Button from '@mui/material/Button';
import { useParams } from 'react-router-dom';
import { CatalogByNumber, OffersByNumberCatalog, ticketlevel2, updateTicketZammad } from '../../service/GlobalService';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import Snackbar from '@mui/material/Snackbar';
import { useFormId } from './../form/FormIdContext';
import IdentificacaoV2 from '../form-v2/identificacao/Identificacao-v2';
import DescricaoV2 from '../form-v2/descricao/Descricao-v2';
import { Backdrop, CircularProgress, Dialog, makeStyles } from '@material-ui/core';
import DeleteSweepIcon from '@mui/icons-material/DeleteSweep';
import { identificationTabValidations, fieldAssuntoValidations, dynamicFieldValidations, fieldDetalhesValidations, internacionalValidation } from '../../utils/ValidationRules';
import { GvpGetUser, GvpCreateUser, GvpCreateTicket, GvpUpdateTicket, CREATE_USER_INTERNATIONAL, createTicketZammad } from '../../service/GlobalService';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import { cnpjValidation } from '../../utils/ValidationRules';
import TermosV2 from '../../components/TermosModal/TermosModal-v2';
import Cookies from 'universal-cookie';
import Error from '../../components/error/Error';
import { REACT_CONTINGENCY } from '../../utils/ConstDatas';
import NewSerproHeader from '../../components/newSerproHeader/newSerproHeader';
import IdentificacaoInternatialV2 from '../form-v2/identification-internacional/identification-internetional-v2';
import { Lang } from '../../utils/Languages';
import { useTranslation } from 'react-i18next';

function ScreenSection() {

    let { id } = useParams();
    const classes = useStyles();
    const { t, i18n: { changeLanguage, language } } = useTranslation();
    const [currentLanguage, setCurrentLanguage] = useState(language);

    const [steps, setSteps] = useState('identificacao');
    const [activeStep, setActiveStep] = useState(0);

    const { setFormIdContextAdd, formIdContext, setFormIdContext } = useFormId();
    const [formValid, setFormValid] = useState(true);
    const [catalog, setCatalog] = useState({});
    const [openAlertError, setOpenAlertError] = useState(false);
    const [erroMessage, setErroMessage] = useState('');
    const [backdrop, setBackdrop] = useState(false);
    const [genericDialog, setGenericDialg] = useState(false);
    const [genericDialogTitle, setGenericDialgTitle] = useState('');
    const [genericDialogDescription, setGenericDialgDescription] = useState('');
    const [genericDialogButton, setGenericDialgButton] = useState('');
    const [typeButton, setTypeButton] = useState('default');
    const [textBackdrop, setTextBackdrop] = useState('');
    const [idTicket, setIdTicket] = useState('');
    const [numberTicket, setNumberTicket] = useState('');
    const [openDialogTerms, setOpenDialogTerms] = useState(false);
    const [openDialogError, setOpenDialogError] = useState(false);

    const [offerOfcatalog, setOfferOfcatalog] = useState([]);
    const [images, setImages] = useState([]);
    const [fieldsDescription, setFieldsDescription] = useState({});
    const [lang, setLang] = useState('');

    useEffect(async () => {

        const cookies = new Cookies();
        if (!cookies.get('termos_uso')) {
            cookies.set('termos_uso', true, { path: '/', maxAge: 8000000 });
            setOpenDialogTerms(true);
        }

        openBackdrop('Consultando catálogo');

        CatalogByNumber(id)
            .then(response => {

                const catalog = response.data;

                if (catalog.autentication && !formIdContext.authorized)
                    window.location = process.env.REACT_APP_FORM_SCCD_KEY_CLOACK

                if (catalog.in24)
                    window.location = process.env.REACT_APP_FORM_PSERPRO + id;

                const validForm = catalog.status === 'ACTIVE' && catalog.numberCatalog === id;

                if (!catalog.in24 && catalog.in19 && !catalog.in27) {
                    window.location = process.env.REACT_APP_FORM_PSERPRO_INTER + id;
                }

                if (!catalog.in24 && catalog.in19 && catalog.in27) {
                    setSteps('identificacao-internacional');
                }

                if (!catalog.in27) // in27 verify
                    window.location = `${process.env.REACT_APP_FORM_ZDYNAMIC_HOME}upperScreenForm/${catalog.numberCatalog}/`

                if (!validForm) {
                    closeBackdrop();
                    generateGenericDialog(true, 'Atenção', `Catálogo ${id} não disponível`, 'Ok', 'reload');
                }
                setCatalog(catalog);
                setFormIdContext(catalog);
                closeBackdrop();
            })
            .catch(error => {
                closeBackdrop();
                const { response } = error;
                if (response?.status === 404) {
                    generateGenericDialog(true, 'Atenção', `Catálogo não encontrado`, 'Ok', 'reload');
                }
                else {
                    setOpenDialogError(true);
                }
            });
    }, [])

    const goToHome = () => {
        window.location = `https://centraldeajuda.serpro.gov.br/duvidas/${language}/`
    }

    const controllerErrorAlert = (object) => {
        if (object.show) {
            setOpenAlertError(true);
            setErroMessage(object.message);
        } else {
            setOpenAlertError(false);
            setErroMessage('')
        }
    }

    const setMenssageErrorAlertLang = (validation) => {
        if (validation.idMenssage) {
            if (validation.idMenssage == 'campoVazio') return t('campoVazio', { field: validation.fieldInvalid });
            if (validation.idMenssage == 'campoIncompleto') return t('campoIncompleto', { field: validation.fieldInvalid });
            if (validation.idMenssage == 'detalhesDemandaVazio') return t('detalhesDemandaVazio');
            if (validation.idMenssage == 'detalhesDemandaInvalidoCaracteres') return t('detalhesDemandaInvalidoCaracteres');
        }

        return validation.message;
    }

    const openErrorAlert = (message) => {
        controllerErrorAlert({ message: message, show: true })
    }



    const closeErrorAlert = () => {
        controllerErrorAlert({ message: '', show: false })
    }


    const controllerBackdrop = (object) => {
        if (object.show) {
            setBackdrop(true);
            setTextBackdrop(object.message);
        } else {
            setBackdrop(false);
            setTextBackdrop('');
        }
    }

    const openBackdrop = (message) => {
        controllerBackdrop({ message: message, show: true })
    }

    const closeBackdrop = () => {
        controllerBackdrop({ message: '', show: false })
    }

    const registerUser = async () => {

        closeErrorAlert();
        openBackdrop('Registrando usuário');

        const validationUser = identificationTabValidations(formIdContext, formIdContext.newUser);
        if (!validationUser.passed) {
            closeBackdrop();
            openErrorAlert(validationUser.message);
            return
        }

        const emailExist = await findUserForEmail();
        if (emailExist) {
            closeBackdrop();
            openErrorAlert('O e-mail informado já está sendo utilizado em outro cadastro em nossa base. Por gentileza, informe um e-mail diferente');
        }

        const user = mapToUser();

        await GvpCreateUser(user)
            .then(() => {
                generateTicket()
                    .then(() => {
                        closeBackdrop();
                        generateGenericDialog(true, 'Novo registro', 'Cadastro de usuário realizado com sucesso!', 'Ok', 'default');
                        formIdContext.newUser = false;
                        setFormIdContextAdd({ setCpfDisable: true, setCnpjDisabled: true });
                        setFormValid(true);
                    }).catch(error => {
                        closeBackdrop();
                        generateGenericDialog(true, 'Atenção', 'Falha ao abrir o chamado, por gentileza tente mais tarde.', 'Ok', 'default');
                    })
            }).catch(error => {
                closeBackdrop();
                generateGenericDialog(true, 'Atenção', 'Falha ao cadastrar o usuário, por gentileza verifique seus dados e tente mais tarde.', 'Ok', 'default');
            });

    }

    const mapToUser = () => {
        return {
            document: formIdContext.cpf,
            fullName: formIdContext.name,
            email: formIdContext.email,
            address: {
                number: filterAdressNumber(formIdContext.endNumero),
                neighborhood: formIdContext.bairro,
                state: formIdContext.uf,
                city: formIdContext.cidade,
                zipCode: formIdContext.cep,
                addressComplete: `${formIdContext.logradouro}`,
                complemento: formIdContext.complemento
            },
            phone: {
                ddd: formIdContext.ddd,
                number: formIdContext.telefone
            }
        }
    }

    const findUserForEmail = async () => {
        let result;
        await GvpGetUser(formIdContext.email, formIdContext.numberCatalog).then((res) => {
            if (res.data?.id && res.data?.id !== 0) {
                result = true;
            } else {
                result = false
            }
        }).catch(() => {
            result = false
        })
        return result;
    }

    function filterAdressNumber(input) {
        if (!input) return 0
        let array = input.split('')
        let filtered = array.filter(el => el > -1);
        return parseInt(filtered.join(''))
    }

    const generateTicket = async () => {

        const object = generateFirstObject();

        await GvpCreateTicket(object)
            .then(response => {
                setIdTicket(response.data);
                setNumberTicket(response.data);
                setFormIdContextAdd({ setCpfDisable: true, setCnpjDisabled: true, idTicket: response.data });
            })
            .catch(error => {
                generateGenericDialog(true, 'Atenção', 'Falha ao abrir o chamado, por favor tente novamente!', 'Ok', 'default');
            });
    }

    const generateFirstObject = () => {

        let logradouro = '';
        logradouro += formIdContext.logradouro ? formIdContext.logradouro : '';
        logradouro += formIdContext.endNumero ? '::' + formIdContext.endNumero : '';
        logradouro += formIdContext.complemento ? '::' + formIdContext.complemento : '';

        return {
            title: process.env.REACT_APP_ENVIRONMENT == 'prd' ? "DÚVIDA/INFORMAÇÃO" : "homologacao 0205HOMOLOG",
            group: 'NIVEL 1::SERVICE DESK',
            customer: formIdContext.cpf,
            cep: formIdContext.cep,
            cnpj: formIdContext.cnpj,
            logradouro,
            category: 'GESTÃO DO ATENDIMENTO CSS::DÚVIDA/INFORMAÇÃO::3105::13351',
            categoria: 'GESTÃO DO ATENDIMENTO CSS::DÚVIDA/INFORMAÇÃO::3105::13351',
            canal: 'FORMULARIO',
            article: {
                subject: "DÚVIDA/INFORMAÇÃO",
                body: 'Chamado criado via formulário para orientação/registro: ' + formIdContext.description,
                internal: true,
                attachments: [],
            },
            owner: 'mxintstef',
            state: '772',
            finalizador: 'FORMULARIO',
            aplicarplano: false,
            new_group: 'ATGSBRTERC',
            gruposolucionador: 'ATGSBRTERC',
            affected_by: formIdContext.relatorCpf
        };
    }



    const generateGenericDialog = (status, title, description, buttonText, typeButton) => {
        setGenericDialg(status);
        setGenericDialgTitle(title);
        setGenericDialgDescription(description);
        setGenericDialgButton(buttonText);
        setTypeButton(typeButton);
    }

    const handleNext = async () => {

        if (formIdContext.cnpjRequiredFormWeb) {
            closeErrorAlert();
            const validation = cnpjValidation(formIdContext.cnpj);
            if (!validation.passed) {
                closeBackdrop();
                openErrorAlert(validation.message);
                return
            }

            setFormIdContextAdd({ setCpfDisable: true, setCnpjDisabled: true });
        }

        openBackdrop('Consultando seus acessos as ofertas');

        formIdContext.in19 ? await generateTicketInternational() : await generateTicket();

        OffersByNumberCatalog(formIdContext.numberCatalog)
            .then(response => {
                const offers = response.data;
                setOfferOfcatalog(offers);
                setSteps('descricao');
            })
            .catch(error => {
                console.error('error :>> ', error);
                if (error.response.status === 404) {
                    openErrorAlert('Não existem ofertas para este catálogo.');
                }
                else {
                    openErrorAlert('Não foi possível recuperar as ofertas.');
                }
            });

        closeBackdrop();
    }

    const callbackImage = (image) => {
        setImages(image);
    }

    const finalUpdate = async (e) => {
        e.preventDefault();
        openBackdrop();
        closeErrorAlert();

        const validationAssuntoResult = fieldAssuntoValidations(fieldsDescription);
        if (!validationAssuntoResult.passed) {
            closeBackdrop();
            openErrorAlert(t('validacaoAssunto'));
            return;
        }

        const validationsDynamicsFieldsResult = dynamicFieldValidations(fieldsDescription.data);
        if (!validationsDynamicsFieldsResult.passed) {
            closeBackdrop();
            const menssage = setMenssageErrorAlertLang(validationsDynamicsFieldsResult);
            openErrorAlert(menssage);
            return;
        }

        const validationDetalhesResult = fieldDetalhesValidations(fieldsDescription.details);
        if (!validationDetalhesResult.passed) {
            closeBackdrop();
            const menssage = setMenssageErrorAlertLang(validationDetalhesResult);
            openErrorAlert(menssage);
            return;
        }

        if (formIdContext.in19) {
            await updateTicketInternational();
        } else {
            const validationIdentificationsTabResult = identificationTabValidations(formIdContext);
            if (!validationIdentificationsTabResult.passed) {
                closeBackdrop();
                openErrorAlert(validationIdentificationsTabResult.message);
                return;
            }

            if (formIdContext.cnpjRequiredFormWeb && !formIdContext.cnpj) {
                closeBackdrop();
                openErrorAlert('O campo CNPJ deve ser preenchido');
            }
            await updateTicket();
        }
        return;
    }

    const updateTicket = () => {
        openBackdrop();
        try {
            const object = generateRequisitionObjectForTicket();
            GvpUpdateTicket(object)
                .then(resp => {
                    generateGenericDialog(true, 'Processo realizado com sucesso', `Número do ticket ${formIdContext.idTicket}`, 'Ok', 'reload');
                    closeBackdrop();
                })
                .catch(error => {
                    console.error(error);
                    generateGenericDialog(true, 'Ops! Ocorreu um erro...', 'Tente novamente por favor', 'Ok', 'reload');
                })

        } catch (error) {
            generateGenericDialog(true, 'Atenção', 'Falha no cadastro da oferta, por favor entre em contato com o administrador do catalogo.', 'Ok', 'reload');
            closeBackdrop();
        }
    }

    const updateTicketInternational = () => {
        openBackdrop();
        try {
            const object = generateFinalUpdateObjectInternational();
            GvpUpdateTicket(object)
                .then(resp => {
                    generateGenericDialog(true, t('abrirTicketSucesso'), `${t('numeroTicket')} ${numberTicket}`, 'Ok', 'reload');
                    closeBackdrop();
                })
                .catch(error => {
                    console.error(error);
                    generateGenericDialog(true, t('falhaAberturaTicket'), t('falhaAberturaTicketTexto'), 'Ok', 'reload');
                })

        } catch (error) {
            generateGenericDialog(true, t('atencao'), t('falhaCadastroOferta'), 'Ok', 'reload');
            closeBackdrop();
        }
    }

    const generateRequisitionObjectForTicket = () => {
        try {
            let logradouro = '';
            logradouro += formIdContext.logradouro ? formIdContext.logradouro : '';
            logradouro += formIdContext.endNumero ? '::' + formIdContext.endNumero : '';
            logradouro += formIdContext.complemento ? '::' + formIdContext.complemento : '';

            let objectTicket = {
                title: process.env.REACT_APP_ENVIRONMENT == 'prd' ? formIdContext.topic : "homologacao 0205HOMOLOG",
                group: 'NIVEL 1::SERVICE DESK',
                customer: steps === 'identificacao-internacional' ? formIdContext.email : formIdContext.cpf,
                cep: formIdContext.cep,
                cnpj: formIdContext.cnpj,
                //state_id: 20,
                logradouro,
                category: (`${formIdContext.description}:: ${formIdContext.topic}:: ${formIdContext.numberCatalog}:: ${formIdContext.numberOffer}`).toUpperCase(),
                categoria: (`${formIdContext.description}:: ${formIdContext.topic}:: ${formIdContext.numberCatalog}:: ${formIdContext.numberOffer}`).toUpperCase(),
                canal: 'FORMULARIO',
                article: {
                    subject: formIdContext.topic,
                    body: 'Chamado criado via formulário para orientação/registro: ' + formIdContext.description + '; Detalhes da demanda: ' + fieldsDescription.details,
                    internal: true,
                    attachments: images,
                },
                owner: 'mxintstef',
                state: '767',
                finalizador: 'FORMULARIO',
                aplicarplano: formIdContext.groupLevelOne ? false : true,
                idserpro: `${idTicket}`,
                campoS_EXTRAS: generateArticleBodyForObjectRequisition(formIdContext.data),
                in27: formIdContext.in27
            };

            if (formIdContext.groupLevelOne) {
                objectTicket = {
                    ...objectTicket,
                    gruposolucionador: formIdContext.groupLevelOne,
                    new_group: formIdContext.groupLevelOne
                }
            }

            return objectTicket

        } catch (error) {
            console.log('generateRequisitionObjectForTicket', error);
            throw Error(error);
        }
    }

    const generateArticleBodyForObjectRequisition = (details) => {

        try {
            let body = '';

            if (details) {
                details.forEach(detail => {
                    body += `${detail.attrId}!(*| _ |*)! ${detail.serproDisplay === null ? ' ' : detail.serproDisplay?.toUpperCase()}!(*| _ |*)! ${detail.value}!(*| _ |*)! ${detail.type}!(***| _ |***)!`;
                });
            }

            return body;

        } catch (error) {
            console.log('generateArticleBodyForObjectRequisition', error)
            throw Error('Erro no cadastro da oferta')
        }
    }

    const resetForm = (e) => {
        closeBackdrop();

        if (formIdContext.saUserCnpj) {
            window.location = process.env.REACT_APP_FORM_PSERPRO_INTER
        } else {
            window.location = process.env.REACT_APP_FORM_ZDYNAMIC_HOME
        }

        window.location.reload();
    }

    const redirectSingleAcess = () => {
        closeBackdrop();
        window.location = `${process.env.REACT_APP_FORM_ZDYNAMIC_HOME}SingleAccess`
    }

    const handleCloseDialogTerms = (data) => {
        setOpenDialogTerms(false);
    };

    const handleCloseDialogError = (data) => {
        setOpenDialogError(false);
    };

    const disableBackDrop = () => {
        if (typeButton === 'reload')
            setGenericDialg(true);
        else
            setGenericDialg(false);
    }

    const registerUserInternacional = async () => {
        closeErrorAlert();
        openBackdrop(t('consultaUsuarioBackdrop'));

        const user = mapToUserInternacional();
        const validation = internacionalValidation(user);

        if (!validation.passed) {
            closeBackdrop();
            openErrorAlert(t(validation.message));
            return;
        }

        GvpCreateUser(user).then(() => {
            generateTicketInternational()
                .then(() => {
                    closeBackdrop();
                    generateGenericDialog(true, t('novoRegistro'), t('usuarioCadastradoSucesso'), 'Ok', 'default');
                    formIdContext.newUser = false;
                    setFormIdContextAdd({ setEmailDisable: true, setFieldsInternationalDisable: false });
                    setFormValid(true);
                }).catch(error => {
                    closeBackdrop();
                    generateGenericDialog(true, t('atencao'), t('abrirTicketFalha'), 'Ok', 'default');
                })
        }).catch(error => {
            closeBackdrop();
            generateGenericDialog(true, t('atencao'), t('usuarioCadastradoFalha'), 'Ok', 'default');
        });

    }

    const mapToUserInternacional = () => {

        return {
            fullName: formIdContext.name,
            email: formIdContext.email,
            empresa: formIdContext.empresa,
            address: {
                number: 0,
                neighborhood: 'N/A',
                addressComplete: formIdContext.logradouro
            },
            phone: {
                ddi: '00',
                number: formIdContext.telefone
            },
            // lang: lang,
            Lang: lang,
            estrangeiro: true,
            endereco: formIdContext.logradouro
        }
    }

    const generateTicketInternational = async () => {

        const object = generateFirstObjectInternational();

        await GvpCreateTicket(object)
            .then(response => {
                setIdTicket(response.data);
                setNumberTicket(response.data);
                setFormIdContextAdd({ setCpfDisable: true, setCnpjDisabled: true, idTicket: response.data });
            })
            .catch(error => {
                generateGenericDialog(true, t('atencao'), t('abrirTicketFalha'), 'Ok', 'default');
            });
    }

    const generateFirstObjectInternational = () => {
        return {
            title: process.env.REACT_APP_ENVIRONMENT == 'prd' ? "DÚVIDA/INFORMAÇÃO" : "homologacao 0205HOMOLOG",
            group: 'NIVEL 1::SERVICE DESK',
            customer: formIdContext.email,
            cep: formIdContext.cep ? formIdContext.cep : 'N/I',
            cnpj: formIdContext.cnpj ? formIdContext.cnpj : 'N/I',
            logradouro: formIdContext.endereco,
            category: 'GESTÃO DO ATENDIMENTO CSS::DÚVIDA/INFORMAÇÃO::3105::13351',
            categoria: 'GESTÃO DO ATENDIMENTO CSS::DÚVIDA/INFORMAÇÃO::3105::13351',
            canal: 'FORMULARIO',
            article: {
                subject: "DÚVIDA/INFORMAÇÃO",
                body: 'Chamado criado via formulário para orientação/registro: ' + formIdContext.description,
                internal: false,
                attachments: [],
            },
            owner: 'mxintstef',
            state: '772',
            finalizador: 'FORMULARIO',
            aplicarplano: false,
            new_group: 'ATGSBRTERC',
            gruposolucionador: 'ATGSBRTERC',
            empresa: formIdContext.empresa ? formIdContext.empresa : 'N/I',
            endereco: formIdContext.endereco ? formIdContext.endereco : 'N/I',
            estrangeiro: true
        }
    }

    const registerUserController = () => {
        if (formIdContext.in19)
            registerUserInternacional();
        else
            registerUser();
    }

    const generateUpdateObjectInternational = () => {
        return {
            state: 'DIRECIONADO',
            article: {
                subject: formIdContext.topic,
                body: 'Chamado criado via formulário para orientação/registro: ' + formIdContext.description + '; Detalhes da demanda: ' + formIdContext.details,
                internal: true,
                attachments: images,
            }
        }
    }

    const generateFinalUpdateObjectInternational = () => {
        try {
            let logradouro = '';
            logradouro += formIdContext.logradouro ? formIdContext.logradouro : '';
            logradouro += formIdContext.endNumero ? '::' + formIdContext.endNumero : '';
            logradouro += formIdContext.complemento ? '::' + formIdContext.complemento : '';

            let objectTicket = {
                title: process.env.REACT_APP_ENVIRONMENT == 'prd' ? formIdContext.topic : "homologacao 0205HOMOLOG",
                group: 'NIVEL 1::SERVICE DESK',
                customer: steps === 'identificacao-internacional' ? formIdContext.email : formIdContext.cpf,
                cep: formIdContext.cep,
                cnpj: formIdContext.cnpj,
                //state_id: 20,
                logradouro,
                category: (`${formIdContext.description}:: ${formIdContext.topic}:: ${formIdContext.numberCatalog}:: ${formIdContext.numberOffer}`).toUpperCase(),
                categoria: (`${formIdContext.description}:: ${formIdContext.topic}:: ${formIdContext.numberCatalog}:: ${formIdContext.numberOffer}`).toUpperCase(),
                canal: 'FORMULARIO',
                article: {
                    subject: formIdContext.topic,
                    body: 'Chamado criado via formulário para orientação/registro: ' + formIdContext.description + '; Detalhes da demanda: ' + fieldsDescription.details,
                    internal: true,
                    attachments: images,
                },
                owner: 'mxintstef',
                state: '767',
                finalizador: 'FORMULARIO',
                aplicarplano: formIdContext.groupLevelOne ? false : true,
                idserpro: `${idTicket}`,
                campoS_EXTRAS: generateArticleBodyForObjectRequisition(formIdContext.data),
                in27: formIdContext.in27
            };

            if (formIdContext.groupLevelOne) {
                objectTicket = {
                    ...objectTicket,
                    gruposolucionador: formIdContext.groupLevelOne,
                    new_group: formIdContext.groupLevelOne
                }
            }

            if (formIdContext.in19) {
                objectTicket = {
                    ...objectTicket,
                    empresa: formIdContext.empresa ? formIdContext.empresa : 'N/A',
                    endereco: formIdContext.endereco ? formIdContext.endereco : 'N/A',
                    estrangeiro: true
                }
            }

            return objectTicket

        } catch (error) {
            console.log('generateRequisitionObjectForTicket', error);
            throw Error(error);
        }
    }

    const mapToFilesZammad = (file) => {
        return {
            'content_type': file.contenttype,
            'data': file.data,
            'filename': file.filename,
            'mime-type': file.mimetype,
            'size': 0
        }
    }

    const getIdentification = () => {
        formIdContext.in19 ? setSteps('identificacao-internacional') : setSteps('identificacao');
    }

    const setFieldsDescriptionValue = (value) => {
        setFieldsDescription(value);
    }

    const setLangValue = (value) => {
        setLang(value);
    }

    return (
        <>
            <NewSerproHeader setLangForm={setLangValue} />
            <seciton style={{ display: 'inline-block', width: '100%', height: '900px', backgroundColor: '#F4F4F4', boxShadow: 'inset 0px 4px 4px #0000001F', }}>
                <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', padding: '2em 4em' }}>
                    <div style={{ display: 'flex', width: '80%' }}>
                        <Link style={{ display: 'flex', alignItems: 'center', textDecorationLine: 'none' }} to={'/'}>{`
                        ${t('caminho', {
                            catalogo: catalog.description
                        })
                            }
                        `}</Link>
                    </div>
                    <Button style={{ padding: '5px 20px', borderRadius: '60px', backgroundColor: '#FFF', color: '#003DA5', textTransform: 'none' }} onClick={goToHome} disableRipple>
                        {t('centralBotao')}
                    </Button>
                </div>

                <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }} >
                    <div style={{ width: '100%', margin: '0em 4em', padding: '2em', backgroundColor: '#FFF', boxShadow: '0px 4px 4px #00000033', borderRadius: '4px' }}>
                        <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                            <div>
                                <h4 style={{ color: '#003DA5', fontWeight: '600' }}>
                                    {t('tituloSessao')}
                                </h4>
                                <p style={{ color: '#5F5F7D', fontStyle: 'italic', fontSize: '14px' }}>
                                    {t('descricaoSessao')}
                                </p>
                            </div>
                            {/* <p style={{ color: '#5F5F7D', fontStyle: 'italic', fontSize: '14px', textAlign: 'end' }} >Você pode ser atendido por outro formulário <br /> acessando a <a href=''>Central de Serviços Serpro</a></p> */}
                        </div>
                        <hr style={{ margin: '0' }} />
                        <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                            <Box sx={{ width: '20%', padding: '30px 60px 10px' }}>
                                <Stepper nonLinear activeStep={activeStep}>
                                    <Step style={{ padding: '0' }}>
                                        <Button style={{ padding: '0px', textTransform: 'none', borderRadius: '0px' }} onClick={() => { getIdentification() }}>
                                            <div >
                                                <p style={{ color: '#5F5F7D', fontSize: '16px' }}>
                                                    {t('identificacao')}
                                                </p>
                                                {steps == 'identificacao' && <div style={{ width: '100%', height: '5px', backgroundColor: '#003DA5' }}></div>}
                                                {steps != 'identificacao' && <div style={{ width: '100%', height: '5px', backgroundColor: '#F4F4F4' }}></div>}
                                            </div>
                                        </Button>
                                    </Step>
                                    <Step>
                                        <Button disabled={formValid} style={{ padding: '0px', marginLeft: '10px', textTransform: 'none', borderRadius: '0px' }} onClick={() => { setSteps('descricao') }}>
                                            <div>
                                                <p style={{ color: '#5F5F7D', fontSize: '16px' }}>
                                                    {t('descricao')}
                                                </p>
                                                {steps == 'descricao' && <div style={{ width: '100%', height: '5px', backgroundColor: '#003DA5' }}></div>}
                                                {steps != 'descricao' && <div style={{ width: '100%', height: '5px', backgroundColor: '#F4F4F4' }}></div>}
                                            </div>
                                        </Button>
                                    </Step>
                                </Stepper>
                            </Box>
                            <p style={{ margin: '30px 60px 10px 0px', fontStyle: 'italic', color: '#5F5F7D' }}>
                                {t('preenchimento')}
                            </p>
                        </div>


                        <Stack sx={{ width: '100%', padding: '20px 60px 0px', marginTop: '20px' }} spacing={2}>
                            <Snackbar style={{ position: 'relative', left: '0', zIndex: '0' }} open={openAlertError}>
                                <Alert style={{ backgroundColor: '#ffc9c9' }} severity="error">{erroMessage}</Alert>
                            </Snackbar>
                        </Stack>

                        <div style={{ width: '100%', padding: '0px 60px 10px' }}>

                            {steps == 'identificacao-internacional' &&
                                <IdentificacaoInternatialV2
                                    setAlertMessage={controllerErrorAlert}
                                    setControllerBackdrop={controllerBackdrop}
                                />
                            }

                            {steps == 'identificacao' &&
                                <IdentificacaoV2
                                    setAlertMessage={controllerErrorAlert}
                                    setControllerBackdrop={controllerBackdrop}
                                    setFormValidButton={setFormValid}
                                />
                            }

                            {steps == 'descricao' &&
                                <DescricaoV2
                                    offers={offerOfcatalog}
                                    setFieldsDescription={setFieldsDescriptionValue}
                                    setAlertMessage={controllerErrorAlert}
                                    setControllerBackdrop={controllerBackdrop}
                                    setGenerateGenericDialog={generateGenericDialog}
                                    callbackImage={callbackImage}
                                />
                            }

                            <hr style={{ margin: '40px 0' }} />
                            <div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', marginTop: '10px' }}>
                                <div>
                                    <Button startIcon={<DeleteSweepIcon />} style={{ margin: '0', padding: '5px 20px', border: '1px solid #E7E7E7', borderRadius: '60px', backgroundColor: '#FFF', color: '#003DA5', textTransform: 'none', }} disableRipple onClick={() => { window.location.reload() }}>
                                        {t('limparFormBotao')}
                                    </Button>
                                </div>
                                <div>
                                    <Button style={{ margin: '0', padding: '5px 20px', border: '1px solid #E7E7E7', borderRadius: '60px', backgroundColor: '#FFF', color: '#003DA5', textTransform: 'none', }} disableRipple onClick={() => { window.location.reload() }}>
                                        {t('cancelarBotao')}
                                    </Button>
                                    {formIdContext.newUser && <Button style={{ marginLeft: '25px', padding: '5px 20px', borderRadius: '60px', backgroundColor: '#003DA5', color: '#FFF', textTransform: 'none', }} disableRipple onClick={() => { registerUserController() }}>
                                        {t('cadastrarBotao')}
                                    </Button>}
                                    {(!formIdContext.newUser && (steps == 'identificacao' || steps == 'identificacao-internacional')) && <Button style={{ marginLeft: '25px', padding: '5px 20px', borderRadius: '60px', backgroundColor: '#003DA5', color: '#FFF', textTransform: 'none', }} disableRipple onClick={() => { handleNext() }}>
                                        {t('avancarBotao')}
                                    </Button>}
                                    {(!formIdContext.newUser && steps == 'descricao') && <Button style={{ marginLeft: '25px', padding: '5px 20px', borderRadius: '60px', backgroundColor: '#003DA5', color: '#FFF', textTransform: 'none', }} disableRipple onClick={(e) => { finalUpdate(e) }}>
                                        {t('enviarBotao')}
                                    </Button>}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Dialog
                    className={classes.modalBackdrop}
                    open={backdrop}
                    disableEscapeKeyDown
                    fullScreen
                >
                    <Backdrop className={classes.backdrop} open={backdrop}>
                        <CircularProgress color="inherit" />
                        <span className={classes.textBackdrop}>{textBackdrop}</span>
                    </Backdrop>
                </Dialog>

                <Dialog
                    open={genericDialog}
                    onClose={disableBackDrop}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                    fullWidth='true'
                >
                    <DialogTitle style={{ color: '#5F5F7D' }}>
                        {genericDialogTitle}
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText >
                            {genericDialogDescription}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        {typeButton == 'default' && <Button style={{ margin: '16px 24px', padding: '5px 20px', borderRadius: '60px', backgroundColor: '#003DA5', color: '#FFF', textTransform: 'none', }} onClick={() => setGenericDialg(false)} disableRipple>{genericDialogButton}</Button>}
                        {typeButton == 'reload' && <Button style={{ margin: '16px 24px', padding: '5px 20px', borderRadius: '60px', backgroundColor: '#003DA5', color: '#FFF', textTransform: 'none', }} onClick={() => resetForm()} disableRipple>{genericDialogButton}</Button>}
                        {typeButton == 'redirect' && <Button style={{ margin: '16px 24px', padding: '5px 20px', borderRadius: '60px', backgroundColor: '#003DA5', color: '#FFF', textTransform: 'none', }} onClick={() => redirectSingleAcess()} disableRipple>{genericDialogButton}</Button>}
                    </DialogActions>
                </Dialog>

                <TermosV2 openDialog={openDialogTerms} onCloseDialog={handleCloseDialogTerms}></TermosV2>

                <Dialog
                    open={openDialogError}
                    onClose={handleCloseDialogError}
                    maxWidth="md"
                >
                    <Error
                        content={<p> O sistema de Gestão de Atendimento da Central de Serviços SERPRO (CSS) está em manutenção temporariamente.
                            <br />Por gentileza, <a href={REACT_CONTINGENCY} target="_blank">clique aqui</a> para acessar o formulário de contingência.</p>} />
                </Dialog>
            </seciton>
        </>
    )
}

export default ScreenSection;

const useStyles = makeStyles((theme) => ({
    modalBackdrop: {
        opacity: '0.5',
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
        display: 'flex',
        flexDirection: 'column'
    },
    textBackdrop: {
        marginTop: '20px',
        fontSize: '16px',
    }
}));