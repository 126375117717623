import React, { createContext, useContext, useEffect, useState } from "react";
import { GvpGetAuth } from "../../service/GlobalService";

export const FormIdContext = createContext({});

export function FormIdProvider({ children }) {

    const [formIdContext, setFormIdContext] = useState({});

    const setFormIdContextAdd = (object) => {
        setFormIdContext(Object.assign(formIdContext, object));
    }

    return (
        <FormIdContext.Provider
            value={{
                formIdContext,
                setFormIdContext,
                setFormIdContextAdd
            }}
        >
            {children}
        </FormIdContext.Provider>
    )
}

export function useFormId() {
    const context = useContext(FormIdContext);
    const { formIdContext, setFormIdContext, setFormIdContextAdd } = context;
    return { formIdContext, setFormIdContext, setFormIdContextAdd }
}