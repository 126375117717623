/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { useHistory } from "react-router-dom";

import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { AlertError, ButtonContainer } from '../../screens/form/FormStyle';
import SerproFormInput from '../serproforminput/SerproFormInput';

export default function SecurityCode(props) {

  const { openDialog, onCloseDialog, formIdContext } = props;
  const classes = useStyles();
  const history = useHistory();

  /** Controles de tela */
  const [backdrop, setBabackdrop] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [, setValidFormFields] = useState(false);
  const [hiddenBtn, setHiddenBtn] = useState(true);
  const [securityCode, setSecurityCode] = useState('');
  //const [validaForm, setValidaForm] = useState(false);

  const arrayRequiredFields = [
    securityCode
  ]

  const initialFieldsData = () => {
    setSecurityCode('')
  }

  const initialViewControls = () => {
    setErrorMessage('')
    setBabackdrop(false)
  }

  function validateFormByField() {
    if (arrayRequiredFields.length === 0 || arrayRequiredFields[0] === {}) return true

    const keys = Object.keys(arrayRequiredFields)
    const isValidForm = keys.every(value => arrayRequiredFields[value])

    return isValidForm
  }

  const handleClose = (e, isValid = false) => {
    initialFieldsData();
    initialViewControls();
    setBabackdrop(false);

    return onCloseDialog({
      securityCode: securityCode
    });
  };

  const SecurityCode = () => {
    //Request SecurityCode
    return securityCode === '123' ? true : false;
  }

  const handleConfirmar = () => {
    const formIsValidate = validateFormByField(arrayRequiredFields);
    if (!formIsValidate) {
      setErrorMessage('Código de Segurança inválido');
      return setBabackdrop(false);
    }

    setValidFormFields(formIsValidate); 
    SecurityCode() ? handleClose() : setErrorMessage('Código de Segurança inválido'); setHiddenBtn(false);
  }

  const handleSend = () => {
    window.location.reload();
  }

  const handleUpdate = () => {   
    handleClose();
    formIdContext.numberCatalog = 3379;
    history.push("/upperScreenForm/3379/");
  }

  const getSecurityCode = async (value) => {
    setSecurityCode(value);
    return setBabackdrop(false);
  }

  return (
    <>
      <Dialog
        open={openDialog}
        onClose={onCloseDialog}
        disableEscapeKeyDown={true}
        disableBackdropClick={true}
        maxWidth="md"
        fullWidth={false}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >

        <DialogTitle id="alert-dialog-title">
          <Box className={classes.modalTitle}>{"Validação do Usuário"}</Box>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
              <SerproFormInput
                name="Digite o Código de Segurança:"
                value={securityCode}
                onChange={(e) => getSecurityCode(e.target.value)}
                error
              />
            {errorMessage && <AlertError>{errorMessage}</AlertError>}
        </DialogContentText>

       </DialogContent>
      <DialogActions className={classes.boxInfoRequired}>
        <ButtonContainer>
            <Button
              onClick={handleConfirmar}
              color="primary"
              variant="contained"
              hidden={!hiddenBtn}
              className={classes.buttonWidthDefault}
            >
              CONFIRMAR
            </Button>
        </ButtonContainer>
        <ButtonContainer>
            <Button
              onClick={handleSend}
              color="primary"
              variant="contained"
              hidden={hiddenBtn}
              className={classes.buttonWidthDefault}
            >
              CONTINUAR
            </Button>
          </ButtonContainer>
          <ButtonContainer>
            <Button
              onClick={handleUpdate}
              color="default"
              variant="contained"
              hidden={hiddenBtn}
              className={classes.buttonWidthDefault}
            >
              ALTERAR CADASTRO
            </Button>
          </ButtonContainer>
        </DialogActions>
        <Backdrop className={classes.backdrop} open={backdrop}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Dialog>
    </>
  )

}

SecurityCode.prototype = {
  openDialog: PropTypes.bool,
  onCloseDialog: PropTypes.func,
}

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  buttonWidthDefault: {
    width: '250px',
  },
  boxInfoRequired: {
    display: 'grid',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'end',
    padding: '30px',
    marginBottom: '0',
    marginTop: '-30px',
    fontSize: '10pt',
    fontWeight: 'bold',
    marginLeft: '15px'
  },
  modalTitle: {
    fontSize: '25px',
    fontWeight: 'bold',
  }
}));
