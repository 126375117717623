import React, { useEffect } from 'react'
import {
    Backdrop,
    CircularProgress,
    Dialog,
    makeStyles
} from '@material-ui/core';
import { BackdropText } from '../upperscreenform/UpperScreenForm.style';

function RedirectSingleAccess() {

    const classes = useStyles();

    useEffect(() => {
        window.location = process.env.REACT_APP_FORM_SCCD_KEY_CLOACK
    })

    return (
        <div>
            <Dialog
                className={classes.modalBackdrop}
                open={true}
                disableEscapeKeyDown
                fullScreen
            >
                <Backdrop className={classes.backdrop}>
                    <CircularProgress color="inherit" />
                    <span className={classes.textBackdrop}>Estamos te redirecionando para o Login Único, por favor aguarde .....</span>
                </Backdrop>
            </Dialog>
        </div>
    )

}


export default RedirectSingleAccess

const useStyles = makeStyles((theme) => ({
    modalBackdrop: {
        opacity: '0.5',
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
        display: 'flex',
        flexDirection: 'column'
    },
    textBackdrop: {
        marginTop: '20px'
    }

}));