import React from 'react'
import PropTypes from 'prop-types'

function SerproFormTextArea(props) {
  const {
    name,
    type,
    placeholder,
    value,
    onChange,
    disabled,
    inputStyle,
    id,
    divClass,
    maxLength,
  } = props

  const divClassParams = 'form-group ' + divClass

  return (
    <div className={divClassParams}>
      <label>{name}</label>
      <textarea
        className="form-control"
        id={id}
        type={type}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        disabled={disabled}
        style={inputStyle}
        maxLength={maxLength}
      />
    </div>
  )
}

export default SerproFormTextArea

SerproFormTextArea.propTypes = {
  divClass: PropTypes.string,
  name: PropTypes.string,
  id: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  inputStyle: PropTypes.object,
  style: PropTypes.object,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  maxLength: PropTypes.number,
}
