import React, { useContext, useState, useEffect } from 'react'
import PropTypes, { func } from 'prop-types'

import { FormIdContext } from '../FormIdContext';
import { GvpGetUser } from '../../../service/GlobalService';
import { MOUNT_CEP } from '../../../service/external/cep';
import ClearGlobalMessage from '../../../utils/ClearGlobalMessage';
import {
  ADICIONA_MASK_CPF,
  REMOVE_MASK_CEP,
  REMOVE_MASK_CNPJ,
  REMOVE_MASK_CPF,
  REMOVE_MASK_TELEFONE,
  VALIDAR_CPF,
} from '../../../utils/ValidationForm';

import OtherUser from '../../../components/OtherUserModal/OtherUserModal';
import { SerproFormInput } from '../../../components';
import { InfoRelator, OtherUserContainer } from '../FormStyle';

import { Backdrop, Button, CircularProgress, Dialog, makeStyles } from '@material-ui/core';
import { SerproAlert } from '../../../components';
import { TYPE_ALERT } from '../../../components/serproalert/SerproAlert';
import SecurityCode from '../../../components/SecurityCodeModal/SecurityCodeModal';
import CertificateModal from '../../../components/CertificateModal/CertificateModal';

function IdentificacaoForm(props) {
  const classes = useStyles();
  const { setFieldsIdentification,
    setGlobalMessage,
    setCpfDisable,
    setCnpjDisabled } = props;
  const formIdContext = useContext(FormIdContext);

  const [obj,] = useState({
    id: '',
    cpf: '',
    name: '',
    sobrenome: '',
    email: '',
    cep: '',
    logradouro: '',
    endNumero: '',
    complemento: '',
    bairro: '',
    cidade: '',
    uf: '',
    ddd: '',
    telefone: '',
    ramal: '',
    alertEmail: '',
    alertUser: true,
    hasUserToEdit: false,
    newUser: false,
    groups: '',
    affectedBy: ''
  })

  const [backdrop, setBackdrop] = useState(false);
  const [, setErrorSearchCep] = useState(false);
  const [, setHasUserToEdit] = useState(false);
  const [viewOtherUserOption, setViewOtherUserOption] = useState(false);
  const [openDialogOtherUser, setOpenDialogOtherUser] = useState(false);
  const [isAtendimentoOutroUsuario, setIsAtendimentoOutroUsuario] = useState(false);

  const [validaForm, setValidaForm] = useState(false);
  const [openDialogSecurityCode, setOpenDialogSecurityCode] = useState(false);
  const [fieldDisabled, setFieldDisabled] = useState(true);
  const [fieldDisabledCep, setFieldDisabledCep] = useState(true);

  const [cnpj, setCnpj] = useState('');
  const [, setUserId] = useState('');
  const [cpf, setCpf] = useState('');
  const [name, setName] = useState('');
  const [, setSobrenome] = useState('');
  const [email, setEmail] = useState('');
  const [cep, setCep] = useState('');
  const [logradouro, setLogradouro] = useState('');
  const [endNumero, setEndNumero] = useState('');
  const [complemento, setComplemento] = useState('');
  const [bairro, setBairro] = useState('');
  const [cidade, setCidade] = useState('');
  const [uf, setUf] = useState('');
  const [ddd, setDdd] = useState('');
  const [telefone, setTelefone] = useState('');
  const [ramal, setRamal] = useState('');
  const [dadosRelator, setDadosRelator] = useState({});
  const [, setDadosOutroUsuario] = useState({});
  const [nameMask, setNameMask] = useState('');
  const [nameMaskSolicitante, setNameMaskSolicitante] = useState('');
  const [openDialogCertificate, setOpenDialogCertificate] = useState(false);
  const [getCpfVal, setGetCpfVal] = useState(true);
  const [consultUserSa, setConsultUserSa] = useState(true);
  const [textBackdrop, setTextBackdrop] = useState('');

  useEffect(() => {

    if (formIdContext.saUser && consultUserSa) {

      if (formIdContext.cnpjRequiredFormWeb) {
        if (formIdContext.saUserCnpj) {
          setCnpjValue(formIdContext.saUserCnpj);
          setCpfValue(formIdContext.saUser);
          getCpf(formIdContext.saUser);
          setConsultUserSa(false);
        } else {
          SerproAlert.showDialogBlock(
            'Atenção',
            "Para acessar este formulário é necessário ter um CNPJ vinculado ao seu usuário no SCCD. Entre em contato com seu administrador.",
            TYPE_ALERT.WORN,
            () => {
              window.location =  `${process.env.REACT_APP_FORM_ZDYNAMIC_HOME}SingleAccess`
            },
            false,
            false
          );
        }
      } else {
        setCpfValue(formIdContext.saUser);
        getCpf(formIdContext.saUser);
        setConsultUserSa(false);
      }
    }

  });

  const handleCloseDialogCertificate = (data) => {
    setOpenDialogCertificate(false);
  };

  const getCpf = async (value) => {

    setGetCpfVal(false);
    setValidaForm(false);
    setGlobalMessage(ClearGlobalMessage);
    setDadosRelator({});
    setIsAtendimentoOutroUsuario(false);

    let cpfConst = REMOVE_MASK_CPF(value)

    setCpfValue(cpfConst);
    setHasUserToEditValue(false);
    setFieldsIdentification(obj);

    if (cpfConst.length !== 11) {
      return
    }

    if (!VALIDAR_CPF(cpfConst)) {
      setGlobalMessage({
        message: 'O CPF informado é inválido',
        show: true,
      });
      return;
    }
    
    setTextBackdrop('Aguarde um instante, estamos verificando seu cadastro.');
    setBackdrop(true);

    GvpGetUser(cpfConst, formIdContext.numberCatalog)
      .then((response) => {
        const data = response.data;

        if (data?.id) {
          const offerGroups = data.catalog.offerGroups;

          obj.groups = offerGroups?.join('::');

          obj.newUser = false;

          setCpfValue(cpfConst);
          setUserIdValue(data?.id);
          setNameValue(data.nome, data.sobrenome);
          setSobrenomeValue(data.sobrenome);
          setEmailValue(data.email);
          setLogradouroValue(data.enderec?.addresscomplete);
          setEndNumeroValue(data.enderec?.number);
          setNeighborhoodValue(data.enderec?.neighborhood);
          setComplementoValue('')
          setUfValue(data.enderec?.state);
          setMunicipioValue(data.enderec?.city);
          setTelefoneValue(data.phone?.number);
          setDddValue(data.phone?.ddd);
          setCep(data.enderec?.zipcode);
          setHasUserToEditValue(true);
          obj.hasUserToEdit = true;

          setFieldDisabled(true);
          setFieldsIdentification(obj);

          setViewOtherUserOption(true);

          formIdContext.in21 ? setValidaForm(true) : setValidaForm(false);

          setBackdrop(false);
        } else {
          obj.cpf = '';

          if (data === 'semcadastro') {
            obj.newUser = true;
            setValidaForm(true);

            setCpfValue(cpfConst);
            setUserIdValue('');
            setNameValue('', '');
            setEmailValue('');
            setLogradouroValue('');
            setEndNumeroValue('');
            setNeighborhoodValue('');
            setComplementoValue('');
            setUfValue('');
            setMunicipioValue('');
            setTelefoneValue('');
            setDddValue('');
            setCepValue('');
            setFieldDisabled(false);
            setFieldDisabledCep(false);

            setFieldsIdentification(obj);

            setGlobalMessage({
              message: 'Usuário não cadastrado, preencha o formulário.',
              show: true,
            })

            return setBackdrop(false);
          }
        }
      }).catch(() => {

        setCpfValue('');
        setUserIdValue('');
        setNameValue('', '');
        setEmailValue('');
        setLogradouroValue('');
        setEndNumeroValue('');
        setNeighborhoodValue('');
        setComplementoValue('');
        setUfValue('');
        setMunicipioValue('');
        setTelefoneValue('');
        setDddValue('');
        setCepValue('');
        setFieldDisabled(true);

        setFieldsIdentification(obj);

        setGlobalMessage({
          message: 'Ocorreu um erro ao buscar os dados para o CPF informado, por favor tente novamente mais tarde',
          show: true,
        });

        setFieldsIdentification(obj);
        setBackdrop(false);
      })
  }

  const setCpfValue = (e) => {
    setCpf(e)
    obj.cpf = e
    setFieldsIdentification(obj);
  }

  const setUserIdValue = (e) => {
    setUserId(e)
    obj.id = e
    setFieldsIdentification(obj);
  }

  const setNameValue = (fisrtname, sobrenome) => {
    setName(`${fisrtname} ${sobrenome}`);
    obj.name = `${fisrtname} ${sobrenome}`;
    setFieldsIdentification(obj);
  }

  const setJustNameValue = (name) => {
    setName(`${name}`);
    obj.name = name;
    setFieldsIdentification(obj);
  }

  const setSobrenomeValue = (e) => {
    setSobrenome(e)
    obj.sobrenome = e
    setFieldsIdentification(obj);
  }

  const setCepValue = async (e) => {
    if (e != null) {
      setCep(e);

      let cep = "" + e;
      obj.cep = REMOVE_MASK_CEP(e);

      cep = cep.replace(/[\s_.-]*/gim, '');

      if (cep.length !== 8) {
        return;
      }

      if (cep.length === 8) {

        setTextBackdrop('Aguarde um instante, estamos consultando o endereço.');
        setBackdrop(true);

        MOUNT_CEP(cep)
          .then(response => response.data)
          .then(response => {
            if (response.erro) {
              clearAddressFields();
              return setGlobalMessage({
                message: 'CEP inválido',
                show: true,
              });
            }
            setGlobalMessage(ClearGlobalMessage);

            setLogradouroValue(response.logradouro);
            setUfValue(response.uf);
            setNeighborhoodValue(response.bairro);
            setDddValue(response.ddd);
            setMunicipioValue(response.localidade);

            setBackdrop(false);
          })
          .catch(error => {
            console.error(error);

            setGlobalMessage({
              message: 'Ocorreu um erro ao buscar os dados para o CEP informado, por favor tente novamente mais tarde',
              show: true,
            });

            setErrorSearchCep(true);
            setBackdrop(false);
          });
      }
      else {
        clearAddressFields();
      }
    }
  }

  const clearAddressFields = () => {
    setLogradouroValue('')
    setComplementoValue('')
    setUfValue('')
    setNeighborhoodValue('')
    setDddValue('')
    setMunicipioValue('')
    setBackdrop(false)
  }

  const setLogradouroValue = (e) => {
    setLogradouro(e)
    obj.logradouro = e
    setFieldsIdentification(obj);
  }

  const setEndNumeroValue = (e) => {
    setEndNumero(e)
    obj.endNumero = e
    setFieldsIdentification(obj);
  }

  const setComplementoValue = (e) => {
    setComplemento(e)
    obj.complemento = e
    setFieldsIdentification(obj);
  }

  const setNeighborhoodValue = (e) => {
    setBairro(e)
    obj.bairro = e
    setFieldsIdentification(obj);
  }

  const setUfValue = (e) => {
    setUf(e)
    obj.uf = e
    setFieldsIdentification(obj);
  }

  const setMunicipioValue = (e) => {
    setCidade(e)
    obj.cidade = e
    setFieldsIdentification(obj);
  }

  const setEmailValue = (email) => {
    setEmail(email)
    obj.email = email
    setFieldsIdentification(obj);
  }

  const setHasUserToEditValue = (e) => {
    setHasUserToEdit(e);
    obj.hasUserToEdit = e
  }

  const setDddValue = (e) => {
    setDdd(e)
    obj.ddd = e
    setFieldsIdentification(obj);
  }

  const setTelefoneValue = (e) => {
    if (e != null) {
      setTelefone(e);
      obj.telefone = REMOVE_MASK_TELEFONE(e);
      setFieldsIdentification(obj);
    }
  }

  const setRamalValue = (e) => {
    setRamal(e)
    obj.ramal = e
    setFieldsIdentification(obj);
  }

  const setCnpjValue = (e) => {
    setCnpj(e)
    obj.cnpj = e
    setFieldsIdentification(obj);
  }

  const handleOpenDialog = () => {
    setOpenDialogOtherUser(true);
  };

  const handleCloseDialog = (data) => {
    setOpenDialogOtherUser(false);
    setDadosRelator({});
    setDadosOutroUsuario({});

    if (data.isOutroUsuario) {
      setDadosRelator({
        cpf: ADICIONA_MASK_CPF(cpf),
        name,
        email,
        cep,
        logradouro,
        endNumero,
        complemento,
        bairro,
        cidade,
        uf,
        ddd,
        telefone,
        ramal
      });

      setCpf(data.cpfOutroUsuario);
      setNameMask(data.nomeDoSolicitanteOutroUsuario);
      setEmail(data.emailOutroUsuario);
      setCep(data.cepOutroUsuario);
      setLogradouro(data.logradouroOutroUsuario);
      setEndNumero(data.numeroLogradouroOutroUsuario);
      setComplemento(data.complementoOutroUsuario);
      setBairro(data.bairroOutroUsuario);
      setCidade(data.cidadeOutroUsuario);
      setUf(data.ufOutroUsuario);
      setDdd(data.dddOutroUsuario);
      setTelefone(data.telefoneOutroUsuario);
      setRamal(data.ramalOutroUsuario);

      setDadosOutroUsuario(data);

      obj.affectedBy = data.cpfOutroUsuario;

      setNameMaskSolicitante(nameMask);

      setFieldsIdentification(obj);
    }

    setIsAtendimentoOutroUsuario(data.isOutroUsuario);
  };

  const handleOpenDialogSecurityCode = () => {
    setOpenDialogSecurityCode(true);
  };

  const handleCloseDialogSecurityCode = (data) => {
    setOpenDialogSecurityCode(false);
  };

  return (
    <>
      <p></p>

      {isAtendimentoOutroUsuario && (
        <p>
          <InfoRelator>
            <b>Relatado por:</b>
            <span>{dadosRelator.cpf}</span> {nameMaskSolicitante ? nameMaskSolicitante : dadosRelator.name}
          </InfoRelator>
        </p>
      )}

      <div className="form-row">
        <SerproFormInput
          divClass="col-md-3"
          name="CPF*"
          mask="999.999.999-99"
          value={cpf}
          disabled={setCpfDisable || formIdContext.saUser}
          onChange={(e) => getCpf(e.target.value)}
          error
        />

        {nameMask !== '' ? (
          <SerproFormInput
            divClass="col-md-9"
            name="Nome do Solicitante*"
            value={nameMask}
            disabled={fieldDisabled}
            onChange={(e) => setNameValue(e.target.value)}
            error
            maxlength={100}
          />
        ) : (
          <SerproFormInput
            divClass="col-md-9"
            name="Nome do Solicitante*"
            disabled={fieldDisabled}
            value={name}
            onChange={(e) => setJustNameValue(e.target.value)}
            error
            maxlength={100}
          />
        )}
      </div>

      {validaForm && (
        <>
          <div className="form-row">
            <SerproFormInput
              divClass="col-md-2"
              name="CEP"
              mask="99.999-999"
              value={cep}
              disabled={fieldDisabledCep}
              onChange={(e) => setCepValue(e.target.value)}
            />
            <SerproFormInput
              divClass="col-md-3"
              disabled={fieldDisabled}
              name="Endereço/ logradouro"
              value={logradouro}
              maxLength={55}
              onChange={(e) => setLogradouroValue(e.target.value)}
            />
            <SerproFormInput
              divClass="col-md-2"
              name="Número"
              mask="9999999"
              value={endNumero}
              disabled={fieldDisabledCep}
              onChange={(e) => setEndNumeroValue(e.target.value)}
            />
            <SerproFormInput
              divClass="col-md-5"
              name="Complemento"
              value={complemento}
              disabled={fieldDisabledCep}
              onChange={(e) => setComplementoValue(e.target.value)}
            />
          </div>
          <div className="form-row">
            <SerproFormInput
              divClass="col-md-4"
              disabled={fieldDisabled}
              name="Bairro"
              value={bairro}
              onChange={(e) => setNeighborhoodValue(e.target.value)}
            />
            <SerproFormInput
              divClass="col-md-2"
              disabled={fieldDisabled}
              name="UF"
              mask="aa"
              type="select"
              value={uf}
              onChange={(e) => setUfValue(e.target.value)}
            />
            <SerproFormInput
              divClass="col-md-6"
              disabled={fieldDisabled}
              name="Município"
              value={cidade}
              onChange={(e) => setMunicipioValue(e.target.value)}
            />
          </div>
          <div className="form-row">
            <SerproFormInput
              divClass="col-md-6"
              name="Email*"
              value={email}
              disabled={!obj.newUser}
              onChange={(e) => setEmailValue(e.target.value)}
              /* onBlur={(e) => verifyEmail(e.target.value)} */
              maxlength={100}
            />
            <SerproFormInput
              divClass="col-md-2"
              name="DDD*"
              mask="99"
              value={ddd}
              disabled={fieldDisabled}
              onChange={(e) => setDddValue(e.target.value)}
            />
            <SerproFormInput
              divClass="col-md-2"
              name="Telefone*"
              mask="99999-9999"
              value={telefone}
              disabled={fieldDisabledCep}
              onChange={(e) => setTelefoneValue(e.target.value)}
            />
            <SerproFormInput
              divClass="col-md-2"
              name="Ramal"
              mask="99999999999"
              value={ramal}
              disabled={fieldDisabled}
              onChange={(e) => setRamalValue(e.target.value)}
            />
          </div>
        </>
      )}

      {formIdContext.cnpjFormWeb && (
        <div style={{ marginLeft: '-15px' }}>
          <SerproFormInput
            divClass="col-md-3"
            name={formIdContext.cnpjRequiredFormWeb ? 'CNPJ *' : 'CNPJ'}
            type='text'
            mask='99.999.999/9999-99'
            value={cnpj}
            onChange={e => setCnpjValue(REMOVE_MASK_CNPJ(e.target.value))}
            disabled={setCnpjDisabled || formIdContext.saUserCnpj}
          />
        </div>
      )}

      {viewOtherUserOption && !localStorage.getItem("cpfDisable") && (
        <>
          <OtherUserContainer>
            <span>Clique no botão abaixo caso o atendimento seja para outro usuário:</span>
            <Button
              size="small"
              variant="contained"
              fullWidth={false}
              onClick={handleOpenDialog}
            >
              ATENDIMENTO PARA OUTRO USUÁRIO
            </Button>
            {/* Caso necessite de atendimento para outro usuário, solicitamos a gentileza de encaminhar 
          um e-mail para css.serpro@serpro.gov.br ou ligue para o número 08007282323 */}
          </OtherUserContainer>

          <OtherUser openDialog={openDialogOtherUser} onCloseDialog={handleCloseDialog}></OtherUser>
        </>
      )}

      <SecurityCode openDialog={openDialogSecurityCode}
        onCloseDialog={handleCloseDialogSecurityCode}
        formIdContext={formIdContext}>
      </SecurityCode>

      <CertificateModal openDialog={openDialogCertificate}
        onCloseDialog={handleCloseDialogCertificate}
        formIdContext={formIdContext}>
      </CertificateModal>

      <Dialog
        className={classes.modalBackdrop}
        open={backdrop}
        disableBackdropClick
        disableEscapeKeyDown
        fullScreen
      >
        <Backdrop className={classes.backdrop} open={backdrop}>
          <CircularProgress color="inherit" />
          <span className={classes.textBackdrop}>{textBackdrop}</span>
        </Backdrop>
      </Dialog>
    </>
  )
}

export default IdentificacaoForm

IdentificacaoForm.prototype = {
  setFieldsIdentification: PropTypes.func,
  setGlobalMessage: PropTypes.func,
};

const useStyles = makeStyles((theme) => ({
  modalBackdrop: {
    opacity: '0.5',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
    display: 'flex',
    flexDirection: 'column'
  },
  textBackdrop: {
    marginTop: '20px'
  }
}));
