import React from 'react'
import PropTypes from 'prop-types'

function SerproFormSelect(props) {
  const { name, onChange, id, divClass, multiple, itemArray, onHidden, readOnly, value } = props

  const divClassParams = 'form-group ' + divClass

  const createSelectItems = () => {
    let items = []
    let conut = itemArray.length - 1
    for (let i = 0; i <= conut; i++) {
      var itemOptional = itemArray[i]
      items.push(<option value={itemOptional.id}>{itemOptional.name}</option>)
    }
    return items
  }

  return (
    <div hidden={onHidden} className={divClassParams}>
      <label>{name}</label>

      <select
        id={id}
        className="form-control"
        aria-label=".form-select-lg example"
        onChange={onChange}
        multiple={multiple}
        disabled={readOnly}

      >
        {readOnly && <option value="">{value}</option>}
        {!readOnly &&  <option value=""> Selecione... </option>}
        {createSelectItems()}
      </select>
    </div>
  )
}

export default SerproFormSelect

SerproFormSelect.propTypes = {
  name: PropTypes.string,
  divClass: PropTypes.string,
  itemArray: PropTypes.array,
  multiple: PropTypes.bool,
  onChange: PropTypes.func,
}
