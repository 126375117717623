import { zintegratorApi, apiSerpro, apiZerpro, apiUser, gvp, GetAuthTokenGvp, keycloakSerpro } from './index';

export const CPF_REQUEST_MASK = async (value, numberCatalog) => {
  const url = `/user/document/${value}/catalog/${numberCatalog}`;
  return zintegratorApi.get(url, {});
}

export const CREATE_USER = async (user) => {
  const url = '/common'
  return apiUser.post(url, user, {})
}

export const CREATE_USER_INTERNATIONAL = async (user) => {
  const url = '/international'
  return apiUser.post(url, user, {})
}

export const GET_USER = async (key, param) => {
  const url = `/?${key}=${param}`
  return apiUser.get(url, {})
}

/** https://api-zserpro.sandbox.stefanini.io/api/v1/serpro/zammad/tickets */
export const getUserSccd = async (cpf) => {
  const url = `/user/sccd/user/${cpf}`;
  return apiSerpro.get(url);
}

export const createTicketZammad = async (ticket) => {
  const url = `zammad/tickets`;
  return apiZerpro.post(url, ticket);
}

export const updateTicketZammad = async (ticket, id) => {
  const url = `zammad/tickets/${id}`;
  return apiZerpro.put(url, ticket);
}

export const ticketlevel2 = async (id) => {
  const url = `tickets/level2/`;
  return apiZerpro.post(url, { ticket: { id: id } });
}

/** DADOS DE UM CATALOGO 'https://api-zserpro.sandbox.stefanini.io/api/v1/zintegrator/catalog/:number_catalog' */
export const CatalogByNumber = async (number_catalog) => {
  const url = `/catalog/${number_catalog}`;
  return await zintegratorApi.get(url);
}

export const ListCatalog = async () => {
  const url = `/catalog/`;
  return await zintegratorApi.get(url);
}

/** LISTAR OFERTAS DE UM CATALOGO 'https://api-zserpro.sandbox.stefanini.io/api/v1/zintegrator/catalog/:number_catalog' */
export const OffersByNumberCatalog = async (number_catalog) => {
  const url = `/catalog/${number_catalog}/offers`;
  return await zintegratorApi.get(url);
}

/** DETALHAR OFERTAS DE UM CATALOGO 'https://api-zserpro.sandbox.stefanini.io/api/v1/zintegrator/catalog/:number_catalog/offer/:number_offer' */
export const DetailsOfferByNumberCatalog = async (number_catalog, number_offer) => {
  const url = `/catalog/${number_catalog}/offer/${number_offer}`;
  return await zintegratorApi.get(url);
}

export const getAuthZintegrator = async (code) => {
  const url = `/user/authenticform`;
  let config = {
    code: code
  }
  return await zintegratorApi.post(url, config);
}

export const getCatalogsForCpf = async (cpf) => {
  const url = `/user/${cpf}/catalogs`;
  return await zintegratorApi.get(url);
}

export const GvpGetAuth = async () => {
  let auth;
  await GetAuthTokenGvp.post().then((response) => {
    auth = response.data
  });

  const config = {
    headers: { Authorization: `Bearer ${auth}` }
  };
  return config
}

export const GvpGetUser = async (document, catalog) => {

  const url = `/api/user/GetUsuarioCPFV2?cpf=${document}&numero_catalogo=${catalog}&in21=false`
  return await gvp.get(url, await GvpGetAuth());
}

export const GvpCreateUser = async (user) => {
  const url = `/api/user/criarusuarioV2`
  return await gvp.post(url, user, await GvpGetAuth());
}

export const GvpCreateTicket = async (ticket) => {
  const url = `/api/Ticket/CriarTicketV2`
  return await gvp.post(url, ticket, await GvpGetAuth());
}

export const GvpUpdateTicket = async (ticket) => {
  const url = `/api/Ticket/AtualizarTicketV2`
  return await gvp.post(url, ticket, await GvpGetAuth());
}

export const GvpUpdateUserSCCD = async (idUser) => {
  const url = `/api/user/AtualizaUsuarioDialugoSCCD`
  return await gvp.post(url, idUser, await GvpGetAuth());
}

export const requestAuthSCCD = async (code) => {

  const requestConfig = new URLSearchParams({
    grant_type: 'authorization_code',
    code: code,
    client_id: process.env.REACT_APP_FORM_SCCD_ID,
    client_secret: process.env.REACT_APP_FORM_SCCD_SECRET,
    redirect_uri: process.env.REACT_APP_FORM_ZDYNAMIC_HOME
  });

  return await keycloakSerpro.post('/token', requestConfig.toString())
}

export const requestLogoutSCCD = async (code) => {

  const requestConfig = new URLSearchParams({
    grant_type: 'authorization_code',
    code: code,
    client_id: process.env.REACT_APP_FORM_SCCD_ID,
    client_secret: process.env.REACT_APP_FORM_SCCD_SECRET,
    redirect_uri: process.env.REACT_APP_FORM_ZDYNAMIC_HOME
  });

  return await keycloakSerpro.post('/logout', requestConfig.toString())
}



