export const TAM_CAMPO_NUMERICO = '99999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999999';

export const CEP_DEFAULT_RECALL = '79000000';

export const REACT_CONTINGENCY = "https://ams-zserpro-frontend-zcontigencyform.develop.stefanini.io/"

export const CATALOGS_MULTICLIENT = [
  '3187',
  '3205',
  '3217',
  '3277',
  '3223',
  '2214',
  '2986',
  '2985',
  '2897',
  '3152',
  '3446',
  '3447',
  '2562',
  '3253',
  '2199',
  '3267',
  '3191',
  '2241',
  '3167',
  '3256',
  '3211',
  '3137',
  '3236',
  '3238',
  '2353',
  '3434',
  '3058',
  '2922',
  '2750',
  '3404',
  '3376',
  '2753',
  '2173',
  '2990',
  '3245',
  '3434',
  '3502',
  '3505',
  '2998',
  '3239',
  '3240'
]

export const STATUS_TICKET_GVP_ENUM = {
  RASCUNHO:'772',
  EM_ATENDIMENTO:'769'
}