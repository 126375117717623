import React from 'react'
import PropTypes from 'prop-types'

function SerproFormSelectV2(props) {
    const { name, onChange, id, divClass, multiple, itemArray, onHidden, readOnly, value } = props

    const createSelectItems = () => {
        let items = []
        let conut = itemArray.length - 1
        for (let i = 0; i <= conut; i++) {
            var itemOptional = itemArray[i]
            items.push(<option value={itemOptional.id}>{itemOptional.name}</option>)
        }
        return items
    }

    return (
        <div hidden={onHidden} style={{ width: '100%' }}>
            <label style={{ color: '#003DA5', fontWeight: '600', marginBottom: '5px' }}>{name}</label>

            <select
                className='form-control-v2'
                style={{ width: '100%', padding:'0.7em' }}
                id={id}
                onChange={onChange}
                multiple={multiple}
                disabled={readOnly}

            >
                {readOnly && <option value="">{value}</option>}
                {!readOnly && <option value=""> Selecione... </option>}
                {createSelectItems()}
            </select>
        </div>
    )
}

export default SerproFormSelectV2

SerproFormSelectV2.propTypes = {
    name: PropTypes.string,
    divClass: PropTypes.string,
    itemArray: PropTypes.array,
    multiple: PropTypes.bool,
    onChange: PropTypes.func,
}
