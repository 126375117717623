/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { ButtonContainer } from '../../screens/form/FormStyle';
import { makeStyles } from '@material-ui/core/styles';

import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@material-ui/core';

export default function Termos(props) {

  const { openDialog, onCloseDialog } = props;
  const classes = useStyles();

  /** Controles de tela */
  const [backdrop, setBabackdrop] = useState(false);

  const handleClose = (e, isValid = false) => {
    setBabackdrop(false);
    return onCloseDialog({ });
  };

  return (
    <>
      <Dialog
        open={openDialog}
        onClose={onCloseDialog}
        disableEscapeKeyDown={false}
        disableBackdropClick={false}
        maxWidth="md"
        fullWidth={false}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >

        <DialogTitle id="alert-dialog-title">
          <Box className={classes.modalTitle}>{"Sobre cookies e os termos de uso da CSS"}</Box>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Para que esse formulário funcione corretamente, é necessário a criação de cookies (pequenos arquivos de dados) em seu dispositivo.
            <br />
            Além disso, é necessário que você concorde com os <a href="https://www.serpro.gov.br/menu/suporte/termos-de-uso-da-css.pdf" target="_blank">TERMOS DE USO</a> da Central de Serviços Serpro (CSS).
        </DialogContentText>

       </DialogContent>
        <DialogActions className={classes.boxInfoRequired}>
          <ButtonContainer>
              <Button
                onClick={handleClose}
                color="primary"
                variant="contained"
                className={classes.buttonWidthDefault}
              >
                CONCORDO COM A UTILIZAÇÃO DE COOKIES E COM OS TERMOS DE USO DA CSS
              </Button>
          </ButtonContainer>
        </DialogActions>
        <Backdrop className={classes.backdrop} open={backdrop}>
          <CircularProgress color="inherit" />
        </Backdrop>
      </Dialog>
    </>
  )

}

Termos.prototype = {
  openDialog: PropTypes.bool,
  onCloseDialog: PropTypes.func,
}

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  buttonWidthDefault: {
    width: '630px',
  },
  boxInfoRequired: {
    display: 'grid',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'end',
    padding: '30px',
    marginBottom: '0',
    marginTop: '-30px',
    fontSize: '10pt',
    fontWeight: 'bold',
    marginLeft: '15px'
  },
  modalTitle: {
    fontSize: '25px',
    fontWeight: 'bold',
  }
}));
