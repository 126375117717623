import styled from 'styled-components'

const Container = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-left: 0.1%;
  margin-top: 0.1%;
  box-shadow: 2px 2px 2px 2px rgba(0.2, 0.2, 0.2, 0.2);
  background-color: #fff;
  padding: 1%;
`

const Title = styled.h2`
  color: #595858;
  font-weight: bold;
  font-size: 22;
`

const Description = styled.p`
  margin-bottom: 2%;
  font-size: 12;
`

export { Container, Title, Description }
