import React, { useEffect, useState } from 'react'
import { Container, Title } from './UpperScreenHeaderContainer.style'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { FORMULARIO } from '../../utils/FormularioConst'
import { TYPE_ALERT } from '../../components/serproalert/SerproAlert';
import { SerproAlert } from '../../components';
import { ListCatalog, requestLogoutSCCD } from '../../service/GlobalService'
import { Button } from '@material-ui/core'
import LogoutIcon from '@mui/icons-material/Logout';
import { useFormId } from '../../screens/form/FormIdContext';

function UpperScreenHeaderContainer(props) {

  // useEffect(() => {
  //   if (formState.length === 0) {
  //     // getCatalog();
  //     console.log('get catalog');
  //   }

  // })

  const [formState, setFormState] = useState([]);

  const { title, catalog, description, code } = props

  const renderCell = () => {
    return catalog.map((item) => {
      const goTo = `/upperScreenForm/${item.numberCatalog}/`
      return (
        <tr key={item.id + item.phone + item.description + item.client}>
          <td style={{ width: '50%' }}>
            <Link to={goTo}>{item.description}</Link>
          </td>
          <td style={{ width: '30%' }}>{!item.client ? 'Cliente não cadastrado' : item.client}</td>
          <td style={{ width: '20%' }}>{!item.phone ? '--' : item.phone}</td>
        </tr>
      )
    })
  }

  const getCatalog = async () => {
    await ListCatalog().then((res) => {
      setFormState(res.data);
    })
  }

  const logoutAuth = () => {

    try {
      return requestLogoutSCCD(code).then(async (res) => {
        return res.data.id_token;
      }).catch((error) => {
        SerproAlert.showSimpleDialog(
          'Atenção',
          "Falha ao solicitar o logout, tente novamente!",
          TYPE_ALERT.WORN,
          () => {

          },
          false,
          false
        );
      });
    } catch (error) {
      console.log(error);
    }
  }

  // 2702

  return (
    <Container>
      <div style={{ marginLeft: '90px', marginRight: '90px' }}>
        <Title>{title}</Title>
        {description &&
          <>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              {description}
              {/* <Button color="primary" variant="text" size="small" onClick={logoutAuth}>
                Logout <LogoutIcon />
              </Button> */}
            </div>
          </>
        }

        {!description &&
          <label>
            Para solicitar atendimento clique no nome do serviço na lista
            apresentada abaixo. É possível utilizar o campo de busca, informando
            nome do serviço, cliente ou telefone. Você também pode acionar a CSS
            através do e-mail{' '}
            <a href="mailto:css.serpro@serpro.gov.br">css.serpro@serpro.gov.br</a>
            .
          </label>
        }


        <label>
          Tire suas dúvidas sobre a CSS acessando{' '}
          <a rel="noreferrer"
            href="https://www.serpro.gov.br/menu/contato/cliente/perguntas-frequentes/suporte/suporte-perguntas-frequentes"
            tabIndex="-1"
            target="_blank"
            role="button"
            aria-disabled="true"
          >
            Perguntas Frequentes
          </a>
          .
        </label>
        <br></br>
        <a rel="noreferrer"
          href="https://www.serpro.gov.br/menu/suporte/termos-de-uso-da-css.pdf"
          tabIndex="-1"
          target="_blank"
          role="button"
          aria-disabled="true"
        >
          Conheça os Termos de Uso da CSS.
        </a>
      </div>
      <div style={{ marginTop: '30px' }}>
        <table className="table" style={{ width: '80%', margin: '0 auto' }}>
          <thead>
            <tr>
              <th scope="col">Serviço</th>
              <th scope="col">Cliente(s)</th>
              <th scope="col">Telefone</th>
            </tr>
          </thead>
          <tbody>{renderCell()}</tbody>
        </table>
      </div>
      <>{props.children}</>
    </Container>
  )
}

export default UpperScreenHeaderContainer

UpperScreenHeaderContainer.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
}
