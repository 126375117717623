import styled from 'styled-components'

export const Container = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-left: 0.1%;
  margin-top: 0.1%;
  box-shadow: 2px 2px 2px 2px rgba(0.2, 0.2, 0.2, 0.2);
  background-color: #fff;
  padding: 1%;
  border-radius: 15px;
`

export const FlexRow = styled.span`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
`

export const FlexRowSaibaMais = styled.span`
  display: flex;
  flex-direction: row;
  justify-content: end;
`

export const FlexColumn = styled.span`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const Title = styled.h2`
  color: #595858;
  font-weight: bold;
  font-size: 22;
`

export const Description = styled.p`
  margin-bottom: 2%;
  font-size: 12;
`

export const InfoText = styled.span`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: end;
  font-size: 12pt;
  font-style: italic;
`

export const HighlightedWord = styled.span`
  font-size: 18pt;
  font-weight: bold; 
  font-style: normal;
`

export const OmniDescription = styled.p`
  text-align: right;
  font-size: 10pt;
  font-style: italic;
  width: 100%;
  margin: 0;
`

export const OmniMensege = styled.p`
  text-align: right;
  font-size: 10pt;
  font-style: italic;
  margin: 0;
`

export const OmniMensegeLink = styled.a`
  text-align: right;
  font-size: 10pt;
  font-style: italic;
  margin-left: 3px;
  cursor: pointer;
`