/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from 'react';
import {
  Switch,
  Route,
  useRouteMatch,
  useParams,
} from 'react-router-dom';

import { FormIdContext, useFormId } from './../form/FormIdContext';
import { CatalogByNumber } from './../../service/GlobalService';
import Form from '../form/Form.screen';
import SelectedFormContainer from '../../containers/selectedformcontainer/SelectedFormContainer';
import { BackdropText, PageNotFound } from './UpperScreenForm.style';
import {
  Backdrop,
  CircularProgress,
  Dialog,
  makeStyles
} from '@material-ui/core';
import Cookies from 'universal-cookie';
import Termos from '../../components/TermosModal/TermosModal';
import Error from '../../components/error/Error';
import { REACT_CONTINGENCY } from '../../utils/ConstDatas';
import { SerproHeader } from '../../components';

function UpperScreenForm() {

  const classes = useStyles();

  let match = useRouteMatch()
  let { id } = useParams()

  const [backdrop, setBackdrop] = useState(true);
  const [isValidForm, setIsValidForm] = useState(false);
  const [catalog, setCatalog] = useState({});
  const [errorSearchForm, setErrorSearchForm] = useState({ isError: false, message: '' });
  const [openDialogTerms, setOpenDialogTerms] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);
  const [in19, setin19] = useState(false);
  const { setFormIdContext, formIdContext } = useFormId();

  useEffect(async () => {


    const cookies = new Cookies();
    if (!cookies.get('termos_uso')) {
      cookies.set('termos_uso', true, { path: '/', maxAge: 8000000 });
      setOpenDialogTerms(true);
    }

    setBackdrop(true);

    CatalogByNumber(id)
      .then(response => {

        const catalog = response.data;

        if (catalog.autentication && !formIdContext.authorized)
          window.location = process.env.REACT_APP_FORM_SCCD_KEY_CLOACK

        if (catalog.in24)
          window.location = process.env.REACT_APP_FORM_PSERPRO + id;

        const validForm = catalog.status === 'ACTIVE' && catalog.numberCatalog === id;

        if (!catalog.in24 && catalog.in19) {
          window.location = process.env.REACT_APP_FORM_PSERPRO_INTER + id;
        }

        if (catalog.in27) // in27 verify
          window.location = `${process.env.REACT_APP_FORM_ZDYNAMIC_HOME}form-v2/${catalog.numberCatalog}/`

        if (!validForm) {
          setIsValidForm(false);
          setBackdrop(false);
          return setErrorSearchForm({
            isError: true,
            message: `Catálogo ${id} não disponível`,
          });
        }

        setCatalog(Object.assign({}, catalog, formIdContext));
        setIsValidForm(true);
        setBackdrop(false);
      })
      .catch(error => {
        setIsValidForm(false);
        setBackdrop(false);

        const { response } = error;

        if (response?.status === 404) {
          return setErrorSearchForm({
            isError: true,
            message: `Catálogo não encontrado`,
          });
        }
        else {
          setOpenDialog(true);
        }
      });
  }, [])

  const handleCloseDialogTerms = (data) => {
    setOpenDialogTerms(false);
  };

  const handleCloseDialog = (data) => {
    setOpenDialog(false);
  };

  return (
    <>
      <SerproHeader />
      <FormIdContext.Provider value={catalog}>
        {
          !isValidForm && (
            <SelectedFormContainer>
              <PageNotFound>
                {errorSearchForm.message}
              </PageNotFound>
            </SelectedFormContainer>
          )
        }

        {
          isValidForm && (
            <SelectedFormContainer
              title={isValidForm && 'Você está em'}
              description={isValidForm && 'Formulário > ' + catalog.description}
            >
              <Switch>
                <Route path={'/upperScreenForm/' + id} component={Form} />

                <Route path={match.path}>
                  <PageNotFound>
                    Catálogo não encontrado! Informe um número de catálogo válido
                  </PageNotFound>
                </Route>
              </Switch >
            </SelectedFormContainer>
          )
        }
        <Termos openDialog={openDialogTerms} onCloseDialog={handleCloseDialogTerms}></Termos>

        <Dialog
          open={openDialog}
          onClose={handleCloseDialog}
          maxWidth="md"
        >
          <Error
            content={<p> O sistema de Gestão de Atendimento da Central de Serviços SERPRO (CSS) está em manutenção temporariamente.
              <br />Por gentileza, <a href={REACT_CONTINGENCY} target="_blank">clique aqui</a> para acessar o formulário de contingência.</p>} />
        </Dialog>

        <Dialog
          className={classes.modalBackdrop}
          open={backdrop}
          disableBackdropClick
          disableEscapeKeyDown
          fullScreen
        >

          <Backdrop className={classes.backdrop} open={backdrop}>
            <CircularProgress color="inherit" />
            <span className={classes.textBackdrop}>Carregando formulário, por favor aguarde .....</span>
          </Backdrop>
        </Dialog>
      </FormIdContext.Provider>
    </>
  )
}

export default UpperScreenForm;

const useStyles = makeStyles((theme) => ({
  modalBackdrop: {
    opacity: '0.5',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
    display: 'flex',
    flexDirection: 'column'
  },
  textBackdrop: {
    marginTop: '20px'
  }
}));
