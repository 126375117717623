import Routes from './routes'
import { FormIdProvider } from './screens/form/FormIdContext'
import GlobalStyle from './styles/global'

function App() {
  return (
    <FormIdProvider>
      <GlobalStyle />
      <Routes />
    </FormIdProvider>
  )
}

export default App
