import React from 'react'
import PropTypes from 'prop-types'
import InputMask from 'react-input-mask'

function SerproFormInputV2(props) {
  const {
    name,
    placeholder,
    value,
    onChange,
    onBlur,
    disabled,
    inputStyle,
    id,
    divClass,
    maxlength,
    mask,
    beforeMaskedValueChange,
    title,
    hidden,
    readOnly,
    type,
    maskChar,
    styleDiv,
    exemple
  } = props

  const divClassParams = 'form-group ' + divClass

  return (
    <div style={styleDiv} className={divClassParams}>
      <label style={{ color: '#003DA5', fontWeight: '600', marginBottom: '5px' }} hidden={hidden}>{name}</label>
      <InputMask
        className="form-control form-control-v2"
        id={id}
        mask={mask}
        maskChar={maskChar}
        maxLength={maxlength}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        disabled={disabled}
        style={inputStyle}
        beforeMaskedValueChange={beforeMaskedValueChange}
        title={title}
        hidden={hidden}
        readOnly={readOnly}
        type={type}
      />
      {exemple && <p style={{ color: '#5F5F7D', fontStyle: 'italic', fontSize: '12px', marginBottom: '0px' }}>{exemple}</p>}
    </div>
  )
}

export default SerproFormInputV2

SerproFormInputV2.propTypes = {
  divClass: PropTypes.string,
  name: PropTypes.string,
  id: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  maskChar: PropTypes.string,
  value: PropTypes.string,
  inputStyle: PropTypes.object,
  style: PropTypes.object,
  maxlength: PropTypes.string,
  title: PropTypes.string,
  mask: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  beforeMaskedValueChange: PropTypes.func,
  disabled: PropTypes.bool,
  hidden: PropTypes.bool,
  readOnly: PropTypes.bool,
}
