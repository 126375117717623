import styled, { createGlobalStyle } from 'styled-components'

export default createGlobalStyle`
  * {
    margin:0;
    padding:0;
    outline:0;
    box-sizing: border-box;
  }
  html, body, #root{
    min-height: 100%;
  }
  body{
    -webkit-font-smoothing: antialiased !important
  }
  body, input, button{
    color:#222;
    font-size:14px;
    font-weight: normal;
    // font-family:'Roboto', sans-serif;
  }
  ul{
    list-style: none;
  }
  button{
    cursor: pointer;
  }

  :root {
    --bs-primary: #0d6efd;
  }

  .form-group {
    margin-bottom: 0px ;
  }

  .form-control-v2 {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #818181;
    opacity: 1;
    border-radius: 0px;
  }

  .form-control-v2:focus {
    background: #FFFFFF 0% 0% no-repeat padding-box;
    box-shadow: 0px 0px 5px #00000033;
    border: 1px solid #0075DD;
    opacity: 1;
  }

  #remove-arrow {
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    text-overflow: '';
  }
`

const ErrorMessage = styled.span`
  padding: 2% 2% 2% 4%;
  background-color: #FFD27C;
  font-size: 1rem;
  box-shadow: 2px 2px 2px 2px rgb(0 0 0 / 20%);
`

const AlertUser = styled.span`
  color: #5b5e09;
  margin-left: 2%;
`

const Loading = styled.span`
  color: #523be4;
  margin-left: 2%;
`

const Sucess = styled.span`
  color: #3be457;
  margin-left: 2%;
`

export { ErrorMessage, AlertUser, Loading, Sucess }
